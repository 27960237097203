/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {useForm, Controller} from 'react-hook-form';
import {formateImage} from '../../../functions/formatImage';
import PageWrapper from '../../../modules/PageWrapper';
import {useThemeMode} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';

import css from '../../../../styles/PwaConstructor.module.scss';

const UpdateService = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Сервисы', path: '/app/services', isActive: true},
        {isSeparator: true},
        {
          title: 'Изменение сервиса',
          isActive: true,
        },
      ]}
      title={'Изменение сервиса'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = ({isVerify, setPreloader}) => {
  const store = useStore();

  const [data, setData] = useState({});
  const [service, setService] = useState({});
  const {id} = useParams();

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      setService(await store.getService(id));
      setPreloader(false);
    })();
  }, [isVerify]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
          <Common setData={setData} data={data} service={service} />
        </div>
      </div>
    </div>
  );
};

const Common = ({setData, data, service}) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm();
  const store = useStore();
  const navigate = useNavigate();

  const {mode} = useThemeMode();
  const [checkboxes, setCheckboxes] = useState([
    {value: false, label: 'Android'},
    {value: false, label: 'iOS'},
    {value: false, label: 'PWA'},
  ]);
  const [active, setActive] = useState(false);

  const [theme, setTheme] = useState('');

  useEffect(() => {
    if (mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    } else {
      setTheme(mode);
    }
  }, [mode]);

  useEffect(() => {
    if (!Object.keys(service).length) return;
    !!service.name && setValue('name', service.name);
    !!service.registration_postback &&
      setValue('registration_postback', service.registration_postback);
    !!service.fd_postback && setValue('fd_postback', service.fd_postback);
    !!service.external_param && setValue('external_param', service.external_param);
    !!service.click_param && setValue('click_param', service.click_param);
    !!service.install_postback && setValue('install_postback', service.install_postback);
    setCheckboxes([
      {value: service.is_android, label: 'Android'},
      {value: service.is_ios, label: 'iOS'},
      {value: service.is_pwa, label: 'PWA'},
    ]);
    setActive(service.is_active);
  }, [service]);

  const onSubmit = async (params) => {
    const result = await store.updateService({
      ...params,
      is_android: +checkboxes[0].value,
      is_ios: +checkboxes[1].value,
      is_pwa: +checkboxes[2].value,
      is_active: active,
      id: service.id,
    });
    !!result && toast.success(`Успешно сохранено!`, {});
    !!result && navigate(`/app/services`);
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title title={'Общие данные'} subTitle={'Вводите данные на кирилице и внимательно!'} />
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Имя</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            {...register('name', {required: true})}
          />
          {errors.name?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className=''>Постбэк регистрации</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            {...register('registration_postback', {
              pattern:
                /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:\d{1,5})?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/,
              required: false,
            })}
          />
          {errors.registration_postback?.type === 'pattern' && (
            <div className='text-danger mt-2'>Не верный формат постбэк регистрации</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span>Постбэк депозита</span>
          </label>

          <input
            className='form-control form-control-lg form-control-solid'
            {...register('fd_postback', {
              pattern:
                /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:\d{1,5})?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/,
              required: false,
            })}
          />
          {errors.fd_postback?.type === 'pattern' && (
            <div className='text-danger mt-2'>Не верный формат постбэк депозита</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span>Внешний параметр</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            {...register('external_param', {required: false})}
          />
          {errors.phone?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span>Параметр клика</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            {...register('click_param', {required: false})}
          />
          {errors.telegram_id?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span>Постбэк устновки (внутренний)</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            {...register('install_postback', {required: false})}
          />
          {errors.install_postback?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
        <div
          className='col-lg-6 fv-row fv-plugins-icon-container mb-10'
          style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'end'}}
        >
          <div
            style={{
              maxWidth: '400px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '16px',
              whiteSpace: 'nowrap',
              '--slideSelectorBg':
                theme === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
            }}
          >
            <span>Статус</span>
            <label className={css.switch} htmlFor={`active`}>
              <input
                type='checkbox'
                id={`active`}
                checked={active}
                onChange={() => {
                  setActive((prev) => !prev);
                }}
              />
            </label>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-between flex-wrap' style={{gap: 20}}>
          {checkboxes.map((item) => (
            <div
              key={`${item.label}`}
              style={{
                maxWidth: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '16px',
                whiteSpace: 'nowrap',
                '--slideSelectorBg':
                  theme === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
              }}
            >
              <span
                onClick={(e) => {
                  setCheckboxes((prev) =>
                    prev.map((i) => (i.label === item.label ? {...i, value: !i.value} : i))
                  );
                }}
              >
                {item.label}
              </span>
              <label
                className={css.switch}
                htmlFor={`${item.label}`}
                onClick={(e) => {
                  setCheckboxes((prev) =>
                    prev.map((i) => (i.label === item.label ? {...i, value: !i.value} : i))
                  );
                }}
              >
                <input
                  type='checkbox'
                  id={`${item.value}`}
                  checked={!!item.value}
                  onChange={() => {}}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className='d-flex flex-stack justify-content-end pt-10'>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Сохранить
          </button>
        </div>
      </div>
    </form>
  );
};

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      {!!subTitle && <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>}
    </div>
  );
};

export default UpdateService;
