/* eslint-disable jsx-a11y/anchor-is-valid */
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Card from '../../../shared/Card';
import Pagination from '../../../shared/Pagination';
import {ButtonView} from '../../../shared/Buttons';
import {formatNumberToCash} from '../../../functions/formatNumber';

const AttachmentOffersList = ({data, searchData, portionData, title, limit, offset, setOffset}) => {
  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5 justify-content-start'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
        </div>
    {console.log(true)}
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {!!data.length && (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>оффер</th>
                    <th className='min-w-125px'>ставка</th>
                    <th className='min-w-125px'>бейслайн</th>
                    <th className='min-w-125px'>создан</th>
                    <th className='text-end min-w-100px'>Действие</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-semibold'>
                  {portionData.map((el, index) => {
                    return (
                      <tr key={el?.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column justify-content-center'>
                              <span className='fs-6 text-gray-800 text-hover-primary'>
                                {el?.name}
                              </span>
                              <div className='fw-semibold text-gray-400'>#{el?.id}</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='badge badge-light-success'>
                            {formatNumberToCash(el?.bid)}
                          </div>
                        </td>
                        <td>
                          <div className='badge badge-light fw-bold'>
                            {formatNumberToCash(el?.baseline)}
                          </div>
                        </td>
                        <td>{moment.tz(el.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY, HH:mm')}</td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link to={`/advertisers/offers/${el?.id}`}>
                              <ButtonView />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {searchData.length >= limit && (
            <Pagination
              arrLength={searchData.length}
              limit={limit}
              offset={offset}
              setOffset={setOffset}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default observer(AttachmentOffersList);
