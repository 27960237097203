/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';

import BloggerList from './BloggersList';
import PageWrapper from '../../../../../modules/PageWrapper';
import ButtonExelWithLoadOnClick from '../../../../../modules/ButtonExelWithLoadOnClick';
import moment from 'moment';

const Bloggers = () => {
  const store = useStore();
  const [selectedBayer, setSelectedBayer] = useState();
  const [queryText, setQueryText] = useState();
  const [localPreloader, setLocalPreloader] = useState(false);
  const [bloggersList, setBloggersList] = useState([]);
  const [romiList, setRomiList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [timeConfig, setTimeConfig] = useState({});

  const loadData = async () => {
    const getListForExel = await store.trafficsInfluenceGetTrafficsListForExelNoPreloader({
      selectedBayer,
      queryText,
      timeConfig,
    });
    const formattedForExel = getListForExel.data.map((item) => ({
      ...item,
      created_at: moment.tz(item?.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm '),
    }));
    return {data: formattedForExel, time: getListForExel.time};
  };

  const updateList = async (page, selectedBayer, q, timeConfig) => {
    if (selectedBayer || q || page) {
      setLocalPreloader(true);
      setBloggersList([]);
      const getBloggers = await store.trafficsInfluenceGetTrafficsListNoPreloader({
        page,
        selectedBayer,
        queryText: q,
        timeConfig,
      });
      const getRomiList = await store.financeCategoryGetRomiListNoPreloader();
      const newBloggers = getBloggers.rows.map((el) => {
        const romi = Math.round(+el.romi);
        const indicator = getRomiList.find(
          (item) => romi >= +item.romi_min && romi <= +item.romi_max
        );
        return {...el, indicator};
      });
      setBloggersList(newBloggers);
      setRomiList(getRomiList);
      setLocalPreloader(false);
      setTotalCount(getBloggers.total);
    }
  };
  return (
    <PageWrapper
      firstTimeConfig='all_time'
      allowAllTimes
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Трафик от блогеров', path: '/tracker/influence/bloggers', isActive: true},
      ]}
      title={'Трафик от блогеров'}
      buttonKPI={
        <ButtonExelWithLoadOnClick
          fieldsToKeep={[
            'id',
            'type',
            'name',
            'bayer_id',
            'created_at',
            'count_appeals',
            'count_leads',
            'count_qualifications',
            'count_registrations',
            'cost',
            'profit',
            'revenue',
            'romi',
          ]}
          fileName={'get-bloggers'}
          loadData={loadData}
        />
      }
    >
      <Component
        updateList={updateList}
        selectedBayer={selectedBayer}
        setSelectedBayer={setSelectedBayer}
        romiList={romiList}
        setRomiList={setRomiList}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
        setQueryText={setQueryText}
        queryText={queryText}
        bloggersList={bloggersList}
        setBloggersList={setBloggersList}
        localPreloader={localPreloader}
        setLocalPreloader={setLocalPreloader}
        setTimeConfig={setTimeConfig}
      />
    </PageWrapper>
  );
};

const Component = observer(
  ({
    timeConfig,
    isVerify,
    setPreloader,
    updateList,
    selectedBayer,
    setSelectedBayer,
    romiList,
    setRomiList,
    setTotalCount,
    totalCount,
    setQueryText,
    queryText,
    bloggersList,
    setBloggersList,
    localPreloader,
    setLocalPreloader,
    setTimeConfig,
  }) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
      isVerify && setPreloader(false);
    }, [isVerify]);

    useEffect(() => {
      setTimeConfig(timeConfig);
    }, [timeConfig]);

    // useEffect(() => {
    //   isVerify && getData();
    // }, [timeConfig, isVerify]);

    // const getBloggersList = async (page, selectedBayer, queryText) => {
    //   const getBloggers = await store.trafficsInfluenceGetTrafficsList({page, selectedBayer, queryText, timeConfig});
    //   const getRomiList = await store.financeCategoryGetRomiList();
    //   const getBayers = await store.trafficsInfluenceGetBayersList();
    //
    //   const newBloggers = getBloggers.rows
    //     .map((el) => {
    //       const romi = Math.round(+el.romi);
    //       const indicator = getRomiList.find(
    //         (item) => romi >= +item.romi_min && romi <= +item.romi_max
    //       );
    //       return {...el, indicator};
    //     })
    //     .sort((a, b) => b.last_link_created - a.last_link_created);
    //   setBloggers(newBloggers);
    //   setBayers(getBayers);
    //   setPreloader(false);
    //   setTotal(getBloggers.total)
    // };

    return (
      <>
        <BloggerList
          type={'influence'}
          title={'Блогеры'}
          total={total}
          timeConfig={timeConfig}
          updateList={updateList}
          selectedBayer={selectedBayer}
          setSelectedBayer={setSelectedBayer}
          romiList={romiList}
          setRomiList={setRomiList}
          queryText={queryText}
          setQueryText={setQueryText}
          setTotalCount={setTotalCount}
          totalCount={totalCount}
          bloggersList={bloggersList}
          setBloggersList={setBloggersList}
          localPreloader={localPreloader}
          setLocalPreloader={setLocalPreloader}
        />
      </>
    );
  }
);

export default Bloggers;
