import React, {useEffect, useState} from 'react';
import css from './PwaConfig.module.scss';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {useThemeMode} from '../../../../_metronic/partials';

const PwaConfig = ({data, isExternal, roles}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');
  const [parameters, setParameters] = useState({});

  const [link, setLink] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    const newParameters = {};
    const baseTrafficInfoContainer = document.getElementById('baseTrafficInfoContainer');
    const worldTrafficInfoContainer = document.getElementById('worldTrafficInfoContainer');
    if (baseTrafficInfoContainer) {
      newParameters.baseTrafficInfoContainer =
        baseTrafficInfoContainer.getBoundingClientRect().height;
    }
    if (worldTrafficInfoContainer) {
      newParameters.worldTrafficInfoContainer =
        worldTrafficInfoContainer.getBoundingClientRect().height;
    }

    if (data.country_offers.length > 0) {
      data.country_offers.forEach((item) => {
        const countryTrafficInfoContainer = document.getElementById(
          `${item.country_name}TrafficInfoContainer`
        );
        newParameters[`${item.country_name}TrafficInfoContainer`] =
          countryTrafficInfoContainer.getBoundingClientRect().height;
      });
    }

    setParameters(newParameters);

    let params = [];
    if (data.click_param) {
      params.push(`click_id={${data.click_param}}`);
    }
    if (data.external_param) {
      params.push(`external_id={${data.external_param}}`);
    }

    const queryString = params.length ? `?${params.join('&')}` : '';
    setLink(`https://click.thl.ac/app/${data.id}${queryString}`);
  }, [data]);

  const onCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div
      className={css.container}
      style={{
        '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
        '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
        '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
        '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
        '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
        '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
        '--buttonGrayBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
        '--slideSelectorBg':
          themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
        '--slideSelectorItemBg':
          themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
        '--buttonCommentBg':
          themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
      }}
    >
      <section className={classNames(css.section, css._1, 'p-8')}>
        <span className={classNames(css.title)}>Конфигурация</span>
        <label htmlFor='' className={css.field}>
          <span className={css.label}>Домен</span>
          <div className={css.input}>
            <span className={css.text}>
              {`https://${isExternal ? data?.link : data?.domain_name}`}
            </span>
            <span
              className={css.link}
              onClick={() => {
                window.open(`https://${isExternal ? data?.link : data?.domain_name}`, '_blank');
              }}
            >
              <i className='bi bi-link-45deg text-gray-500 p-0' style={{fontSize: 22}}></i>
            </span>
          </div>
        </label>
        <label htmlFor='' className={css.field}>
          <span className={css.label}>Байер</span>
          <div className={css.input}>
            <span className={css.text}>
              {!!data?.bayer_id ? `#${data?.bayer_id} ${data?.bayer_name}` : 'Не выбран'}
            </span>
          </div>
        </label>
        <label htmlFor='' className={css.field}>
          <span className={css.label}>Default Поток</span>
          <div className={css.input}>
            <span
              className={css.text}
            >{`#${data?.default_link_id} ${data?.default_link_name}`}</span>
          </div>
        </label>

        <label htmlFor='' className={css.field}>
          <span className={css.label}>Ссылка для сервиса</span>
          <div className={css.input}>
            {isExternal && (roles.includes(4) || roles.includes(10) || roles.includes(39)) && (
              <span className={css.text}>{link}</span>
            )}
            <span
              className={css.link}
              onClick={() => {
                if (isExternal && (roles.includes(4) || roles.includes(10) || roles.includes(39))) {
                  onCopy(link);
                }
              }}
            >
              <i className='ki-duotone ki-copy text-gray-400' style={{fontSize: 22}}></i>
            </span>
          </div>
        </label>
      </section>
      <section className={classNames(css.section, css._2, 'p-8')}>
        <span className={classNames(css.title)}>Распределение трафика</span>
        {!data?.split_enabled && (
          <div className={css.selectorContainer}>
            <label className={css.selector} htmlFor='baseTraffic'>
              <input
                type='checkbox'
                name=''
                id='baseTraffic'
                onClick={(e) =>
                  setParameters((prev) => {
                    return {...prev, baseTrafficInfoContainerChecked: e.target.checked};
                  })
                }
              />
              <div className={css.title}>
                <span className={classNames(css.icon, css._world)}></span>
                <span className={css.label}>Базовый лендинг</span>
              </div>
              <span className={classNames(css.arrow, css._active)}></span>
            </label>
            <div
              className={css.infoWrapper}
              style={
                parameters.baseTrafficInfoContainerChecked
                  ? {height: `${parameters.baseTrafficInfoContainer}px`}
                  : {}
              }
            >
              <div className={css.infoContainer} id='baseTrafficInfoContainer'>
                <div className={css.info}>
                  <div className={css.item}>
                    <div
                      className={css.image}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${data?.default_offer_avatar})`,
                      }}
                    ></div>
                    <div className={classNames(css.text, 'text-gray-500')}>
                      #{data?.default_offer_id} {data?.default_offer_name}
                    </div>
                  </div>
                  <div className={css.item}>
                    <div
                      className={css.image}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${data?.default_offer_landing_avatar})`,
                      }}
                    ></div>
                    <div className={classNames(css.text, 'text-gray-500')}>
                      #{data?.default_offer_landing_id} {data?.default_offer_landing_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.split_enabled && (
          <div className={css.selectorContainer}>
            <label className={css.selector} htmlFor='worldTraffic'>
              <input
                type='checkbox'
                name=''
                id='worldTraffic'
                onClick={(e) =>
                  setParameters((prev) => {
                    return {...prev, worldTrafficInfoContainerChecked: e.target.checked};
                  })
                }
              />
              <div className={css.title}>
                <span className={classNames(css.icon, css._world)}></span>
                <span className={css.label}>Весь мир</span>
              </div>
              <span className={classNames(css.arrow, css._active)}></span>
            </label>
            <div
              className={css.infoWrapper}
              style={
                !!parameters.worldTrafficInfoContainerChecked
                  ? {height: `${parameters.worldTrafficInfoContainer}px`}
                  : {height: '0'}
              }
            >
              <div
                className={css.infoContainer}
                id='worldTrafficInfoContainer'
                style={{paddingTop: '17px'}}
              >
                {data?.app_split.map((item, index) => {
                  return (
                    <div className={css.info} key={index}>
                      <span className={classNames(css.proc)}>{item.traffic_percentage}%</span>
                      <div className={css.item}>
                        <div
                          className={css.image}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${item?.app_offer_avatar})`,
                          }}
                        ></div>
                        <div className={classNames(css.text, 'text-gray-500')}>
                          #{item?.app_offer_id} {item?.app_offer_name}
                        </div>
                      </div>
                      <div className={css.item}>
                        <div
                          className={css.image}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${item?.offer_landing_avatar})`,
                          }}
                        ></div>
                        <div className={classNames(css.text, 'text-gray-500')}>
                          #{item?.offer_landing_id} {item?.offer_landing_name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {data?.country_offers?.length > 0 &&
          data?.country_offers?.map((country, i) => {
            return (
              <div className={css.selectorContainer}>
                <label className={css.selector} htmlFor={`${country?.country_name}Traffic`}>
                  <input
                    type='checkbox'
                    name=''
                    id={`${country?.country_name}Traffic`}
                    onClick={(e) =>
                      setParameters((prev) => {
                        return {
                          ...prev,
                          [`${country?.country_name}TrafficInfoContainerChecked`]: e.target.checked,
                        };
                      })
                    }
                  />
                  <div className={css.title}>
                    <span
                      className={classNames(css.icon)}
                      style={{
                        width: '35px',
                        height: '23px',
                        backgroundImage: `url(${country?.country_avatar})`,
                      }}
                    ></span>
                    <span className={css.label}>{country.country_name}</span>
                  </div>
                  <span className={classNames(css.arrow, css._active)}></span>
                </label>
                <div
                  className={css.infoWrapper}
                  style={
                    !!parameters[`${country?.country_name}TrafficInfoContainerChecked`]
                      ? {height: `${parameters[`${country?.country_name}TrafficInfoContainer`]}px`}
                      : {height: '0'}
                  }
                >
                  <div
                    className={css.infoContainer}
                    id={`${country?.country_name}TrafficInfoContainer`}
                    style={{paddingTop: '17px'}}
                  >
                    {!country?.split_enabled && (
                      <div className={css.info}>
                        <div className={css.item}>
                          <div
                            className={css.image}
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${country?.app_offer_avatar})`,
                            }}
                          ></div>
                          <div className={classNames(css.text, 'text-gray-500')}>
                            #{country?.app_offer_id} {country?.app_offer_name}
                          </div>
                        </div>
                        <div className={css.item}>
                          <div
                            className={css.image}
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${country?.offer_landing_avatar})`,
                            }}
                          ></div>
                          <div className={classNames(css.text, 'text-gray-500')}>
                            #{country?.offer_landing_id} {country?.offer_landing_name}
                          </div>
                        </div>
                      </div>
                    )}
                    {!!country?.split_enabled &&
                      country?.app_split.map((item, index) => {
                        return (
                          <div className={css.info} key={index}>
                            <span className={classNames(css.proc)}>{item.traffic_percentage}%</span>
                            <div className={css.item}>
                              <div
                                className={css.image}
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${item?.app_offer_avatar})`,
                                }}
                              ></div>
                              <div className={classNames(css.text, 'text-gray-500')}>
                                #{item?.app_offer_id} {item?.app_offer_name}
                              </div>
                            </div>
                            <div className={css.item}>
                              <div
                                className={css.image}
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${item?.offer_landing_avatar})`,
                                }}
                              ></div>
                              <div className={classNames(css.text, 'text-gray-500')}>
                                #{item?.offer_landing_id} {item?.offer_landing_name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default PwaConfig;
