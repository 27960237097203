/* eslint-disable no-use-before-define */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState, useRef} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';
import Header from './Header';
import Settings from './Settings';
import Treker from './Treker/Treker';
import PageWrapper from '../../../../../modules/PageWrapper';
import Analyze from './Analyze';
import Clo from './Clo';
import {Button} from '../../../../../shared/Buttons';

export const MainSource = observer(
  ({page, setPage, isVerify, setPreloader, timeConfig, id, setCompanyID}) => {
    const store = useStore();

    const [data, setData] = useState({});
    const [traffic, setTraffic] = useState({});
    const [dataForSelectors, setDataForSelectors] = useState({domains: [], bayers: [], pixels: []});
    const [validation, setValidation] = useState([]);

    const [settingsType, setSettingsType] = useState('');
    const [settings, setSettings] = useState({});
    const [saveButtonState, setSaveButtonState] = useState(0);
    const [timeInfo, setTimeInfo] = useState('');

    const [fbPixelId, setFbPixelId] = useState('');

    const [loader, setLoader] = useState(false);

    const [pixelsList, setPixelsList] = useState([]);

    useEffect(() => {
      isVerify && timeConfig.time_config && getData();
    }, [timeConfig, isVerify]);

    useEffect(() => {
      const containersXL = document.querySelectorAll('.container-xxl');
      containersXL.forEach((el) => (el.style.maxWidth = '100%'));

      return () => containersXL.forEach((el) => (el.style.maxWidth = ''));
    }, []);

    const getData = async () => {
      const tc = timeConfig;
      const [getMainStats, domains, bayers, getCountries, landings, getWhitePages] =
        await Promise.all([
          store.trafficsFacebookLinksGetMainStats(tc, id),
          store.resourceGetDomainsList('cloak'),
          store.trafficsFacebookGetBayersList(),
          store.getCountries(),
          store.getLandings(),
          store.trafficsWhitePagesGetWhitePagesList(),
        ]);

      const pixels = await store.getPixels(getMainStats.type === 'pwa' ? 'app' : 'telegram');
      setPixelsList(pixels);

      const traffic_id = getMainStats?.traffic_id;

      setSettingsType(getMainStats.type);

      setSaveData({
        ...saveData,
        name: getMainStats?.name,
        bayer_id: getMainStats?.bayer_id || null,
        domain_id: getMainStats?.domain_id,
        pixel_id: getMainStats?.pixel_id || null,
        landing_id: getMainStats?.landing_id || null,
        id: getMainStats?.id,
        whitepage_id: getMainStats?.whitepage_id || 'avia',
        os_permissions: getMainStats?.os_permissions || {is_allowed: true, systems: []},
        devices_permissions: getMainStats?.devices_permissions || {is_allowed: true, devices: []},
        countries_permissions: getMainStats?.countries_permissions || {
          is_allowed: true,
          countries: [],
        },
        cloakit: getMainStats?.cloakit,
        vpn: getMainStats?.vpn,
        allow_ipv6: getMainStats?.allow_ipv6,
        check_pixel: getMainStats?.check_pixel,
        check_fbclid: getMainStats?.check_fbclid,
        check_campaign_id: getMainStats?.check_campaign_id,
        check_adset_id: getMainStats?.check_adset_id,
        check_ad_id: getMainStats?.check_ad_id,
        check_placement: getMainStats?.check_placement,
      });

      setSettings({
        traffic_name:
          getMainStats?.traffic_id && `#${getMainStats?.traffic_id} ${getMainStats?.traffic_name}`,
        channel_name:
          getMainStats?.channel_id && `#${getMainStats?.channel_id} ${getMainStats?.channel_name}`,
        pwa_name:
          getMainStats?.pwa_id &&
          `#${getMainStats?.pwa_id} (${getMainStats?.type_config}) ${getMainStats?.pwa_name}`,
        bot_name: getMainStats?.bot_id && `#${getMainStats?.bot_id} ${getMainStats?.bot_name}`,
        channel_link: getMainStats?.channel_link,
      });

      setCompanyID(traffic_id);
      const getInfo = !!traffic_id ? await store.trafficsGetInfo(traffic_id) : {};
      setTraffic(getInfo);

      const getRomiList = await store.financeCategoryGetRomiList();
      const romi = Math.round(+getMainStats.romi);

      const indicator = getRomiList.find((el) => el.romi_min <= romi && el.romi_max >= romi);

      setData({...getMainStats, indicator});
      console.log(getWhitePages);
      setDataForSelectors({
        domains: domains.map((d) => ({value: d.id, label: `#${d.id} ${d.domain}`})),
        bayers: [
          {label: 'Не выбрано', value: null},
          ...bayers.map((b) => ({
            value: b.id,
            label: `#${b.id} ${b.first_name} ${b.last_name}`,
          })),
        ],
        pixels: [
          {label: 'Не выбрано', value: null},
          ...pixels.map((p) => ({
            value: +p.id,
            label: `#${p.id} (${p.pixel_id}) ${p.name}`,
          })),
        ],
        countries: getCountries.map((c) => ({
          value: c.alpha2Code.toUpperCase(),
          label: c.name,
          flag: c.flag,
        })),
        landings: [
          {label: 'Не выбрано', value: null},
          ...landings.map((p) => ({
            value: p.id,
            label: `#${p.id} ${p.name}`,
          })),
        ],
        whitePages: [
          ...getWhitePages?.rows?.map((p) => ({
            value: p.id,
            label: `#${p.id} ${p.name}`,
          })),
        ],
      });

      setFbPixelId(pixels.find((item) => item.id === getMainStats.pixel_id)?.pixel_id || '');
      getNewTime(
        !!getMainStats.updated_at ? getMainStats.updated_at * 1000 : getMainStats.created_at * 1000
      );
      setPreloader(false);
    };

    const save = async () => {
      setLoader(true);
      const invalidFields = Object.entries(saveData).filter(
        (item) =>
          item[0] !== 'pixel_id' &&
          item[0] !== 'bayer_id' &&
          item[0] !== 'landing_id' &&
          (item[1] === null || item[1] === '' || item[1] === undefined)
      );
      setValidation(invalidFields.map((item) => item[0]));
      if (invalidFields.length > 0)
        return toast.error(`Есть не заполненые поля!`, {theme: 'colored'});

      const res = await store.trafficsFacebookLinksUpdateSource({
        ...saveData,

        devices_permissions:
          saveData?.devices_permissions?.devices?.length === 0
            ? null
            : JSON.stringify(saveData?.devices_permissions),

        os_permissions:
          saveData?.os_permissions?.systems?.length === 0
            ? null
            : JSON.stringify(saveData?.os_permissions),

        countries_permissions:
          saveData?.countries_permissions?.countries?.length === 0
            ? null
            : JSON.stringify(saveData?.countries_permissions),
      });

      if (!res) {
        toast.error(`Произошла ошибка сохранения!`, {theme: 'colored'});
        setSaveButtonState(1);
      } else {
        const mainStats = await store.trafficsFacebookLinksGetMainStats2(timeConfig, id);
        setData({...data, ...mainStats});
        setFbPixelId(pixelsList.find((item) => item.id === mainStats.pixel_id)?.pixel_id || '');
        toast.success(`Изменения сохранены!`, {});
        getNewTime();
      }
      setLoader(false);
    };

    const [saveData, setSaveData] = useState({
      os_permissions: {is_allowed: true},
      devices_permissions: {is_allowed: true},
      countries_permissions: {is_allowed: true},
      cloakit: false,
      vpn: false,
      allow_ipv6: false,
      check_pixel: false,
      check_fbclid: false,
      check_campaign_id: false,
      check_adset_id: false,
      check_ad_id: false,
      check_placement: false,
    });

    const formatTimeAgo = (milliseconds) => {
      if (new Date().getTime() - milliseconds * 1000 < 3600000) {
        return moment(milliseconds * 1000)
          .startOf('minute')
          .fromNow();
      }

      return moment(milliseconds * 1000)
        .startOf('hour')
        .fromNow();
    };

    const time = useRef(null);
    const intervalId = useRef(null);

    const getNewTime = (miliss = 0) => {
      const newTime = miliss || new Date().getTime();
      time.current = newTime;

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      intervalId.current = setInterval(() => {
        setTimeInfo(formatTimeAgo(time.current / 1000));
      }, 1000);

      setSaveButtonState(0);
    };

    useEffect(() => {
      if (saveButtonState === 1) {
        clearInterval(intervalId.current);
        time.current = null;
      }
    }, [saveButtonState]);

    useEffect(() => {
      return () => {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
      };
    }, []);

    const onCopy = (text, domain) => {
      if (!domain) {
        return toast.error(`Отсутствует домен!`, {theme: 'colored'});
      }

      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success(`Скопировано!`, {});
        })
        .catch((err) => {
          console.log('Something went wrong', err);
        });
    };

    return (
      <>
        <Header
          page={page}
          setPage={setPage}
          data={data}
          traffic={traffic}
          links={
            !settingsType || store.roles.includes(38)
              ? [
                  {link: 'treker', title: 'Трекер'},
                  {link: 'analyze', title: 'Анализ Трафика'},
                ]
              : [
                  {link: 'treker', title: 'Трекер'},
                  {link: 'settings', title: 'Настройки'},
                  {link: 'clo', title: 'Клоака'},
                  {link: 'analyze', title: 'Анализ Трафика'},
                ]
          }
          actionComponents={[
            <SaveButton
              save={save}
              saveButtonState={saveButtonState}
              setSaveButtonState={setSaveButtonState}
              timeInfo={timeInfo}
            />,
            <>
              {!!settingsType && (
                <Button
                  className={`px-4`}
                  color={'btn-light'}
                  disabled={loader}
                  action={async () => {
                    onCopy(
                      `https://${data?.domain_name}/lnk/${data?.id}?campaign_name={{campaign.name}}&ad_name={{ad.name}}&placement={{placement}}&campaign_id={{campaign.id}}&adset_id={{adset.id}}&ad_id={{ad.id}}&adset_name={{adset.name}}&pixel=${fbPixelId}`,
                      data?.domain_name
                    );
                  }}
                >
                  {loader && (
                    <div
                      className='spinner-border text-gray-500 spinner-border-sm'
                      role='status'
                      style={{width: '18px', height: '18px'}}
                    ></div>
                  )}
                  {!loader && <i className='ki-duotone ki-copy text-gray-500 p-0 fs-2'></i>}
                </Button>
              )}
            </>,
          ]}
        />
        {page === 'treker' && <Treker timeConfig={timeConfig} id={id} />}
        {page === 'settings' && !!settingsType && (
          <Settings
            settingsType={settingsType}
            settings={settings}
            saveData={saveData}
            setSaveData={setSaveData}
            dataForSelectors={dataForSelectors}
            validation={validation}
            setSaveButtonState={setSaveButtonState}
          />
        )}
        {page === 'clo' && !!settingsType && (
          <Clo
            saveData={saveData}
            setSaveData={setSaveData}
            dataForSelectors={dataForSelectors}
            validation={validation}
            setSaveButtonState={setSaveButtonState}
          />
        )}
        {page === 'analyze' && <Analyze id={id} tc={timeConfig} />}
      </>
    );
  }
);

const SaveButton = ({save, saveButtonState, setSaveButtonState, timeInfo}) => {
  return (
    <>
      {saveButtonState === 0 && (
        <>
          {timeInfo && <span className='me-4 text-gray-600'>Обновлено {timeInfo}</span>}
          <Button
            className='me-3 text-gray-500'
            color={'btn-light'}
            disabled={true}
            action={async () => {
              save();
            }}
          >
            Сохранено
          </Button>
        </>
      )}
      {saveButtonState === 1 && (
        <>
          <span className='me-4 text-gray-600'>Изменения не сохранены</span>
          <Button
            className='me-3'
            action={async () => {
              save();
            }}
          >
            Сохранить
          </Button>
        </>
      )}
    </>
  );
};

const SourceWrapper = () => {
  const [page, setPage] = useState('treker');
  const [companyID, setCompanyID] = useState();

  const [prevTimeConfig, setPrevTimeConfig] = useState(null);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {id} = useParams();

  useEffect(() => {
    const timeConfig = searchParams.get('time_config');
    const timeStart = searchParams.get('time_start');
    const timeEnd = searchParams.get('time_end');

    if (!timeConfig) return;

    setPrevTimeConfig({
      time_config: timeConfig,
      time_start: !!timeStart ? +timeStart * 1000 : '',
      time_end: !!timeEnd ? +timeEnd * 1000 : '',
    });
  }, []);

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      prevTimeConfig={prevTimeConfig}
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {
          title: `Кампания #${companyID || ''}`,
          path: `/tracker/facebook/companies/${companyID}`,
          isActive: true,
        },
        {isSeparator: true},
        {
          title: `Поток #${id}`,
          path: `/tracker/facebook/source/${id}`,
          isActive: true,
        },
      ]}
      title={`Поток #${id}`}
    >
      <MainSource page={page} setPage={setPage} id={id} setCompanyID={setCompanyID} />
    </PageWrapper>
  );
};

export default SourceWrapper;
