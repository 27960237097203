/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import BlockTitles from './templates/BlockTitles';
import BlockLine from './templates/BlockLine';
import {SelectorWithSearch, SelectorIsMulti} from '../../shared/Selectors';
import Warning from '../../modules/Warning';
import {CreateAppModal} from '../../../_metronic/partials';

const NameForm = ({
  data,
  setData,
  templatesNames,
  advertisersOptions,
  offersOptions,
  offers,
  countries,
  errors,
  id,
  deleteWithToken,
  putWithToken,
  token,
  setTemplate,
  pwaToCopy
}) => {
  return (
    <>
      <div className={css.nameForm}>
        <BlockTitles
          title={'Настройки'}
          description={'Настройте основные системные настройки для своего Приложения'}
        />
        <BlockLine />
        <label htmlFor='' className={css.inputField} style={{marginTop: '30px'}}>
          <span>Название</span>
          <input
            type='text'
            value={data.name || ''}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value,
              })
            }
            style={errors.includes('name') ? {border: '2px solid red', outline: 'none'} : {}}
          />
        </label>
        {/* <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors.includes('advertiser_id') && '2px solid red !important',
                boxShadow: errors.includes('advertiser_id') && 'none',
              }),
            }}
            placeholder=''
            options={advertisersOptions}
            value={advertisersOptions.find((item) => item.value === data.advertiser_id)}
            setValue={(e) =>
              setData({
                ...data,
                advertiser_id: e.value,
              })
            }
            actions={() => {}}
          />
        </label>
        {data.advertiser_id !== undefined && (
          <>
            <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>Основной оффер</span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: 'fit-content',
                    minHeight: '45px',
                    border: errors.includes('offer_id') && '2px solid red !important',
                    boxShadow: errors.includes('offer_id') && 'none',
                  }),
                }}
                placeholder=''
                options={offersOptions}
                value={offersOptions?.find((item) => item.value === data.offer_id)}
                setValue={(e) => {
                  setData({
                    ...data,
                    offer_id: e.value,
                  });
                }}
              />
            </label>
            <BlockTitles
              description={'Здесь вы можете добавить дополнительные офферы для разных ГЕО.'}
            />
            {data?.offers?.map((offer, index) => {
              return (
                <React.Fragment key={index}>
                  <OtherOffers
                    index={index}
                    offer={offer}
                    token={token}
                    deleteWithToken={deleteWithToken}
                    id={id}
                    data={data}
                    setData={setData}
                    offers={offers}
                    countries={countries}
                    advertisersOptions={advertisersOptions}
                    errors={errors}
                    putWithToken={putWithToken}
                  />
                </React.Fragment>
              );
            })}
            <button
              className={css.addComment}
              style={{marginTop: '0'}}
              onClick={() => {
                setData({...data, offers: [...data?.offers, {offer_id: null, country_id: null}]});
              }}
            >
              Добавить дополнительный оффер +
            </button>
            <BlockLine />
          </>
        )} */}
        <label htmlFor='' style={{width: '100%'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Шаблон</span>
          <div className={css.doubleFields}>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '45px',
                  border: errors.includes('template_id') && '2px solid red !important',
                  boxShadow: errors.includes('template_id') && 'none',
                }),
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '100%',
                }),
              }}
              isDisabled={!!pwaToCopy}
              placeholder=''
              options={templatesNames}
              value={data.template}
              setValue={(e) => {
                setTemplate(e.label);
                setData({...data, template: e});
              }}
            />
          </div>
        </label>
      </div>
    </>
  );
};

const OtherOffers = ({
  index,
  offer,
  token,
  deleteWithToken,
  id,
  data,
  setData,
  offers,
  countries,
  advertisersOptions,
  errors,
  putWithToken,
}) => {
  const [advertiser, setAdvertiser] = useState(null);
  const [offersOptions, setOffersOptions] = useState([]);

  const setNewOffers = (advertiser_id) => {
    setAdvertiser(advertiser_id);
    setOffersOptions([
      ...offers
        .filter((item) => item.advertiser_id === advertiser_id)
        .map((item) => {
          return {value: item?.id, label: item?.name};
        }),
    ]);
  };

  useEffect(() => {
    if (!offer.offer_id) return;
    const findAdvertiser = offers.find((item) => item.id === offer.offer_id).advertiser_id;
    setAdvertiser(findAdvertiser);
    setOffersOptions([
      ...offers
        .filter((item) => item.advertiser_id === findAdvertiser)
        .map((item) => {
          return {value: item?.id, label: item?.name};
        }),
    ]);
  }, [offer]);

  return (
    <React.Fragment key={`${index}-offer`}>
      <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '5px 0'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>
            Дополнительный рекламодатель
          </span>
          <DeleteOffer
            offer={offer}
            token={token}
            deleteWithToken={deleteWithToken}
            id={id}
            data={data}
            setData={setData}
            index={index}
          />
        </div>
        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: 'fit-content',
              minHeight: '45px',
              border: errors.includes(`offer_${index}`) && '2px solid red !important',
              boxShadow: errors.includes(`offer_${index}`) && 'none',
            }),
          }}
          placeholder=''
          options={advertisersOptions}
          value={advertisersOptions?.find((item) => item.value === advertiser)}
          setValue={(e) => {
            setNewOffers(e.value);
          }}
        />
      </label>
      {!!advertiser && (
        <>
          <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
            <span style={{display: 'inline-block', marginBottom: '8px'}}>Дополнительный оффер</span>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: 'fit-content',
                  minHeight: '45px',
                  border: errors.includes(`offer_${index}`) && '2px solid red !important',
                  boxShadow: errors.includes(`offer_${index}`) && 'none',
                }),
              }}
              placeholder=''
              options={offersOptions}
              value={offersOptions?.find((item) => item.value === offer.offer_id)}
              setValue={async (e) => {
                const newData = {...data};
                newData.offers[index].offer_id = e.value;
                setData(newData);
                if (!!id && !!offer?.id) {
                  await putWithToken(`pwa/${id}/offers/${offer.id}`, token, {
                    offer_id: e.value,
                    country_id: offer?.country_id,
                  });
                }
              }}
            />
          </label>
          <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
            <span style={{display: 'inline-block', marginBottom: '8px'}}>ГЕО</span>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: 'fit-content',
                  minHeight: '45px',
                  border: errors.includes(`country_${index}`) && '2px solid red !important',
                  boxShadow: errors.includes(`country_${index}`) && 'none',
                }),
              }}
              placeholder=''
              options={countries}
              value={countries?.find((item) => item.value === offer.country_id)}
              setValue={async (e) => {
                const newData = {...data};
                newData.offers[index].country_id = e.value;
                setData(newData);
                if (!!id && !!offer?.id) {
                  await putWithToken(`pwa/${id}/offers/${offer.id}`, token, {
                    offer_id: offer.offer_id,
                    country_id: e.value,
                  });
                }
              }}
            />
          </label>
        </>
      )}
      <BlockLine />
    </React.Fragment>
  );
};

const DeleteOffer = ({offer, token, deleteWithToken, id, data, setData, index}) => {
  const [warning, setWarning] = useState(false);

  const del = async () => {
    const newData = {...data};
    newData?.offers?.splice(index, 1);
    setData(newData);
    if (!!id && !!offer?.id) {
      await deleteWithToken(`pwa/${id}/offers/${offer.id}`, token);
    }
  };

  return (
    <>
      <div
        className={css.delete}
        onClick={async (e) => {
          setWarning(true);
        }}
      />
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text='Вы уверены что хотите удалить оффер?'
          buttons={[
            {
              action: () => {
                del();
                setWarning(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
};

export default NameForm;
