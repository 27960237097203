/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import InputSearch from '../../../shared/InputSearch';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import AddAdvertiser from '../../advertisers/advertisers/AddAdvertiser';
import {CreateAppModal} from '../../../../_metronic/partials';
import AddAChannel from './AddChannel';
import PaginationBe from '../../../shared/PaginationBe';

const ChannelsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Каналы', path: '/resources/telegram/channels', isActive: true},
      ]}
      title={'Каналы'}
    >
      <Channels />
    </PageWrapper>
  );
};

const Channels = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [channels, setChannels] = useState([]);
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);
  const [bots, setBots] = useState();
  const [page, setPage] = useState(1);
  const [selectedBot, setSelectedBot] = useState();
  const [selectedConfig, setSelectedConfig] = useState('all');
  const [queryText, setQueryText] = useState();
  const searchDebounceRef = useRef(null);
  const [params, setParams] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    isVerify && getChannels() && getBots();
  }, [isVerify]);

  const getBots = async () => {
    const botResult = await store.botsGetListBots();
    const newBots = botResult.map((el) => {
      return {label: el.name, value: el.id};
    });
    setBots(newBots);
  };

  const getChannels = async () => {
    const result = await store.channelsGetChannels();
    setTotal(result.total);
    setChannels(result.rows);
    setPreloader(false);
  };

  const updateChannels = async (filters) => {
    const result = await store.channelsGetChannelsNoPreloader(filters);
    setTotal(result.total);
    setChannels(result.rows);
    setPreloader(false);
  };

  const createParams = async (field, value) => {
    const newParams = {...params, page: 1, [field]: value};
    setParams(newParams);
    let queryStr = '';

    for (let [key, value] of Object.entries(newParams)) {
      if (value !== undefined) {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);

    updateChannels(queryStr);
  };

  const onSearch = (e) => {
    const query = e.target.value;

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    setQueryText(query);
    searchDebounceRef.current = setTimeout(() => {
      createParams('q', query);
    }, 1000);
  };

  const onPaginationChange = (page) => {
    const cleanedParams = Object.fromEntries(
      Object.entries({...params, page}).filter(([_, value]) => value !== undefined)
    );

    setParams(cleanedParams);

    const queryStr = new URLSearchParams(cleanedParams).toString();

    updateChannels(queryStr);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <div className='text-end ms-0 ms-sm-0 mb-5 mb-sm-5 mb-md-0 position-relative'>
              <i
                className='ki-duotone ki-magnifier fs-3 position-absolute'
                style={{left: '15px', top: '13px'}}
              >
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-13'
                placeholder='Поиск'
                value={queryText}
                onChange={onSearch}
              />
            </div>
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer min-w-200px d-flex align-items-center justify-content-between'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {selectedConfig && selectedConfig !== 'all' ? selectedConfig : 'Конфигурация'}{' '}
                <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      setSelectedConfig('vip');
                      createParams('config', 'vip');
                    }}
                  >
                    Vip
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSelectedConfig('main');
                      createParams('config', 'main');
                    }}
                  >
                    Main
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSelectedConfig('all');
                      createParams('config');
                    }}
                  >
                    Все конфиги
                  </span>
                </div>
              </div>
            </div>
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer min-w-225px d-flex align-items-center justify-content-between'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {selectedBot ? bots?.find((item) => item.value === selectedBot).label : ' Бот'}{' '}
                <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-225px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      setSelectedBot(undefined);
                      createParams('bot_id');
                    }}
                  >
                    Не выбрано
                  </span>
                </div>
                {bots?.map((item) => {
                  return (
                    <div className='menu-item px-3'>
                      <span
                        className='menu-link px-3'
                        onClick={() => {
                          setSelectedBot(item.value);
                          createParams('bot_id', item.value);
                        }}
                      >
                        {item.label}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <Button
                action={() => {
                  setShowCreateAppModal(true);
                  setSave(false);
                }}
              >
                <>
                  <i className='ki-duotone ki-plus fs-2'></i>Добавить
                </>
              </Button>
              <CreateAppModal
                customWidth='mw-550px'
                show={showCreateAppModal}
                handleClose={() => {
                  if (save) {
                    setShowCreateAppModal(false);
                  } else {
                    setWarning(true);
                  }
                }}
                title={'Добавление канала'}
              >
                <AddAChannel
                  bots={bots}
                  show={setShowCreateAppModal}
                  getChannels={getChannels}
                  warning={warning}
                  setWarning={setWarning}
                />
              </CreateAppModal>
            </div>
          </div>
        </div>
        <Table
          channels={channels}
          getChannels={getChannels}
          searchChannels={channels}
          bots={bots}
          page={page}
          setPage={setPage}
          total={total}
          onPaginationChange={onPaginationChange}
        />
      </Card>
    </>
  );
});

const Table = ({
  channels,
  getChannels,
  searchChannels,
  bots,
  page,
  setPage,
  total,
  onPaginationChange,
}) => {
  const [portionChannels, setPortionChannels] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newChannels = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (channels.length > i) {
  //       newChannels.push(channels[i]);
  //     }
  //   }
  //   setPortionChannels(newChannels);
  // }, [offset, channels]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>канал</th>
                <th className='min-w-125px'>telegram id</th>
                <th className='min-w-125px'>конфигурация</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchChannels.map((el, i) => {
                return <Channel key={el.id} data={el} getChannels={getChannels} bots={bots} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {channels.length ? (
        <PaginationBe
          arrLength={total}
          limit={10}
          page={page}
          setPage={setPage}
          action={(currentPage) => {
            onPaginationChange(currentPage);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const Channel = ({data, getChannels, bots}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className=''>{data.telegram_id}</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>{data.config}</div>
      </td>
      <td>{moment.tz(data.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <i className='ki-duotone ki-switch fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>

          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              setShowCreateAppModal(true);
              setSave(false);
            }}
          >
            <i className='ki-duotone ki-pencil fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
          <CreateAppModal
            customWidth='mw-550px'
            show={showCreateAppModal}
            handleClose={() => {
              if (save) {
                setShowCreateAppModal(false);
              } else {
                setWarning(true);
              }
            }}
            title={'Редактирование канала'}
          >
            <AddAChannel
              bots={bots}
              show={setShowCreateAppModal}
              getChannels={getChannels}
              warning={warning}
              setWarning={setWarning}
              isUpdate
              id={data.id}
            />
          </CreateAppModal>
        </div>
      </td>
    </tr>
  );
};

export default ChannelsWrapper;
