/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {IMaskInput} from 'react-imask';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import Warning from '../../../../../modules/Warning';
import {Selector, SelectorIsMulti} from '../../../../../shared/Selectors';
import {formatNumber} from '../../../../../functions/formatNumber';
import moment from 'moment';
import Preloader from '../../../../../modules/Preloader';

const Channels = observer(({bot, link, timeConfig}) => {
  const store = useStore();
  const [channels, setChannels] = useState([]);
  const [preloader, setPreloader] = useState(false);

  useEffect(() => {
    if (!!Object.keys(link).length) getChannels();
  }, [link, timeConfig]);

  const getChannels = async () => {
    setPreloader(true);
    const result = await store.trafficsInfluenceLinksChannelsGetChannels({
      link_id: link.id,
      timeConfig,
    });
    setChannels(result);
    setPreloader(false);
  };

  const onCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div className='card mb-0'>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Телеграм каналы</span>
        </h3>
        <div className={''}>
          {(store.roles.includes(4) || store.roles.includes(11) || store.roles.includes(36)) && <AddChannel bot={bot} link={link} channels={channels} updateChannels={getChannels} />}
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-250px rounded-start text-start'>Канал</th>
                <th className='min-w-150px text-start'>Telegram Link</th>
                <th className='min-w-80px text-center'>Лиды</th>
                <th className='min-w-100px text-center'>Обращения</th>
                <th className='min-w-100px text-center'>Регистрации</th>
                <th className='min-w-100px text-center'>Квалификации</th>
                <th className='min-w-100px text-center'>Создан</th>
                <th className='min-w-50px'></th>
                {/* <th className='min-w-150px'>Квалификации</th>
                <th className='min-w-150px'>LTC</th> */}
              </tr>
            </thead>

            <tbody>
              {channels.map((el) => {
                return (
                  <tr key={el.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img
                            src={
                              el?.channel_avatar
                                ? `${process.env.REACT_APP_API_HOST}/${el?.channel_avatar}`
                                : imagePlug
                            }
                            alt=''
                          />
                        </div>

                        <div className='d-flex text-hover-primary justify-content-start flex-column'>
                          <span className='text-gray-900 fw-bold   mb-1 fs-6'>
                            {el.channel_name}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            #{el.channel_id}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold   text-start d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                        {el.telegram_link}
                        <span className='ki-duotone ki-copy fs-3' style={{marginLeft: '6px', cursor: 'pointer'}} onClick={() => onCopy(el.telegram_link)}></span>
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-center  d-block mb-1 fs-6'>
                        {formatNumber(el.count_leads)}
                      </span>
                    </td>
                    <td>
                      <span className='text-gray-900 fw-bold  text-center d-block mb-1 fs-6'>
                        {formatNumber(el.count_appeal)}
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold  text-center d-block mb-1 fs-6'>
                        {formatNumber(el.count_registrations)}
                      </span>
                    </td>
                    <td>
                      <span className='text-gray-900 fw-bold  text-center d-block mb-1 fs-6'>
                        {formatNumber(el.count_qualifications)}
                      </span>
                    </td>
                    <td>
                      <span className='text-gray-900 fw-bold  text-center d-block mb-1 fs-6'>
                        {moment.tz(el.created * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm')}
                      </span>
                    </td>

                    <td>
                      {channels.length > 1 && (store.roles.includes(4) || store.roles.includes(11) || store.roles.includes(36)) && <ButtonDelete id={el.id} getChannels={getChannels} />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {preloader && <Preloader />}
    </div>
  );
});

const AddChannel = observer(({bot, link, channels, updateChannels}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
    watch,
  } = useForm();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  const [optionsChannels, setOptionsChannels] = useState([]);

  useEffect(() => {
    if (!link.id) return;
    setValue('link_id', link.id);
    getChannels(bot?.id);
  }, [showCreateAppModal]);

  const getChannels = async (bot_id) => {
    const getChannels = await store.channelsGetChannelsList2('main', bot_id);

    const newChannels = [];

    getChannels.forEach((c1) => {
      const find = channels.findIndex((c2) => c1.id === c2.channel_id);

      if (find === -1) {
        newChannels.push({label: c1.name, value: c1.id})
      }
    })

    // const newChannels = getChannels.map((el) => {
    //   return {label: el.name, value: el.id};
    // });

    setOptionsChannels(newChannels);
  };

  const onSubmit = async (data) => {
    const result = await store.trafficsInfluenceLinksChannelsAddChannel({
      ...data,
      link_id: Number(data.link_id),
      channel_ids: data.channel_ids.map((item) => item.value),
    });
    setShowCreateAppModal(false);
    reset();
    setSave(true);
    updateChannels();
    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Button action={() => setShowCreateAppModal(true)}>Добавить канал</Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление Рекламной кампании'}
      >
        <>
          <form
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Link ID</label>

              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder=''
                disabled
                {...register('link_id', {required: true})}
              />

              {errors?.link_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div> */}

            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Выберите Канал</label>
              <Controller
                name='channel_ids'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value, name, ref}}) => (
                  <SelectorIsMulti options={optionsChannels} value={value} onChange={onChange} />
                )}
              />

              {errors?.channel_ids?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            <div className='d-flex flex-end pt-10'>
              <input
                type='submit'
                className={`btn fw-bold  btn-primary cursor-pointer `}
                value={'Сохранить'}
              />
            </div>
          </form>
          <CreateAppModal
            customWidth='mw-400px'
            padding='pb-lg-10 pt-lg-10 px-lg-10'
            show={warning}
            handleClose={() => setWarning(false)}
            showHeader={false}
            isMessage={true}
          >
            <Warning
              buttons={[
                {
                  action: () => {
                    setWarning(false);
                  },
                  title: <i className='bi bi-x p-0 fs-1'></i>,
                  class: 'btn-light',
                },
                {
                  action: () => {
                    setWarning(false);
                    setShowCreateAppModal(false);
                    reset();
                  },
                  title: <i className='bi bi-check p-0 fs-1'></i>,
                  class: 'btn-light-primary',
                },
              ]}
            />
          </CreateAppModal>
        </>
      </CreateAppModal>
    </>
  );
});

const ButtonDelete = observer(({id, getChannels}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const deleteLink = async () => {
    const result = await store.trafficsInfluenceLinksChannelsDeleteChannel({id});

    if (!result) {
      return toast.error(`Произошла ошибка в удалении!`, {
        theme: 'colored',
      });
    }
    setShowCreateAppModal(false)
    getChannels();
    toast.success(`Успешно удалено!`);
  };

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => setShowCreateAppModal(true)}
      >
        <i className='ki-duotone ki-trash fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>
      </span>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={<span>Вы уверены что хотите удалить этот канал </span>}
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                deleteLink();
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default Channels;
