/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {formatNumber, formatNumberToCash} from '../../../../functions/formatNumber';
import moment from 'moment';
import Card from '../../../../shared/Card';
import Warning from '../../../../modules/Warning';
import userPlug from '../../../../../_metronic/assets/media/user.png';
import {useThemeMode} from '../../../../../_metronic/partials';
import ButtonExelForTable from '../../../../modules/ButtonExelForTable';
import {SelectorWithSearch} from '../../../../shared/Selectors';

const Scheme = ({
  title,
  subTitle,
  data,
  searchScheme,
  schemeFilters,
  advertisersOptions,
  offersOptions,
  time,
}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>

          <div style={{display: 'flex', gap: '10px'}}>
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '42px',
                  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#9A9CAE !important' : '#4B5675 !important',
                  }),
                }),
              }}
              placeholder='Рекламодатель...'
              options={advertisersOptions}
              actions={(e) => {
                if (e) {
                  return searchScheme({advertiser_id: e.value});
                }
                searchScheme({advertiser_id: ''});
              }}
              value={advertisersOptions.find((item) => schemeFilters.advertiser_id === item.value)}
              clearable={true}
            />
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '42px',
                  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#9A9CAE !important' : '#4B5675 !important',
                  }),
                }),
              }}
              placeholder='Офферы...'
              options={offersOptions}
              value={offersOptions.find((item) => schemeFilters.offer_id === item.value)}
              actions={(e) => {
                if (e) {
                  return searchScheme({offer_id: e.value});
                }
                searchScheme({offer_id: ''});
              }}
              clearable={true}
            />
            <ButtonExelForTable
              data={data.map((item) => {
                return {...item, day: moment(item?.day * 1000).format('ll')};
              })}
              fieldsToKeep={[
                'day',             
                'count_leads',
                'count_registrations',
                'count_qualifications',
                'count_appeals',
                'revenue',
                'count_cost',
                'count_first_deposit',
                'ltc',
              ]}
              columnFormats={{revenue: '$#,##0.00', count_cost: '$#,##0.00'}}
              fileName={'companies'}
              time={time}
            />
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>Дата</th>
                  <th className='min-w-120px text-center'>Лиды</th>
                  <th className='min-w-120px text-center'>Чаты</th>
                  <th className='min-w-120px text-center'>Регистрации</th>
                  <th className='min-w-120px text-center'>Первые депозиты</th>
                  <th className='min-w-120px text-center'>Квалификации</th>
                  {!store.roles.includes(6) && <th className='min-w-120px text-center'>Доход</th>}
                  {!store.roles.includes(6) && <th className='min-w-120px text-center'>Расходы</th>}
                  <th className='min-w-120px text-center'>LTC</th>
                </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
                {data?.map((el) => {
                  return (
                    <tr key={el?.day}>
                      <td>
                        <span>{moment.tz(el?.day * 1000, 'Europe/Kyiv').format('ll')}</span>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {el?.count_leads}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {el?.count_appeals}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {el?.count_registrations}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {el?.count_first_deposit}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {el?.count_qualifications}
                        </span>
                      </td>
                      {!store.roles.includes(6) && (
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                            {formatNumberToCash(el?.revenue)}
                          </span>
                        </td>
                      )}
                      {!store.roles.includes(6) && (
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                            {formatNumberToCash(el?.count_cost)}
                          </span>
                        </td>
                      )}
                      <td>
                        <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                          {formatNumber(el?.ltc)}%
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};

export default observer(Scheme);
