/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useParams, Link} from 'react-router-dom';
import moment from 'moment';
import userPlug from '../../../../_metronic/assets/media/user.png';
import EditRole from './EditRole';
import Card from '../../../shared/Card';
import InputSearch from '../../../shared/InputSearch';
import RoleCard from './RoleCard';
import Pagination from '../../../shared/Pagination';
import PageWrapper from '../../../modules/PageWrapper';

const RolesViewWrapper = () => {
  const {id} = useParams();

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: `Список ролей`, path: `/users/roles`, isActive: true},
        {isSeparator: true},
        {title: `Просмотр роли #${id}`, path: `/users/roles/${id}`, isActive: true},
      ]}
      title={`Просмотр роли #${id}`}
    >
      <RolesView />
    </PageWrapper>
  );
};

const RolesView = observer(({isVerify, setPreloader}) => {
  const store = useStore();
  const {id} = useParams();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [portionUsers, setPortionUsers] = useState([]);

  const [card, setCard] = useState();
  const [users, setUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [usersList, getDepartments] =
      await Promise.all([
        store.usersRolesGetUserList(id),
        store.departamentsGetList(),
      ]);

    const findDepartment = getDepartments.find((item) => +item.id === +usersList.departament_id);

    const newUsers = usersList?.users.map((el) => {
      const fullName = `${el.first_name} ${el.last_name}`;
      return {...el, fullName};
    });

    setUsers(newUsers);
    setSearchUsers(newUsers);
    setCard({...usersList, department: findDepartment});
    setPreloader(false);
  };

  useEffect(() => {
    const newUsers = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (searchUsers.length > i) {
        newUsers.push(searchUsers[i]);
      }
    }
    setPortionUsers(newUsers);
  }, [offset, searchUsers]);

  useEffect(() => {
    setOffset(1);
  }, [searchUsers]);

  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10'>
          <RoleCard el={card} isView={true} />
        </div>

        <div className='flex-lg-row-fluid ms-lg-10'>
          <Card className='card card-flush mb-6 mb-xl-9'>
            <div className='card-header pt-5'>
              <div className='card-title'>
                <h2 className='d-flex align-items-center'>
                  Пользователи с ролью
                  <span className='text-gray-600 fs-6 ms-1'>({searchUsers.length})</span>
                </h2>
              </div>

              <div className='card-toolbar'>
                <div
                  className='d-flex align-items-center position-relative my-1'
                  data-kt-view-roles-table-toolbar='base'
                >
                  <InputSearch
                    title={'Поиск...'}
                    array={users}
                    newArray={setSearchUsers}
                    searchParameters={['id', 'fullName']}
                  />
                </div>
              </div>
            </div>

            <div className='card-body pt-0'>
              <div
                id='kt_roles_view_table_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer'
                    id='kt_roles_view_table'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-50px sorting' style={{width: '70.7812px'}}>
                          ID
                        </th>
                        <th className='min-w-150px sorting' style={{width: '251.984px'}}>
                          ПОЛЬЗОВАТЕЛЬ
                        </th>
                        <th className='min-w-125px sorting' style={{width: '189.5px'}}>
                          НАЧАЛО РОБОТЫ
                        </th>
                        <th
                          className='text-end min-w-100px sorting_disabled'
                          style={{width: '120.344px'}}
                        >
                          ПРОСМОТР
                        </th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {portionUsers.map((el) => {
                        return (
                          <tr className='odd' key={el.id}>
                            <td>#{el?.id}</td>
                            <td className='d-flex align-items-center'>
                              <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                <span>
                                  <div className='symbol-label'>
                                    <img
                                      src={
                                        !!el?.avatar
                                          ? `${process.env.REACT_APP_API_HOST}/${el.avatar}`
                                          : userPlug
                                      }
                                      alt='#'
                                      className='w-100'
                                    />
                                  </div>
                                </span>
                              </div>

                              <div className='d-flex flex-column'>
                                <span className='text-gray-800 text-hover-primary mb-1'>
                                  {el.fullName}
                                </span>
                              </div>
                            </td>
                            <td data-order='2023-09-22T11:05:00+03:00'>
                              {moment.tz(el?.created_at * 1000, 'Europe/Kyiv').format('DD/MM/YYYY')}
                            </td>
                            <td className='text-end'>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <Link to={`/users/list/user/${el.id}/info`}>
                                  <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                    <i className='ki-duotone ki-switch fs-2'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  </span>
                                </Link>

                                {store.roles.includes(13) || store.roles.includes(32) ? (
                                  <></>
                                ) : (
                                  <Link to={`/users/list/user/${el.id}/settings`}>
                                    <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                      <i className='ki-duotone ki-pencil fs-2'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                      </i>
                                    </span>
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {searchUsers.length >= limit && (
                  <Pagination
                    arrLength={searchUsers.length}
                    limit={limit}
                    offset={offset}
                    setOffset={setOffset}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
});

export default RolesViewWrapper;
