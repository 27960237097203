import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {Button} from '../../../../shared/Buttons';
import {useStore} from '../../../../../store/store';
import {toast} from 'react-toastify';
import {formateImage} from '../../../../functions/formatImage';
import {base64ToBlob} from '../../../../functions/base64ToBlob';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import Warning from '../../../../modules/Warning';

export const AddBrandModal = ({getData, id, isUpdate = false}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      {isUpdate ? (
        <i
          className='ki-duotone ki-pencil fs-7'
          onClick={() => {
            setShowCreateAppModal(true);
            setSave(false);
          }}
        >
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      ) : (
        <Button
          action={() => {
            setShowCreateAppModal(true);
            setSave(false);
          }}
        >
          <>
            <i className='ki-duotone ki-plus fs-2'></i>Добавить
          </>
        </Button>
      )}

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={isUpdate ? 'Изменения бренда' : 'Добавление бренда'}
      >
        <AddBrand
          getData={getData}
          show={setShowCreateAppModal}
          warning={warning}
          setWarning={setWarning}
          id={isUpdate ? id : undefined}
          isUpdate={isUpdate}
        />
      </CreateAppModal>
    </>
  );
};

export const AddBrand = ({show, warning, setWarning, getData, isUpdate = false, id}) => {
  const store = useStore();
  const {
    register,
    setValue,
    watch,
    formState: {errors},
    handleSubmit,
  } = useForm();

  const [image, setImage] = useState('');

  useEffect(() => {
    isUpdate && getDataForUpdating();
    !isUpdate && formateImage('', setImage);
  }, []);

  const getDataForUpdating = async () => {
    const result = await store.getBrand(id);
    setValue('name', result?.name);
    setValue('link', result?.link);
    setValue('note', result?.note);
    formateImage(result?.avatar, setImage);
  };

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === 'avatar') {
        if (!!value.length) {
          formData.append(`${key}`, value[0]);
          continue;
        } else if (!isUpdate){
          const blob = base64ToBlob(imagePlug, 'image/png');
          const file = new File([blob], 'user.png', {type: 'image/png'});
          formData.append(key, file);
        }
        continue;
      }
      formData.append(`${key}`, value || '');
    }

    if (isUpdate) {
      const result = await store.updateBrand(formData, id);
      !!result &&
        toast.success(`Успешно обновлено!`, {
          onOpen: async () => {
            await getData();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка в обновлении!`, {
          theme: 'colored',
        });
    } else {
      const result = await store.addBrand(formData);
      !!result &&
        toast.success(`Успешно сохранено!`, {
          onOpen: async () => {
            await getData();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка сохранения!`, {
          theme: 'colored',
        });
    }
  };

  return (
    <>
      <form
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='fv-row mb-10 d-flex flex-column flex-start'>
          <label className='form-label'>Аватар</label>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              className='image-input-wrapper w-125px h-125px'
              style={{
                backgroundImage: image,
              }}
            ></div>

            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              aria-label='Change avatar'
              data-bs-original-title='Change avatar'
              data-kt-initialized='1'
            >
              <i className='ki-duotone ki-pencil fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg .webp .gif' {...register('avatar')} />
              <input type='hidden' name='avatar_remove' />
            </label>
          </div>
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Название бренда</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('name', {required: true})}
          />

          {errors.name?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Ссылка</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('link', {required: true})}
          />

          {errors.name?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='fw-semibold fs-6 mb-2'>Заметка</label>

          <textarea
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('note', {required: false})}
          />
        </div>
        <div className='d-flex flex-end pt-10'>
          <input
            type='submit'
            className={`btn fw-bold  btn-primary cursor-pointer `}
            value={isUpdate ? 'Сохранить изменения' : 'Сохранить'}
          />
        </div>

        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={warning}
          handleClose={() => setWarning(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            buttons={[
              {
                action: () => {
                  setWarning(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: () => {
                  setWarning(false);
                  show(false);
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </form>
    </>
  );
};
