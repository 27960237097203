import React, {useState, useEffect} from 'react';
import css from '../../styles/FacebookStatistics.module.scss';
import classNames from 'classnames';

const SearchableParams = ({params, deleteSavedOption, deleteOptions}) => {
  return (
    <>
      {params.some((item) => item?.options?.length > 0) && (
        <section className={css.filtersListContainer}>
          {params.map((item, index) => {
            return (
              <React.Fragment key={`${item.label}-${index}`}>
                {!!item?.options && item?.options?.length > 0 && (
                  <section className={css.list}>
                    <span className={css.name}>{item.label}:</span>
                    {item.options.map((el, i) => {
                      return (
                        <React.Fragment key={el.label}>
                          {i <= 2 && (
                            <div className={classNames(css.element, css._filter)}>
                              {el.flag && (
                                <img
                                  src={`${el?.flag}`}
                                  alt=''
                                  style={{
                                    width: '20px',
                                    height: '15px',
                                    marginRight: '5px',
                                    objectFit: 'contain',
                                  }}
                                />
                              )}
                              <span className={css.elementName}>{el.label}</span>
                              <span
                                className={css.elementRemove}
                                onClick={() => deleteSavedOption(index, i)}
                              ></span>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                    {item.options.length > 3 && (
                      <div className={classNames(css.element, css.otherElements, css.lastElements)}>
                        <span className={classNames(css.elementName)}>
                          +{item.options.length - 3}
                        </span>
                        <div className={css.elements}>
                          {item.options.map((el, i) => {
                            return (
                              <React.Fragment key={el.label}>
                                {i > 2 && (
                                  <span className={classNames(css.element, css.lastElements)}>
                                    {el.flag && (
                                      <img
                                        src={`${el?.flag}`}
                                        alt=''
                                        style={{
                                          width: '20px',
                                          height: '15px',
                                          marginRight: '5px',
                                          objectFit: 'contain',
                                        }}
                                      />
                                    )}
                                    {el.label}
                                  </span>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <i
                      className='ki-outline ki-basket fs-2 text-hover-danger cursor-pointer'
                      onClick={() => deleteOptions(index)}
                    ></i>
                  </section>
                )}
              </React.Fragment>
            );
          })}
        </section>
      )}
    </>
  );
};

export default SearchableParams;
