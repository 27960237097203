import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import clsx from 'clsx';
import moment from 'moment';
import {toast} from 'react-toastify';

import {useThemeMode} from '../../../../_metronic/partials';

import {postBackColumns, postbackConversion} from '../conversions/columns';
import css from '../conversions/Conversions.module.scss';

export const PostBackTable = ({data, activeColumns}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  const filteredColumns = postBackColumns.filter((col) => !activeColumns.includes(col));

  return (
    <div
      id='facebook_statistics_table'
      className={clsx(css.table__container)}
      style={{
        '--bg': themeMode === 'dark' ? '#15171C' : '#FCFCFC',
        '--borderTable': themeMode === 'dark' ? 'rgba(30, 31, 36, 1)' : '#E4E4E5',
        '--bgRowTable': themeMode === 'dark' ? 'rgba(27, 29, 35, 1)' : 'rgba(245, 246, 247, 1)',
        '--bgRowTableActive':
          themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
        '--bgRowTableHover':
          themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
      }}
    >
      <table className={css.table}>
        <thead className={css.table__head}>
          <tr className={clsx(css.table__tr)}>
            {filteredColumns.map((col, index) => (
              <th key={index} className={clsx(css.table__th, 'text-gray-500')}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.conversion_id} className={clsx(css.table__tr)}>
              {!activeColumns.includes('Результат') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: !!item.result ? '#34C77E' : '#FF3B30',
                    }}
                  ></div>
                </td>
              )}
              {!activeColumns.includes('Дата') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {moment.tz(item.created_at * 1000, 'Europe/Kyiv').format('H:mm:ss, DD/MM/YYYY')}
                </td>
              )}
              {!activeColumns.includes('Рекламодатель') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {item.advertiser_id ? (
                    <Link
                      to={`/advertisers/list/${item.advertiser_id}`}
                      target='_blank'
                      style={{display: 'flex', gap: '10px'}}
                    >
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'dotted',
                          textUnderlineOffset: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {`#${item.advertiser_id} ${item.advertiser_name}`}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
              )}
              {!activeColumns.includes('Оффер') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {item.offer_id ? (
                    <Link
                      to={`/advertisers/offers/${item.offer_id}`}
                      target='_blank'
                      style={{display: 'flex', gap: '10px'}}
                    >
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'dotted',
                          textUnderlineOffset: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {`#${item.offer_id} ${item.offer_name}`}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
              )}
              {!activeColumns.includes('Конверсия') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {postbackConversion[item.conversion_type]}
                </td>
              )}
              {!activeColumns.includes('Lead ID') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {item.lead_id ? (
                    <Link to={`#`} style={{display: 'flex', gap: '10px'}}>
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'dotted',
                          textUnderlineOffset: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {item.lead_id}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
              )}
              {!activeColumns.includes('Байер') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {item.bayer_id ? (
                    <Link
                      to={`/tracker/${item.type}/bayers/${item.bayer_id}`}
                      target='_blank'
                      style={{display: 'flex', gap: '10px'}}
                    >
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'dotted',
                          textUnderlineOffset: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {`#${item.bayer_id} ${item.bayer_name}`}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
              )}
              {!activeColumns.includes('Platform ID') && (
                <td
                  className={clsx(css.table__td, css.table__name)}
                  onClick={() => item.platform_id && copyText(item.platform_id)}
                  style={{
                    cursor: item.platform_id && 'copy',
                    textDecoration: item.platform_id && 'underline',
                  }}
                >
                  {item.platform_id ?? '-'}
                </td>
              )}
              {!activeColumns.includes('IP') && (
                <td
                  className={clsx(css.table__td, css.table__name)}
                  onClick={() => item.ip_address && copyText(item.ip_address)}
                  style={{
                    cursor: item.ip_address && 'copy',
                    textDecoration: item.ip_address && 'underline',
                  }}
                >
                  {item.ip_address ?? '-'}
                </td>
              )}
              {!activeColumns.includes('ID Конверсии') && (
                <td
                  className={clsx(css.table__td, css.table__name)}
                  onClick={() => item.conversion_id && copyText(item.conversion_id)}
                  style={{
                    cursor: item.conversion_id && 'copy',
                    textDecoration: item.conversion_id && 'underline',
                  }}
                >
                  {item.conversion_id ?? '-'}
                </td>
              )}
              {/*  */}
              {!activeColumns.includes('Адрес постбека') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  <div style={{wordBreak: 'break-all', width: '320px', textWrap: 'initial'}}>
                    {item.postback_path ?? '-'}
                  </div>
                </td>
              )}
              {!activeColumns.includes('Event ID') && (
                <td className={clsx(css.table__td, css.table__name)}>
                  {item.event_id ? `#${item.event_id}` : '-'}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
