/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import css from '../../../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import BlockLine from '../BlockLine';
import BlockTitles from '../BlockTitles';
import {base_lucky, lucky} from '../objectsTemplates';

const Form = ({themeMode, config, setConfig, currentLang, post, id, token, createPwa, setId}) => {
  const [timeVersion, setTimeVersion] = useState(0);

  const deleteImage = async (id, name, dir) => {
    fetch('https://api.raulnk.com/uploads', {
      method: 'DELETE',
      body: JSON.stringify({
        id: +id,
        lang: currentLang,
        name,
        dir,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleIcon = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }
    const renamedFile = new File([e.target.files[0]], 'icon.png', {type: e.target.files[0].type});

    if (!!config[currentLang]?.appIcon) {
      await deleteImage(id || newId, config[currentLang]?.appIcon, 'images/icons');
    }

    const formData = new FormData();
    formData.append('file', renamedFile); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', 'icon.png');
    formData.append('dir', 'images/icons');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setConfig({
          ...config,
          [currentLang]: {
            ...config[currentLang],
            appIcon: 'icon.png',
          },
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleSlides = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    const inputFiles = Array.from(e.target.files);
    const slidesLength = config[currentLang]?.slides?.length;

    const imagesNames = inputFiles.map((item, index) => {
      return `${index + slidesLength}_slide.png`;
    });

    for (const [index, file] of inputFiles.entries()) {
      const newName = `${index + slidesLength}_slide.png`;

      const newFile = new File([file], newName, {
        type: file.type,
      });

      // Відправляємо файл на сервер
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('id', id || newId);
      formData.append('lang', currentLang);
      formData.append('name', newName);
      formData.append('dir', `images/slides`);

      try {
        const response = await fetch('https://api.raulnk.com/uploads', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          console.log(`Файл ${newName} успішно відправлено`);

          if (index === inputFiles.length - 1) {
            const newSlides = [...config[currentLang].slides, ...imagesNames];
            setConfig({...config, [currentLang]: {...config[currentLang], slides: newSlides}});
          }
          setTimeVersion(new Date().getTime());
        } else {
          console.error(`Помилка при відправці файлу ${newName}`);
        }
      } catch (error) {
        console.error(`Помилка мережі для файлу ${newName}:`, error);
      }
    }
  };

  const deleteSlide = async (index, item) => {
    await deleteImage(id, item, 'images/slides');

    setConfig((prevConfig) => {
      const newSlides = [...prevConfig[currentLang].slides];
      newSlides.splice(index, 1);
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          slides: newSlides,
        },
      };
    });
  };

  const inputFields = [
    {placeholders: ['Заголовок'], keys: ['title']},
    {placeholders: ['Ожидание ставки'], keys: ['waiting_text']},
    {placeholders: ['Кнопка "Ставка"'], keys: ['button_text']},
    {placeholders: ['Год патента'], keys: ['patent_text']},
    {placeholders: ['Текст патент'], keys: ['patent_years']},
    {placeholders: ['Поздравление'], keys: ['greeting']},
    {placeholders: ['Увеличение депозита'], keys: ['deposit_text']},
    {placeholders: ['Кнопка "Забрать бонус"'], keys: ['button_2_text']},
  ];

  const handleInputChange = (key, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [currentLang]: {
        ...prevConfig[currentLang],
        inscriptions: {
          ...prevConfig[currentLang].inscriptions,
          [key]: key === 'countDownloads' ? value.replace(/\D/g, '') : value,
        },
      },
    }));
  };

  return (
    <>
      <BlockTitles
        title={'Приложение'}
        description={'На данном этапе настраивается внешний вид Приложения и текстовое содержание.'}
      />
      <BlockLine />
      <div className={css.blockConfig}>
        <span>
          Использовать базовую конфигурацию (если вы выберете этот вариант, контент на этой вкладке
          будет перезаписан):
        </span>
        <input
          checked={config[currentLang]?.base || false}
          type='checkbox'
          onChange={(e) => {
            if (!!e.target.checked) {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  inscriptions: base_lucky.inscriptions,
                  base: e.target.checked,
                },
              });
            } else {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  inscriptions: lucky.inscriptions,
                  base: e.target.checked,
                },
              });
            }
          }}
        />
      </div>
      <BlockLine />
      <div className={css.blockHeader}>
        <div
          className={css.icon}
          style={{
            backgroundImage: !config[currentLang]?.appIcon
              ? `url(${imagePlug})`
              : `url(https://api.raulnk.com/${id}/images/icons/${currentLang}/${config[currentLang]?.appIcon}?v=${timeVersion})`,
          }}
        >
          <label htmlFor='appIcon'>
            <div className={classNames(css.delete)}></div>
            <input
              type='file'
              accept='.png, .jpg, .jpeg .webp .gif'
              name=''
              id='appIcon'
              onChange={handleIcon}
            />
          </label>
        </div>
        <div className={css.inputs} style={{marginTop: '10px'}}>
          <label htmlFor='' className={css.inputField}>
            <span>Название приложения</span>
            <input
              type='text'
              value={config[currentLang]?.appName || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {...config[currentLang], appName: e.target.value},
                })
              }
            />
          </label>
        </div>
      </div>
      <BlockLine />
      <BlockTitles title={'Логотипы'} description={'Добавте один или два логотипа'} />
      <div className={css.blockSlides}>
        <div className={css.slides}>
          {config[currentLang]?.slides?.map((item, index) => {
            return (
              <div
                className={css.slide}
                key={`${item}-${index}`}
                style={{
                  backgroundImage: `url(https://api.raulnk.com/${id}/images/slides/${currentLang}/${item}?v=${timeVersion})`,
                }}
              >
                <div
                  className={css.delete}
                  onClick={() => {
                    deleteSlide(index, item);
                  }}
                />
              </div>
            );
          })}
        </div>
        {config[currentLang]?.slides.length <= 2 && (
          <label className={css.add} htmlFor='slides'>
            <input
              type='file'
              accept='.png, .jpg, .jpeg .webp .gif'
              name=''
              id='slides'
              onChange={handleSlides}
            />
          </label>
        )}
      </div>
      <BlockLine />

      <BlockTitles
        title={'Локализация приложения'}
        description={
          'В этом блоке вы можете заполнить перевод оставшихся элементов интерфейса на странице, а также их метрики.'
        }
      />
      <div className={css.blockLocalization}>
        {inputFields.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
      </div>
      <BlockLine />
      <BlockTitles
        title={'Работа с платформой'}
        description={
          'В этом блоке вы настраиваете как приложение будет работать с платформой (IOS, Android).'
        }
      />
      <div className={css.blockLocalization}>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
          <div>
            <div className={css.t}>IOS</div>
            <div className={css.radioButtons}>
              <label htmlFor=''>
                Redirect
                <input
                  style={{accentColor: '#0b57cf'}}
                  type='radio'
                  name='ios'
                  value='#0b57cf'
                  id=''
                  checked={!config[currentLang].ios}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [currentLang]: {...config[currentLang], ios: !e.target.value},
                    })
                  }
                />
              </label>
              <label htmlFor=''>
                Install
                <input
                  style={{accentColor: '#0b57cf'}}
                  type='radio'
                  name='ios'
                  value='#00a173'
                  id=''
                  checked={config[currentLang].ios}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [currentLang]: {...config[currentLang], ios: e.target.value},
                    })
                  }
                />
              </label>
            </div>
          </div>
          <div>
            <div className={css.t}>Android</div>
            <div className={css.radioButtons}>
              <label htmlFor=''>
                Redirect
                <input
                  style={{accentColor: '#0b57cf'}}
                  type='radio'
                  name='android'
                  value='#0b57cf'
                  id=''
                  checked={!config[currentLang].android}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [currentLang]: {...config[currentLang], android: !e.target.value},
                    })
                  }
                />
              </label>
              <label htmlFor=''>
                Install
                <input
                  style={{accentColor: '#0b57cf'}}
                  type='radio'
                  name='android'
                  value='#00a173'
                  id=''
                  checked={config[currentLang].android}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [currentLang]: {...config[currentLang], android: e.target.value},
                    })
                  }
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
