/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import css from '../../styles/FacebookStatisticsTable.module.scss';
import classNames from 'classnames';
import {customizationColumns} from './customizationTable';
import {Button} from '../shared/Buttons';
import {CreateAppModal} from '../../_metronic/partials';
import {TrekerButton} from '../pages/traffic/influence/bloggers/source/Treker/TrekerButton';

export const ColumnsFilter = ({
  linkStore,
  data,
  activeColumns,
  setActiveColumns,
  defaultLinkStore = null,
}) => {
  const [showColumnsFilter, setShowColumnsFilter] = useState(false);
  const [actives, setActives] = useState(null);

  useEffect(() => {
    setActives(activeColumns);
  }, [activeColumns]);

  const columnFilterList = Object.entries(data).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return {
        column: {name: key},
        subColumns: Object.keys(value).map((subKey) => ({
          name: subKey,
        })),
      };
    } else {
      return {
        column: {name: key},
        subColumns: [],
      };
    }
  });

  const handelChange = (label) => {
    const newActiveColumns = [...actives];
    const findIndex = newActiveColumns.findIndex((item) => item === label);
    if (findIndex === -1) {
      newActiveColumns.push(label);
    } else {
      newActiveColumns.splice(findIndex, 1);
    }

    // setActiveColumns(newActiveColumns);
    // localStorage.setItem(linkStore, JSON.stringify(newActiveColumns));
    setActives(newActiveColumns);
  };

  const handelChangeForLabels = (items) => {
    const newActiveColumns = [...actives];
    const isAllActive = items.every(({name}) => actives.includes(name));

    !isAllActive
      ? items.forEach(({name}) => {
          if (!newActiveColumns.includes(name)) {
            newActiveColumns.push(name);
          }
        })
      : items.forEach(({name}) => {
          const index = newActiveColumns.indexOf(name);
          if (index !== -1) {
            newActiveColumns.splice(index, 1);
          }
        });

    // setActiveColumns(newActiveColumns);
    // localStorage.setItem(linkStore, JSON.stringify(newActiveColumns));
    setActives(newActiveColumns);
  };

  return (
    <>
      <TrekerButton onClick={() => setShowColumnsFilter(true)}>Колонки</TrekerButton>
      <CreateAppModal
        customWidth='mw-700px'
        show={showColumnsFilter}
        handleClose={() => setShowColumnsFilter(false)}
        showHeader={true}
        title='Колонки'
        isMessage={false}
      >
        <div className={css.columnFilters}>
          <div className={css.column}>
            {columnFilterList.map((item) => {
              if (item.subColumns?.length > 0) {
                const checkedGroup = item.subColumns.some(({name}) => !actives?.includes(name));
                return (
                  <div
                    className={classNames(
                      css.group,
                      item.column.name === 'clicks' && css._1,
                      item.column.name === 'leads' && css._2,
                      item.column.name === 'chats' && css._3,
                      item.column.name === 'registrations' && css._4,
                      item.column.name === 'first_deposits' && css._5,
                      item.column.name === 'qualifications' && css._6,
                      item.column.name === 'finance' && css._7
                    )}
                    key={item.column.name}
                  >
                    <label
                      key={item.name}
                      className='checkbox d-flex align-items-center mb-4 gap-5'
                      style={{cursor: 'pointer'}}
                    >
                      <input
                        type='checkbox'
                        checked={checkedGroup}
                        onChange={() => handelChangeForLabels(item.subColumns)}
                      />
                      <strong className='text-uppercase'>
                        {customizationColumns(item.column, true).label}
                      </strong>
                    </label>
                    {item.subColumns.map((subItem) => {
                      const c = customizationColumns(subItem, true);
                      return (
                        <label
                          key={subItem.name}
                          className='checkbox d-flex align-items-center gap-5'
                          style={{cursor: 'pointer'}}
                        >
                          <input
                            type='checkbox'
                            checked={!actives?.includes(c?.name)}
                            // onChange={(e) => filterColumns(subItem.name, !e.target.checked)}
                            onChange={(e) => handelChange(c.name)}
                          />
                          <span>{c.label}</span>
                        </label>
                      );
                    })}
                  </div>
                );
              }

              return null;
            })}
            {columnFilterList.map((item) => {
              if (item.subColumns?.length > 0) return null;
              const c = customizationColumns(item.column, true);
              if (
                c.name === 'avatar' ||
                c.name === 'is_active' ||
                c.name === 'day_date' ||
                c.name === 'month_date' ||
                c.name === 'year_date'
              )
                return null;

              return (
                <label
                  key={item.column.name}
                  className='checkbox d-flex align-items-center gap-3'
                  style={{cursor: 'pointer'}}
                >
                  <input
                    type='checkbox'
                    checked={!actives?.includes(c.name)}
                    onChange={(e) => handelChange(c.name)}
                  />
                  <span>{c.label}</span>
                </label>
              );
            })}
          </div>
          <div className='d-flex mt-20 justify-content-between'>
            <div>
              <Button
                color='btn-secondary'
                className='d-flex align-items-center'
                action={() => {
                  if (!!defaultLinkStore) {
                    setActiveColumns(defaultLinkStore);
                    localStorage.setItem(linkStore, JSON.stringify(defaultLinkStore));
                  } else {
                    localStorage.removeItem(linkStore);
                    setActiveColumns([]);
                  }
                }}
              >
                <i className='bi bi-trash3 fs-3 text-gray-900'></i> По умолчанию
              </Button>
            </div>
            <div>
              <Button
                color='btn-secondary me-4'
                action={() => {
                  setShowColumnsFilter(false);
                }}
              >
                Закрыть
              </Button>
              <Button
                color='btn-primary'
                action={() => {
                  setActiveColumns(actives);
                  localStorage.setItem(linkStore, JSON.stringify(actives));
                  setShowColumnsFilter(false);
                }}
              >
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
};
