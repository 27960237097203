/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Stepper from '../../../modules/Stepper';
import PageWrapper from '../../../modules/PageWrapper';
import {SelectorWithSearch, SelectorWithCounties} from '../../../shared/Selectors';

const AddOfferWrapper = ({isUpdate = false}) => {
  const {id} = useParams();
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Офферы', path: '/advertisers/offers', isActive: true},
        {isSeparator: true},
        {
          title: isUpdate ? `Изменение данных Оффера #${id}` : 'Добавление Оффера',
          path: isUpdate ? `/advertisers/offers/updateOffer/${id}` : '/advertisers/offers/add',
          isActive: true,
        },
      ]}
      title={isUpdate ? `Изменение данных Оффера #${id}` : 'Добавление Оффера'}
    >
      <AddOffer isUpdate={isUpdate} id={id} />
    </PageWrapper>
  );
};

const links = [
  {title: 'Общее', link: 'common'},
  {title: 'Финансы', link: 'finances'},
  {title: 'Настройки', link: 'settings'},
];

const AddOffer = ({isVerify, setPreloader, isUpdate, id}) => {
  const store = useStore();

  const [page, setPage] = useState('common');

  const [data, setData] = useState({});
  const [advertisersList, setAdvertisersList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    isVerify && getData();
    isVerify && isUpdate && getOfferData();
  }, [isVerify]);

  const getData = async () => {
    const [getAdvertiserList, getBrandsList, getCountries] = await Promise.all([
      await store.getAdvertisersList(),
      store.getBrandsList(),
      store.getCountries(),
    ]);

    setAdvertisersList(getAdvertiserList.map((item) => ({label: item?.name, value: item?.id})));
    setBrandsList(getBrandsList.map((item) => ({label: item?.name, value: item?.id})));
    setCountries(
      getCountries.map((item) => ({label: item?.name, value: item?.id, flag: item.flag}))
    );

    setPreloader(false);
  };

  const getOfferData = async () => {
    const result = await store.offersGetOffer(id);
    setData({...result, advertiser_id: result?.advertiser?.id});
    setPreloader(false);
  };

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Stepper links={links} page={page} />
          <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
            {page === 'common' && (
              <Common
                advertisersList={advertisersList}
                brandsList={brandsList}
                countries={countries}
                setData={setData}
                data={data}
                setPage={setPage}
                isUpdate={isUpdate}
              />
            )}
            {page === 'finances' && <Finances setData={setData} data={data} setPage={setPage} />}
            {page === 'settings' && (
              <Settings setData={setData} data={data} setPage={setPage} isUpdate={isUpdate} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Common = ({advertisersList, brandsList, countries, setData, data, setPage, isUpdate}) => {
  const {
    control,
    register,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setValue('name', data?.name);
    setValue('advertiser_id', data?.advertiser_id);
    setValue('advertise_offer_id', data?.advertise_offer_id);
    setValue('brand_id', data?.brand_id);
    setValue('country_id', data?.country_id);
  }, [data]);

  const onSubmit = (params) => {
    setData({...data, ...params, advertiser_id: Number(params.advertiser_id)});
    setPage('finances');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title
        title={'Общая информация'}
        subTitle={'Вводите данные корректно, это может влиять на отображение статистики!'}
      />
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Название Оффера</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('name', {required: true})}
        />
        {errors.name?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Выберите рекламодателя</span>
        </label>
        <Controller
          name='advertiser_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <SelectorWithSearch
              options={advertisersList}
              value={advertisersList.find((item) => item.value === value)}
              setValue={(e) => onChange(e.value)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '45px',
                }),
              }}
            />
          )}
        />
        {errors.advertiser_id?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className={`required`}>Выберите бренд</span>
        </label>
        <Controller
          name='brand_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <SelectorWithSearch
              options={brandsList}
              value={brandsList.find((item) => item.value === value)}
              setValue={(e) => onChange(e.value)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '45px',
                }),
              }}
            />
          )}
        />
        {errors.brand_id?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className={`required`}>Выберите страну</span>
        </label>
        <Controller
          name='country_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <SelectorWithCounties
              options={countries}
              value={countries.find((item) => item.value === value)}
              setValue={(e) => onChange(e.value)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '45px',
                }),
              }}
            />
          )}
        />
        {errors.country_id?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span
          // className='required'
          >
            Offer ID Рекламодателя
          </span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          maxLength={20}
          {...register('advertise_offer_id', {required: false})}
        />
        {errors.advertise_offer_id?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='d-flex flex-stack justify-content-end pt-10'>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Далее
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>
      </div>
    </form>
  );
};
const Finances = ({setData, data, setPage}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      bid: '0',
      baseline: '0',
      count_deposits: '1',
    },
  });

  const [bidValidate, setBidValidate] = useState(false);
  const [countDepositsValidate, setCountDepositsValidate] = useState(false);

  useEffect(() => {
    !!data?.bid && setValue('bid', `${data?.bid}`);
    !!data?.baseline && setValue('baseline', `${data?.baseline}`);
    !!data?.count_deposits && setValue('count_deposits', `${data?.count_deposits}`);
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (
        (name === 'bid' || name === 'baseline' || name === 'count_deposits') &&
        type === 'change'
      ) {
        setValue(name, value[name].replace(/\D/g, ''));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (params) => {
    let errors = false;
    if (!Number(params.count_deposits)) {
      setCountDepositsValidate(true);
      errors = true;
    }

    if (!Number(params.bid)) {
      setBidValidate(true);
      errors = true;
    }

    if (errors) return;

    setData({
      ...data,
      ...params,
      bid: Number(params.bid),
      count_deposits: Number(params.count_deposits),
      baseline: Number(params.baseline),
    });
    setPage('settings');
  };

  const validateNonZeroBid = (value) => {
    if (parseFloat(value) === 0) {
      return setBidValidate(true);
    }
    return setBidValidate(false);
  };

  const validateNonZeroCountDeposits = (value) => {
    if (parseFloat(value) === 0) {
      return setCountDepositsValidate(true);
    }
    return setCountDepositsValidate(false);
  };

  const prevPage = () => {
    const params = getValues();
    setData({
      ...data,
      ...params,
      bid: params.bid,
      count_deposits: params.count_deposits,
      baseline: params.baseline,
    });
    setPage('common');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title
        title={'Финансы'}
        subTitle={
          'Данные настройки влияют на обработку лидов, вводите цифры без знаковвалют и других.'
        }
      />
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Ставка $</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('bid', {
            validate: (value) => validateNonZeroBid(value),
            required: true,
          })}
        />
        {errors.bid?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
        {bidValidate && <div className='text-danger mt-2'>Значение должно быть больше 0</div>}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Бейслайн $</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('baseline', {
            required: true,
          })}
        />
        {errors.baseline?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Кол-во депозитов</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('count_deposits', {
            validate: (value) => validateNonZeroCountDeposits(value),
            required: true,
          })}
        />
        {errors.count_deposits?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
        {countDepositsValidate && (
          <div className='text-danger mt-2'>Значение должно быть больше 0</div>
        )}
      </div>
      <div className='d-flex flex-stack justify-content-between pt-10'>
        <Button
          action={() => {
            prevPage();
          }}
          color='btn-light-primary'
        >
          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Назад
        </Button>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Далее
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>
      </div>
    </form>
  );
};

const Settings = observer(({setData, data, setPage, isUpdate}) => {
  const store = useStore();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    setValue('link', data?.link);
    setValue('registration_path', data?.registration_path);
    setValue('deposit_path', data?.deposit_path);
    setValue('play_path', data?.play_path);
    setValue('param_1', data?.param_1);
    setValue('param_2', data?.param_2);
    setValue('param_3', data?.param_3);
    setValue('param_4', data?.param_4);
  }, [data]);

  const onSubmit = async (params) => {
    console.log({...data, ...params});
    const formData = new FormData();

    for (let [key, value] of Object.entries({...data, ...params})) {
      if (typeof value === 'undefined') value = '';
      formData.append(`${key}`, value);
    }
    // for (var value of formData.values()) {
    //   console.log(value);
    // }
    let result;

    if (!isUpdate) {
      result = await store.offersAddOffer(formData);
    } else {
      result = await store.offersUpdateOffer(formData);
    }

    !!result &&
      toast.success(!isUpdate ? `Успешно сохранено!` : 'Успешно обновлено!', {}) &&
      navigate('/advertisers/offers');
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  const prevPage = () => {
    const params = getValues();
    setData({
      ...data,
      ...params,
    });
    setPage('finances');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title title={'Настройки'} subTitle={'Данные настройки влияют на обработку лидов.'} />
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Ссылка Оффера</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          placeholder='example.com'
          {...register('link', {
            required: true,
          })}
        />
        {errors.link?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Путь регистрации</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('registration_path')}
        />
        <div className='text-danger mt-2'></div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Путь депозит</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('deposit_path')}
        />
        <div className='text-danger mt-2'></div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Путь играть</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('play_path')}
        />
        <div className='text-danger mt-2'></div>
      </div>
      {[0, 1, 2, 3].map((el, i) => {
        return (
          <div className='fv-row mb-10' key={el}>
            <label className='form-label'>
              <span className=''>Параметр {i + 1}</span>
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              {...register(`param_${i + 1}`)}
            />
            <div className='text-danger mt-2'></div>
          </div>
        );
      })}
      <div className='d-flex flex-stack justify-content-between pt-10'>
        <Button
          action={() => {
            prevPage();
          }}
          color='btn-light-primary'
        >
          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Назад
        </Button>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Сохранить
          </button>
        </div>
      </div>
    </form>
  );
});

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>
    </div>
  );
};

export default observer(AddOfferWrapper);
