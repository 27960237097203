/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import Header from './Header';
import Footer from './Footer';
import List from './List';
import PageWrapper from '../../../../../modules/PageWrapper';
import {findKPILtc} from '../../../../../functions/findKPI';
import ButtonExel from '../../../../../modules/ButtonExel';

const TOPLTCWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});
  const [subtype, setSubtype] = useState(undefined);

  const getFileName = () => {
    if(subtype === 'facebook'){
      return 'fb_rating_lts_managers';
    }
    if(subtype === 'influence'){
      return 'inf_rating_lts_managers';
    }
    return 'rating_lts_managers';
  }

  return (
    <PageWrapper
      firstTimeConfig='week_sales_periods'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'TOP LTC менеджера', path: '/sales/ltc/top/managers', isActive: true},
      ]}
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'place',
              'id',
              'first_name',
              'last_name',
              'sales_team_id',
              'teamleader_id',
              'teamleader_first_name',
              'teamleader_last_name',
              'count_appeals',
              'count_qualifications',
              'payout',
              'forecast_appeals',
              'ltc',
              'is_ban',
              'ban_date',
              'is_verified',
              'verify_date',
              'created_at',
              'wallet_created_at',
              'wallet_currency',
              'wallet_details',
              'wallet_id',
              'wallet_type'
            ]}
            columnFormats={{forecast_pay: '$#,##0.00'}}
            fileName={getFileName()}
            time={time}
          />
        ) : (
          <></>
        )
      }
      title={'TOP LTC Менеджеров'}
    >
      <TOPLTC setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} subtype={subtype} setSubtype={setSubtype} />
    </PageWrapper>
  );
};

const TOPLTC = observer(
  ({isVerify, timeConfig, setPreloader, setObjForExel, setTimeForExel, setRoles, subtype, setSubtype}) => {
    const store = useStore();
    const [data, setData] = useState([]);
    const [footerData, setFooterData] = useState([]);

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && timeConfig.time_config && getData();
    }, [isVerify, timeConfig, subtype]);

    const getData = async () => {
      const [result, getTeams, LTCList] = await Promise.all([
        store.salesLtcManagersGetTop(
          (timeConfig =
            timeConfig.time_config === 'week_sales_periods'
              ? {
                  time_config: '',
                  time_start: '',
                  time_end: '',
                }
              : timeConfig),
          subtype
        ),
        store.salesTeamsGetList('ltc'),
        store.salesGetKpiLtcList(),
      ]);

      const newList = result.data
        .map((item) => {
          const findTeam = getTeams.find((el) => el.id === item.sales_team_id);

          return {
            ...item,
            team: findTeam,
            full_name: `${item.first_name} ${item.last_name}`,
            status: findKPILtc(LTCList, item?.count_appeals, item?.ltc),
            place: item?.place === 0 ? 99999 : item?.place,
          };
        })
        .sort((a, b) => a?.place - b?.place);

      const dataForExel = newList.map((item) => {
        return {
          ...item,
          teamleader_id: item?.team?.teamleader?.id,
          teamleader_first_name: item?.team?.teamleader?.first_name,
          teamleader_last_name: item?.team?.teamleader?.last_name,
          payout: item?.forecast_pay,
          ban_date: !!item?.ban_date ? item?.ban_date : '',
          verify_date: !!item?.verify_date ? item?.verify_date : '',
          is_verified: `${item.is_verified}`,
          is_ban: `${item.is_ban}`,
          created_at: moment.tz(item?.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm '),
          wallet_created_at: moment.tz(item?.wallet_created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm ')
        };
      });
      setTimeForExel(result.time);
      setObjForExel(dataForExel);
      setData(newList);

      const newFooterData = newList.filter((item) => item.place !== 99999);
      setFooterData(newFooterData);
      setPreloader(false);
    };

    return (
      <>
        <Header top_1={data[0]} top_2={data[1]} top_3={data[2]} />
        <List data={data} withoutOutsiders={footerData} setSubtype={setSubtype} subtype={subtype} />
        <Footer
          data={footerData.slice(footerData.length - 3, footerData.length)}
          length={footerData.length - 2}
        />
      </>
    );
  }
);

export default TOPLTCWrapper;
