import {api} from './api';

const pwaAPI = {
  async resourcePWAgetApps(token, timeConfig) {
    try {
      const response = await api.get(
        `PWA/getApps?token=${token}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAgetAppsList(token, type = '') {
    try {
      const response = await api.get(
        `PWA/getAppsList?token=${token}${type ? '&type=' + type : ''}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getServicesList(token, type, isActive) {
    try {
      const response = await api.get(
        `Apps/getServicesList?token=${token}&type=${type ?? ''}&is_active=${isActive ?? ''}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAgetApp(token, timeConfig, id) {
    try {
      const response = await api.get(
        `PWA/getApp?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async resourcePWAgetAppNew(token, timeConfig, id) {
    try {
      const response = await api.get(
        `PWA/getAppNew?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAgetAppConstructor(token, id) {
    try {
      const response = await api.get(`PWA/getAppConstructor?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAmainStats(token, timeConfig, id) {
    try {
      const response = await api.get(
        `PWA/mainStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAgetAppPush(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (value === '') continue;
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`PWA/getAppPush${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWADeleteAppPush(data) {
    try {
      const response = await api.post(`PWA/deleteAppPush`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async deleteService(data) {
    try {
      const response = await api.post(`Apps/deleteService`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAaddAppPush(data) {
    try {
      const response = await api.post(`PWA/addAppPush`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async addService(data) {
    try {
      const response = await api.post(`Apps/addService`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAimportAppPush(data) {
    try {
      const response = await api.post(`PWA/importAppPush`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAupdateAppPush(data) {
    try {
      const response = await api.post(`PWA/updateAppPush`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updateService(data) {
    try {
      const response = await api.post(`Apps/updateService`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAPushGetMainStats(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`PWA/Push/getMainStats${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAPushGetActivity(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`PWA/Push/getActivity${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourcePWAGetAppPushActivity(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`PWA/getAppPushActivity${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAGetBayers(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`PWA/getBayers${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updatePWA(data) {
    try {
      const response = await api.post(`PWA/updateApp`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  //constructor///////////////////////////////////////////////

  async pwaGetTamplatesList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`/templates/list${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async appsGetServicesList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`/Apps/getServicesList${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async PWAgetStatistics(token, str, signal) {
    try {
      const response = await api.get(`PWA/getStatistics?token=${token}&${str}`, {
        signal,
      });

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getService(token, id) {
    try {
      const response = await api.get(`Apps/getService?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default pwaAPI;
