/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import Card from '../../../../shared/Card';
import {Button} from '../../../../shared/Buttons';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import {KTIcon} from '../../../../../_metronic/helpers';
import {toast} from 'react-toastify';
import Preloader from '../../../../modules/Preloader';
import PaginationBe from '../../../../shared/PaginationBe';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {useForm} from 'react-hook-form';

const perPage = 10;

const Landings = ({offerId, offerLink, landings}) => {
  const store = useStore();
  const searchDebounceRef = useRef(null);
  const [localPreloader, setLocalPreloader] = useState(false);

  const [newLandings, setNewLandings] = useState([]);

  const [queryText, setQueryText] = useState('');
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setNewLandings(landings?.rows);
    setTotal(landings?.total);
  }, [landings]);

  const onPaginationChange = async (page) => {
    setLocalPreloader(true);
    const landings = await store.offersGetLandings(
      `&offer_id=${offerId}&perPage=${perPage}&page=${page}&q=${queryText}`,
      false
    );
    setNewLandings(landings?.rows);
    setLocalPreloader(false);
  };

  const onSearch = (e) => {
    const query = e.target.value;

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    setQueryText(query);
    searchDebounceRef.current = setTimeout(async () => {
      setLocalPreloader(true);
      const landings = await store.offersGetLandings(
        `&offer_id=${offerId}&perPage=${perPage}&page=1&q=${query}`,
        false
      );
      setTotal(landings?.total);
      setNewLandings(landings?.rows);
      setLocalPreloader(false);
    }, 1000);
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <Card className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
          <div className='text-end ms-0 ms-sm-0 mb-5 mb-sm-5 mb-md-0 position-relative'>
            <i
              className='ki-duotone ki-magnifier fs-3 position-absolute'
              style={{left: '15px', top: '13px'}}
            >
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-13'
              placeholder='Поиск'
              value={queryText}
              onChange={onSearch}
            />
          </div>
          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'></div>

          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'></div>
        </div>

        <div className='card-toolbar'>
          <AddLanding
            offerId={offerId}
            setNewLandings={setNewLandings}
            setTotal={setTotal}
            setLocalPreloader={setLocalPreloader}
          />
        </div>
      </div>

      <div className='card-body py-4' style={{position: 'relative'}}>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-100' style={{width: 'fit-content'}}>
                    Название
                  </th>
                  <th className='min-w-100'>Ссылка</th>
                  <th className='min-w-100'></th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {newLandings?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className='' style={{whiteSpace: 'nowrap'}}>
                        #{item.id} {item.name}
                      </td>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex align-items-center border border-end-0 border-2 rounded-1'
                            style={{
                              padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                              maxWidth: '300px',
                            }}
                          >
                            <span
                              className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                minHeight: '20px',
                              }}
                              id=''
                            >
                              {item?.link}
                            </span>
                          </div>
                          <div className='d-flex flex-end p-0'>
                            <Button
                              className=''
                              style={{borderRadius: '0px 6px 6px 0px'}}
                              color='btn-dark'
                              action={() => copyText(item?.link)}
                            >
                              <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                            </Button>
                          </div>
                        </div>
                      </td>
                      <td className=''>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <span
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => window.open(`${item?.link}`, '_blank')}
                          >
                            <i className='bi bi-link-45deg fs-1'></i>
                          </span>
                          <AddLanding
                            landingData={item}
                            offerId={offerId}
                            setNewLandings={setNewLandings}
                            setTotal={setTotal}
                            setLocalPreloader={setLocalPreloader}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {localPreloader && <Preloader style={{border: '0'}} />}
          {newLandings?.length > 0 && (
            <PaginationBe
              arrLength={total}
              limit={perPage}
              page={page}
              setPage={setPage}
              action={(currentPage) => {
                onPaginationChange(currentPage);
              }}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const AddLanding = observer(
  ({offerId, landingData = null, setNewLandings, setTotal, setLocalPreloader}) => {
    const {
      control,
      register,
      setValue,
      formState: {errors},
      handleSubmit,
    } = useForm();
    const store = useStore();
    const [showCreateAppModal, setShowCreateAppModal] = useState(false);

    useEffect(() => {
      if (!landingData) return;
      setValue('name', landingData?.name);
      setValue('link', landingData?.link);
    }, [landingData]);

    const onSubmit = async (params) => {
      setLocalPreloader(true);
      let result;
      if (!landingData) {
        result = await store.offersAddOfferLanding({...params, offer_id: offerId});
      } else {
        result = await store.offersUpdateOfferLanding({
          ...params,
          offer_id: offerId,
          id: landingData.id,
        });
      }

      if (!!result) {
        toast.success(!landingData ? `Успешно сохранено!` : 'Успешно обновлено!', {});
        const landings = await store.offersGetLandings(
          `&offer_id=${offerId}&perPage=${perPage}&page=1`,
          false
        );
        setNewLandings(landings.rows);
        setTotal(landings.total);
        setShowCreateAppModal(false);
      } else {
        toast.error(`Произошла ошибка сохранения!`, {
          theme: 'colored',
        });
      }
      setLocalPreloader(false);
    };

    return (
      <>
        {!landingData && (
          <Button action={() => setShowCreateAppModal(true)}>
            <i className='ki-duotone ki-plus fs-2'></i>Добавить
          </Button>
        )}
        {!!landingData && (
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => setShowCreateAppModal(true)}
          >
            <i className='ki-duotone ki-pencil fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
        )}
        <CreateAppModal
          customWidth='mw-650px'
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
          title={'Добавить лендинг'}
        >
          <form
            id='kt_modal_add_permission_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Название</span>
              </label>

              <input
                className='form-control form-control-solid'
                {...register('name', {required: true})}
              />

              {errors.name?.type === 'required' && (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  Поле обязательно для заполнения
                </div>
              )}
            </div>
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Ссылка</span>
              </label>

              <input
                className='form-control form-control-solid'
                {...register('link', {
                  required: true,
                  validate: (value) => value.includes('{lead_id}'),
                })}
              />

              {errors.link?.type === 'required' && (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  Поле обязательно для заполнения
                </div>
              )}
              {errors.link?.type === 'validate' && (
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
                  Ссылка должна содержать макрос {`{lead_id}`}
                </div>
              )}
            </div>

            <div className='text-end pt-15'>
              <button type='submit' className='btn btn-primary'>
                <span className='indicator-label'>Сохранить</span>
              </button>
            </div>
          </form>
        </CreateAppModal>
      </>
    );
  }
);

export default observer(Landings);
