/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import InputSearch from '../../../shared/InputSearch';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import AddLandings from './AddLandings';
import PageWrapper from '../../../modules/PageWrapper';

const LandingsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Лендинги', path: '/resources/landings', isActive: true},
      ]}
      title={'Лендинги'}
    >
      <Landings />
    </PageWrapper>
  );
};

const Landings = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [landings, setLandings] = useState([]);
  const [searchLandings, setSearchLandings] = useState([]);

  useEffect(() => {
    isVerify && getLandings();
  }, [isVerify]);

  const getLandings = async () => {
    const result = await store.getLandings();
    const domainsList = await store.getDomains();

    const newLandingsList = [];
    for (let i = 0; i < result.length; i++) {
      let domain = domainsList.find((domain) => domain.id === result[i].domain_id);

      newLandingsList.push({
        ...result[i],
        domain,
        domain_name: domain.domain,
      });
    }

    setSearchLandings([...newLandingsList]);
    setLandings([...newLandingsList]);
    setPreloader(false);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <InputSearch
              title={'Поиск...'}
              array={landings}
              newArray={setSearchLandings}
              searchParameters={['domain_name', 'id', 'name']}
            />
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <ButtonAdd getLandings={getLandings} />
            </div>
          </div>
        </div>
        <Table landings={landings} getLandings={getLandings} searchLandings={searchLandings} />
      </Card>
    </>
  );
});

const Table = ({landings, getLandings, searchLandings}) => {
  // const [portionBots, setPortionBots] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newBots = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (landings.length > i) {
  //       newBots.push(landings[i]);
  //     }
  //   }
  //   setPortionBots(newBots);
  // }, [offset, landings]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>лендинг</th>
                <th className='min-w-125px'>домен</th>
                <th className='min-w-125px'>white page</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchLandings.map((el, i) => {
                return <Landing key={el.id} data={el} getLandings={getLandings} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {landings.length > limit && (
        <Pagination arrLength={landings.length} limit={limit} offset={offset} setOffset={setOffset} />
      )} */}
    </div>
  );
};

const Landing = ({data, getLandings}) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>{data.domain.domain}</td>
      <td>
        <div className='badge badge-light fw-bold'>{data.white_path}</div>
      </td>
      <td>{moment.tz(data.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <i className='ki-duotone ki-switch fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>

          <ButtonUpdate getLandings={getLandings} updateData={data} />

          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
            <i className='ki-duotone ki-trash fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
              <span className='path4'></span>
              <span className='path5'></span>
            </i>
          </span>
        </div>
      </td>
    </tr>
  );
};

export const ButtonAdd = ({getLandings}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление домена'}
      >
        <AddLandings
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getLandings={getLandings}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({getLandings, updateData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование домена'}
      >
        <AddLandings
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getLandings={getLandings}
          isUpdate={true}
          updateData={updateData}
        />
      </CreateAppModal>
    </>
  );
};

export default LandingsWrapper;
