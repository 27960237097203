/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
// import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {PageTitle} from '../../../../_metronic/layout/core';
import ModeToolbar from '../../../modules/ModeToolbar';
// import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import EditRole, {Form} from './EditRole';
import Card from '../../../shared/Card';
import RoleCard from './RoleCard';
import PageWrapper from '../../../modules/PageWrapper';

const RolesWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Список ролей', path: '/users/roles', isActive: true},
      ]}
      title={'Роли'}
    >
      <RolesList />
    </PageWrapper>
  );
};

const RolesList = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [getRoles, getDepartments] = await Promise.all([
      store.usersRolesGetList(),
      store.departamentsGetList(),
    ]);

    const newRoles = getRoles
      .map((el) => {
        const department = getDepartments.find((item) => item.id === el.departament_id);

        return {...el, department};
      })
      .sort((a, b) => a.level - b.level);

    setCards(newRoles);
    setPreloader(false);
  };

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'PanelHunt', path: ''},
          {isSeparator: true},
          {title: 'Список ролей', path: '/users/roles', isActive: true},
        ]}
      >
        {'Роли'}
      </PageTitle>
      <ModeToolbar type={null} />
      <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9'>
        {cards.map((el) => {
          return (
            <div className='col-md-4' key={el.id}>
              <RoleCard el={el} customClass='h-md-100' />
            </div>
          );
        })}

        {store.roles.includes(13) ||
        store.roles.includes(26) ||
        store.roles.includes(32) ||
        store.roles.includes(39) ? (
          <></>
        ) : (
          <div className='col-md-4'>
            <Card className='card h-md-100 cursor-pointer border-hover-primary'>
              <div className='card-body d-flex flex-center'>
                <button
                  type='button'
                  className='btn btn-clear d-flex flex-column flex-center '
                  onClick={() => setShowCreateAppModal(true)}
                >
                  <img
                    src='/media/illustrations/sketchy-1/4.png'
                    alt=''
                    className='mw-100 mh-150px mb-7'
                  />

                  <div className='fw-bold fs-3 text-gray-600 text-hover-primary'>Создать роль</div>
                </button>

                <CreateAppModal
                  customWidth='mw-750px'
                  show={showCreateAppModal}
                  handleClose={() => setShowCreateAppModal(false)}
                  title={'Add a Role'}
                >
                  <Form />
                </CreateAppModal>
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
});

export default RolesWrapper;
