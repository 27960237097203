import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import clsx from 'clsx';
import {useLayout} from '../../core';
import {DrawerComponent} from '../../../assets/ts/components';
import {WithChildren} from '../../../helpers';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../partials';

interface IStore {
  fullScreen: boolean;
  // інші властивості store
}

const Content = observer(({children}: WithChildren) => {
  const store = useStore() as unknown as IStore;
  const theme = useThemeMode();
  const {config, classes} = useLayout();
  const location = useLocation();

  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  const appContentContainer = config.app?.content?.container;
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid pt-0',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
      style={
        store.fullScreen
          ? {
              width: '100vw',
              height: '100vw',
              position: 'fixed',
              left: '0',
              top: '0',
              zIndex: '99999',
              backgroundColor: themeMode === 'dark' ? '#1B1C22' : '#F9F9F9',
            }
          : {}
      }
    >
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          className={clsx('app-container container-xxl', classes.contentContainer.join(' '), {
            'container-xxl': appContentContainer === 'fixed',
            'container-fluid': appContentContainer === 'fluid',
          })}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
});

export {Content};
