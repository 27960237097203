/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';

import PageWrapper from '../../../modules/PageWrapper';
import {PostBackTable} from './PostBackTable';
import {ColumnsFilter} from '../conversions/ColumnsFilter';
import PaginationBe from '../../../shared/PaginationBe';
import {useStore} from '../../../../store/store';
import {Filters} from './Filters';
import {
  postBackColumns,
  defaultPostBackActiveColumns,
  filtersPostBackGroup,
} from '../conversions/columns';

import css from '../conversions/Conversions.module.scss';
import clsx from 'clsx';

// paginationOptions
const paginationOptions = [
  {label: 5, value: 5},
  {label: 10, value: 10},
  {label: 15, value: 15},
  {label: 20, value: 20},
  {label: 25, value: 25},
  {label: 50, value: 50},
];

const PostBackWrapper = () => {
  const [prevTimeConfig, setPrevTimeConfig] = useState(null);
  const [firstRenderWrapper, setFirstRenderWrapper] = useState(false);

  useEffect(() => {
    const localStorageTimeConfig = localStorage.getItem('TrafficsPostbackQuery');
    const obj = {};
    const params = new URLSearchParams(localStorageTimeConfig);

    for (const [key, value] of params) {
      if (key === 'time_config') {
        obj.time_config = value;
      } else if (key === 'time_start') {
        obj.time_start = !!value ? +value * 1000 : '';
      } else if (key === 'time_end') {
        obj.time_end = !!value ? +value * 1000 : '';
      }
    }

    if (!obj.time_config) return setFirstRenderWrapper(true);
    setPrevTimeConfig(obj);
    setFirstRenderWrapper(true);
  }, []);

  return (
    <PageWrapper
      firstTimeConfig='7day'
      prevTimeConfig={prevTimeConfig}
      title={'Постбеки'}
      breadcrumbs={[
        {title: 'Трекер'},
        {isSeparator: true},
        {title: 'Постбеки', path: '/traffic/postback', isActive: true},
      ]}
    >
      <PostBack firstRenderWrapper={firstRenderWrapper} />
    </PageWrapper>
  );
};

const PostBack = observer(
  ({isVerify, setPreloader, timeConfig, firstRenderWrapper, CalendarForFullScreen}) => {
    const store = useStore();
    const {search} = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [themeMode, setThemeMode] = useState('');
    const [activePostbackColumns, setActivePostbackColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [countries, setCountries] = useState([]);

    useEffect(() => {
      const storageSearchKey = 'TrafficsPostBacksActiveColumns';

      let storageActiveColumns = JSON.parse(localStorage.getItem(storageSearchKey));
      if (storageActiveColumns) {
        setActivePostbackColumns(activePostbackColumns);
      } else {
        setActivePostbackColumns(defaultPostBackActiveColumns);
        localStorage.setItem(storageSearchKey, JSON.stringify(defaultPostBackActiveColumns));
      }
    }, []);

    const updateQueryString = (str, key, value) => {
      const regex = new RegExp(`[?&]${key}=([^&]*)`);
      const match = str.match(regex);

      if (match) {
        if (match[1] === value) {
          return str;
        }
        return str.replace(regex, `${match[0][0]}${key}=${value}`);
      }
      return `${str}${str.includes('?') ? '&' : '?'}${key}=${value}`;
    };

    const queryToObject = (queryString) => {
      const params = new URLSearchParams(
        queryString.startsWith('?') ? queryString.slice(1) : queryString
      );
      const result = {};

      params.forEach((value, key) => {
        result[key] = value.includes(',') ? value.split(',') : value;
      });

      return result;
    };

    const objectToQuery = (params) => {
      const query = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          query.set(key, value.join(',')); // Якщо значення — масив, об'єднуємо його через кому
        } else if (value !== undefined && value !== null) {
          query.set(key, value); // Якщо значення є, додаємо його
        }
      });

      return `?${query.toString()}`;
    };

    const getData = async (str, type) => {
      let newString = str;
      newString = updateQueryString(newString, 'time_config', timeConfig.time_config);
      newString = updateQueryString(newString, 'time_start', timeConfig.time_start);
      newString = updateQueryString(newString, 'time_end', timeConfig.time_end);

      if (str.search('group') === -1) {
        newString = updateQueryString(newString, 'advertiser_id', '');
      }

      const storageSearchKey = 'TrafficsPostbackQuery';
      localStorage.setItem(storageSearchKey, newString);
      navigate(newString);

      const fetchData = async (query) => {
        setIsLoading(true);
        let req = await store.dashboardConversionsGetPostBack(`${query.replace(/\?/g, '')}`);

        const data = req.rows.map((item) => {
          const country = countries.find((c) => c.id === item.country_id);
          return {...item, flag: country?.flag};
        });

        setData(data);
        setTotal(req.total);
        setPerPage(req.perPage);
        setPage(req.page);
        setIsLoading(false);
      };

      const obj = queryToObject(newString);
      if (!obj?.page) {
        const newUrl = updateQueryString(newString, 'page', page);
        localStorage.setItem(storageSearchKey, newUrl);
        navigate(newUrl);
      }
      if (!obj?.per_page) {
        const newUrl = updateQueryString(newString, 'per_page', perPage);
        localStorage.setItem(storageSearchKey, newUrl);
        navigate(newUrl);
      }
      if (typeof obj.group === 'string') {
        await fetchData(newString);
      } else if (Array.isArray(obj.group)) {
        obj.group = `${obj.group[0]}`;
        const query = objectToQuery(obj);
        await fetchData(query);
      } else {
        await fetchData(newString);
      }
    };

    const changePage = (page) => {
      const storeLink = 'TrafficsPostbackQuery';

      const newUrl = updateQueryString(search, 'page', page);
      navigate(newUrl);
      localStorage.setItem(storeLink, newUrl);
      getData(window.location.search);
    };

    const changePerPage = (perPage) => {
      const storeLink = 'TrafficsPostbackQuery';

      let newUrl = updateQueryString(search, 'per_page', perPage);
      newUrl = updateQueryString(newUrl, 'page', 1);
      navigate(newUrl);
      localStorage.setItem(storeLink, newUrl);
      getData(window.location.search);
    };

    useEffect(() => {
      if (isVerify && firstRenderWrapper && timeConfig.time_config) {
        const storageSearchKey = 'TrafficsPostbackQuery';
        getData(localStorage.getItem(storageSearchKey) || search, 'firstData').finally(() =>
          setPreloader(false)
        );
      }
    }, [isVerify, firstRenderWrapper, timeConfig.time_config]);

    useEffect(() => {
      const containersXL = document.querySelectorAll('.container-xxl');
      containersXL.forEach((el) => (el.style.maxWidth = '100%'));
      (async () => {
        const countriesReq = await store.getCountries();
        setCountries(countriesReq);
      })();

      return () => containersXL.forEach((el) => (el.style.maxWidth = ''));
    }, []);

    return (
      <>
        <div className={css.container} style={{gap: 10, display: 'flex', flexDirection: 'column'}}>
          <Filters
            getTable={getData}
            search={search}
            perPage={perPage}
            isVerify={isVerify}
            setThemeMode={setThemeMode}
            filtersGroup={filtersPostBackGroup}
            linkStore='TrafficsPostbackQuery'
            CalendarForFullScreen={CalendarForFullScreen}
            actions={
              <div>
                <ColumnsFilter
                  data={postBackColumns}
                  activeColumns={activePostbackColumns}
                  setActiveColumns={setActivePostbackColumns}
                  linkStore={'TrafficsPostBacksActiveColumns'}
                />
              </div>
            }
          />
          {isLoading ? (
            <div className='progress' style={{height: 2}}>
              <div
                className={clsx(
                  'progress-bar progress-bar-striped progress-bar-animated h-1',
                  css.progressBar
                )}
                role='progressbar'
                style={{width: '100%'}}
              ></div>
            </div>
          ) : (
            <PostBackTable activeColumns={activePostbackColumns} data={data} />
          )}
        </div>

        {!isLoading && (
          <PaginationBe
            arrLength={total}
            page={page}
            limit={perPage}
            perPage={perPage}
            changePerPage={changePerPage}
            setPage={changePage}
            menuPlacement='top'
            options={paginationOptions}
          />
        )}
      </>
    );
  }
);

export default PostBackWrapper;
