/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import SalesTeams from '../../../dashboards/salesDepartment/SalesLtsTeam';
import PageWrapper from '../../../../modules/PageWrapper';
import ButtonExel from '../../../../modules/ButtonExel';

const TeamsWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});

  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Команды LTC', path: '/sales/ltc/teams', isActive: true},
      ]}
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'id',
              'team_created_at',
              'teamleader_id',
              'teamleader_first_name',
              'teamleader_last_name',
              'count_appeal',
              'count_qualifications',
              'count_users',
              'ltc',
            ]}
            fileName={'lts_teams'}
            time={time}
          />
        ) : (
          <></>
        )
      }
      title={'Команды LTC'}
    >
      <Teams setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} />
    </PageWrapper>
  );
};

const Teams = observer(
  ({timeConfig, isVerify, setPreloader, setObjForExel, setTimeForExel, setRoles}) => {
    const store = useStore();

    const [ltcList, setLtcList] = useState([]);
    const [ltcTeams, setLtcTeams] = useState({});

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const ltcList = await store.financeCategoryGetLtcList();
      setLtcList(ltcList);
      getTeams();
      setPreloader(false);
    };

    const getTeams = async () => {
      const tc = timeConfig;

      const getLtcTeams = await store.salesTeamsLtcGetTeams(tc);

      const dataForExel = getLtcTeams?.data?.teams?.map((item) => {
        return {
          ...item,
          ...item?.statistics,
          team_created_at: moment.tz(item?.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm '),
          teamleader_id: item?.teamleader?.id,
          teamleader_first_name: item?.teamleader?.first_name,
          teamleader_last_name: item?.teamleader?.last_name,
        };
      });
      setTimeForExel(getLtcTeams.time);
      setObjForExel(dataForExel);

      setLtcTeams(getLtcTeams.data);
    };

    return (
      <>
        <SalesTeams
          title={'Команды LTC'}
          subTitle={`Кол-во менеджеров: `}
          headers={['Команда', 'Сотрудники', 'Обращения', 'Квалификации', 'LTC']}
          arrayList={ltcList}
          teams={ltcTeams}
          getTeams={getTeams}
        />
      </>
    );
  }
);

export default TeamsWrapper;
