/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../_metronic/partials';
import Pagination from '../../../shared/Pagination';
import {Button} from '../../../shared/Buttons';
import AddServers from './AddServers';
import InputSearch from '../../../shared/InputSearch';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';

const ServersWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Сервера', path: '/resources/servers', isActive: true},
      ]}
      title={'Сервера'}
    >
      <Servers />
    </PageWrapper>
  );
};

const Servers = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [servers, setServers] = useState([]);
  const [searchServers, setSearchServers] = useState([]);

  useEffect(() => {
    isVerify && getServers();
  }, [isVerify]);

  const getServers = async () => {
    const result = await store.getServers();

    setSearchServers([...result]);
    setServers([...result]);
    setPreloader(false);
  };

  const sortStatus = (status) => {
    const newServers = servers.filter((item) => item.status === status);

    setSearchServers(newServers);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
            <InputSearch
              title={'Поиск...'}
              array={servers}
              newArray={setSearchServers}
              searchParameters={['id', 'ip', 'name']}
            />
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Статус <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      sortStatus(true);
                    }}
                  >
                    Активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      sortStatus(false);
                    }}
                  >
                    Не активный
                  </span>
                </div>
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    data-kt-users-table-filter='delete_row'
                    onClick={() => {
                      setSearchServers(servers);
                    }}
                  >
                    Все статусы
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <ButtonAdd getServers={getServers} />
            </div>
          </div>
        </div>
        <Table servers={servers} getServers={getServers} searchServers={searchServers} />
      </Card>
    </>
  );
});

const Table = ({servers, getServers, searchServers}) => {
  // const [portionServers, setPortionServers] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newServers = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (servers.length > i) {
  //       newServers.push(servers[i]);
  //     }
  //   }
  //   setPortionServers(newServers);
  // }, [offset, servers]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Сервер</th>
                <th className='min-w-125px'>Название</th>
                <th className='min-w-125px'>Действителен до</th>
                <th className='min-w-125px'>Статус</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchServers.map((el, i) => {
                return <Server key={el.id} data={el} getServers={getServers} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {servers.length > limit && (
        <Pagination
          arrLength={servers.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )} */}
    </div>
  );
};

const Server = ({data, getServers}) => {
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <span className='text-gray-800 text-hover-primary mb-1' style={{cursor: 'pointer'}}>
            {data.ip}
          </span>
          <span>#{data.id}</span>
        </div>
      </td>
      <td>
        <div className=''>{data.name}</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>{data.valid}</div>
      </td>
      <td>
        <div className={`badge ${!data.status ? 'badge-light-danger' : 'badge-light-success'}`}>
          {!data.status ? 'Не активный' : 'Активный'}
        </div>
      </td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link to={`/resources/servers/${data.id}`}>
            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className='ki-duotone ki-switch fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </Link>
          <ButtonUpdate getServers={getServers} data={data} />
        </div>
      </td>
    </tr>
  );
};

export const ButtonAdd = ({getServers}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление сервера'}
      >
        <AddServers
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getServers={getServers}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({getServers, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование сервера'}
      >
        <AddServers
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getServers={getServers}
          isUpdate={true}
          data={data}
        />
      </CreateAppModal>
    </>
  );
};

export default observer(ServersWrapper);
