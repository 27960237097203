/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';
import {KTIcon} from '../../../../../_metronic/helpers';
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../../../../_metronic/partials';
import numeral from 'numeral';
import moment from 'moment';
import {formatNumber} from '../../../../functions/formatNumber';

const Stats = ({className, chartColor, chartHeight, data = {}}) => {
  const chartRef = useRef(null);
  const {mode} = useThemeMode();
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, data?.schedule));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data]);

  return (
    <div className={`card ${className} h-xl-100`}>
      <div className={`card-header border-0 bg-${chartColor} py-5`}>
        <h3 className='card-title fw-bold text-white'>Статистика</h3>
      </div>

      <div className='card-body p-0'>
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart card-rounded-bottom bg-${chartColor}`}
          style={{paddingBottom: '10px'}}
          // style={{transform: "translateY(-50px)"}}
        ></div>
        <div
          className='p-6'
          // style={{marginTop: '-50px'}}
        >
          <Item
            stat={`${formatNumber(data?.statistics?.revenue)}$`}
            title={'Выручка'}
            subTitle={'Кол-во выручки'}
            icon={
              <i className='ki-duotone ki-tag fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            }
          />
          <Item
            stat={formatNumber(data?.statistics?.leads?.count)}
            arrow={data?.statistics?.leads?.indicator}
            title={'Лиды'}
            subTitle={'Кол-во лидов'}
            icon={
              <i className='ki-duotone ki-fingerprint-scanning fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
                <span className='path5'></span>
              </i>
            }
          />
          <Item
            stat={formatNumber(data?.statistics?.registration?.count)}
            arrow={data?.statistics?.registration?.indicator}
            title={'Регистрации'}
            subTitle={'Кол-во регистраций'}
            icon={
              <i className='ki-duotone ki-user-edit fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            }
          />
          <Item
            stat={formatNumber(data?.statistics?.deposits?.count)}
            arrow={data?.statistics?.deposits?.indicator}
            title={'Депозиты'}
            subTitle={'Кол-во депозитов'}
            icon={
              <i className='ki-duotone ki-wallet fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
              </i>
            }
          />
          <Item
            stat={`${formatNumber(data?.statistics?.amountDeposits?.amount)}$`}
            arrow={data?.statistics?.amountDeposits?.indicator}
            title={'Сумма депозитов'}
            subTitle={'Сумма депозитов'}
            icon={
              <i className='ki-duotone ki-dollar fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            }
          />
          <Item
            stat={formatNumber(data?.statistics?.qualifications?.count)}
            arrow={data?.statistics?.qualifications?.indicator}
            title={'Квалификации'}
            subTitle={'Кол-во квалификаций'}
            icon={
              <i className='ki-duotone ki-user-tick fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            }
          />
          <Item
            stat={formatNumber(data?.statistics?.appeals?.count)}
            arrow={data?.statistics?.appeals?.indicator}
            title={'Обращения'}
            subTitle={'Кол-во обращений'}
            icon={
              <i className='ki-duotone ki-mouse-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            }
          />
          <Item
            stat={`${data?.statistics?.ltc?.count}%`}
            arrow={data?.statistics?.ltc?.indicator}
            title={'LTC'}
            subTitle={'Конверсия'}
            icon={
              <i className='ki-duotone ki-percentage fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            }
          />
        </div>
      </div>
    </div>
  );
};

const Item = ({stat, title, subTitle, icon, arrow}) => {
  return (
    <div className='d-flex align-items-center mb-5'>
      <div className='symbol symbol-45px w-40px me-5'>
        <span className='symbol-label bg-lighten'>{icon}</span>
      </div>

      <div className='d-flex align-items-center flex-wrap w-100'>
        <div className='mb-1 pe-3 flex-grow-1'>
          <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>{title}</span>
          <div className='text-gray-400 fw-semibold fs-7'>{subTitle}</div>
        </div>

        <div className='d-flex align-items-center'>
          <div className='fw-bold fs-5 text-gray-800 pe-1'>{stat}</div>
          {arrow === 'up' && <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />}
          {arrow === 'down' && <KTIcon iconName='arrow-down' className='fs-5 text-danger ms-1' />}
        </div>
      </div>
    </div>
  );
};

const chartOptions = (chartHeight, data = []) => {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');

  function findLargestNumber(array) {
    const newArr = array?.map((el) => {
      return el.count;
    });

    if (!Array.isArray(newArr) || newArr?.length === 0) {
      return undefined;
    }

    const numericArray = newArr.map(Number);
    const largestNumber = Math.max(...numericArray);
    return largestNumber + 10;
  }

  const counts_registrations = data?.map((el) => Number(el.count_registrations));
  const counts_qualifications = data?.map((el) => Number(el.count_qualifications));
  const month = data?.map((el) => {
    const str = moment.tz(el.month * 1000, 'Europe/Kyiv')
      .format('MMMM')
      .split('');
    str?.splice(0, 1, str[0].toUpperCase());

    return str.join('');
  });

  return {
    series: [
      {
        name: 'Регистрации',
        data: counts_registrations,
      },
      {
        name: 'Квалификации',
        data: counts_qualifications,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10px',
        borderRadius: 3,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: month,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      // max: findLargestNumber(counts_registrations),
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: ['solid', 'solid'],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['#ffffff', '#ffffff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  };
};

export default Stats;
