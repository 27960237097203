/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import BlockTitles from './templates/BlockTitles';
import BlockLine from './templates/BlockLine';
import {SelectorWithSearch} from '../../shared/Selectors';

const CopyForm = ({
  setStep,
  templatesNames,
  setDataNameForm,
  setPwaToCopy,
  setDataOffersFormBc,
  setDataOffersFormFt,
  checkedService,
  setCheckedService,
  checkedType,
  setCheckedType,
  setUpdateDataForServices,
}) => {
  const store = useStore();
  const [change, setChange] = useState(false);
  const [pwas, setPwas] = useState([]);
  const [pwa, setPwa] = useState(null);
  const [validation, setValidation] = useState(false);

  const [addOffers, setAddOffers] = useState(false);
  const [addPushes, setAddPushes] = useState(false);

  const [services, setServices] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const getPWAs = await store.resourcePWAgetApps({
      time_config: 'current_month',
      time_start: '',
      time_end: '',
    });
    const getServices = await store.appsGetServicesList('pwa');
    setPwas(
      getPWAs
        .filter((item) => !item.service_id)
        .map((item) => {
          return {value: item.id, label: `#${item?.id} ${item?.name}`};
        })
    );

    setServices([
      {label: 'PANELHUNT', value: 0},
      ...getServices.map((item) => {
        return {value: item.id, label: item.name};
      }),
    ]);
  };

  const nextStep = async () => {
    if (checkedService !== 0) return setStep(100);
    if (!pwa && !!change) return setValidation(true);

    if (!change) {
      setStep(1);
    } else {
      const getPwaData = await store.resourcePWAgetAppConstructor(+pwa);
      const template = templatesNames.find((item) => item.value === getPwaData?.template_id);

      if (!!getPwaData.service_id) {
        if (!!addOffers) {
          setUpdateDataForServices({
            data: JSON.parse(JSON.stringify(getPwaData)),
            offers: {
              country_offers: getPwaData?.country_offers,
              offer_landing_id: getPwaData?.offer_landing_id,
              split_enabled: getPwaData?.split_enabled,
              apps_split: getPwaData?.apps_split,
            },
          });
        } else {
          setUpdateDataForServices({
            data: JSON.parse(JSON.stringify(getPwaData)),
          });
        }

        setCheckedService(getPwaData.service_id);
        setCheckedType(getPwaData.type_config);
        setStep(100);
        setPwaToCopy({...getPwaData, addOffers, addPushes});
        return;
      }

      if (!!addOffers) {
        setDataNameForm({
          name: '',
          template: {value: template?.value, label: template?.label},
        });

        setDataOffersFormBc({
          country_offers: getPwaData?.country_offers,
          offer_landing_id: getPwaData?.offer_landing_id,
          split_enabled: getPwaData?.split_enabled,
          apps_split: getPwaData?.apps_split,
        });

        setDataOffersFormFt({
          country_offers: getPwaData?.country_offers,
          offer_landing_id: getPwaData?.offer_landing_id,
          split_enabled: getPwaData?.split_enabled,
          apps_split: getPwaData?.apps_split,
        });
      } else {
        setDataNameForm({
          template: {value: template?.value, label: template?.label},
        });
      }

      setPwaToCopy({...getPwaData, addOffers, addPushes});
      setStep(1);
    }
  };

  return (
    <>
      <div className={css.copyForm}>
        <BlockTitles
          title={'Создание / Дублирование'}
          description={
            'На этом этапе выберите, хотите ли вы дублировать уже существующее Приложение или создать новое Приложение.'
          }
        />
        <BlockLine />
        <div
          className={classNames(css.slideSelector, change && css._change)}
          onClick={() => setChange(!change)}
        >
          <span>Создать Приложение</span>
          <span>Дублировать Приложение</span>
        </div>
        {!change && (
          <>
            <label htmlFor='' style={{width: '100%', margin: '16px 0px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>
                Выберите тип приложения
              </span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                  }),
                }}
                placeholder=''
                options={[
                  {label: 'PWA', value: 'pwa'},
                  {label: 'Android', value: 'android'},
                  {label: 'iOS', value: 'ios'},
                ]}
                value={checkedType}
                setValue={async (e) => {
                  setCheckedType(e);
                  const getServices = await store.appsGetServicesList(e.value);
                  if (e.value === 'pwa') {
                    setServices([
                      {label: 'PWAHUNT', value: 0},
                      ...getServices.map((item) => {
                        return {value: item.id, label: item.name};
                      }),
                    ]);
                  } else {
                    setServices(
                      getServices.map((item) => {
                        return {value: item.id, label: item.name};
                      })
                    );
                  }
                }}
              />
            </label>
            <label htmlFor='' style={{width: '100%', margin: '16px 0px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>
                Выберите сервис приложения
              </span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                  }),
                }}
                placeholder=''
                options={services}
                value={services.find((item) => item.value === checkedService)}
                setValue={(e) => {
                  setCheckedService(e.value);
                }}
                actions={() => {}}
              />
            </label>
          </>
        )}
        {!!change && (
          <>
            <label htmlFor='' style={{width: '100%', margin: '16px 0px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>
                Выберите Приложение для дублирования
              </span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                    border: !!validation && '2px solid #F8285A !important',
                    boxShadow: !!validation && 'none',
                  }),
                }}
                placeholder=''
                options={pwas}
                value={pwas.find((item) => item.value === pwa)}
                setValue={(e) => {
                  setPwa(e.value);
                  setValidation(false);
                }}
                actions={() => {}}
              />
            </label>
            <div className={css.checkerContainer}>
              <div className={css.checkboxContainer}>
                <span style={{display: 'inline-block', marginBottom: '8px'}}>
                  Копировать Push уведомления
                </span>
                <label htmlFor='checkbox1' className={css.switch}>
                  <input
                    type='checkbox'
                    id='checkbox1'
                    checked={addPushes}
                    onChange={(e) => setAddPushes(e.target.checked)}
                  />
                </label>
              </div>
              <div className={css.checkboxContainer}>
                <span style={{display: 'inline-block', marginBottom: '8px'}}>Копировать оферы</span>
                <label htmlFor='checkbox2' className={css.switch}>
                  <input
                    type='checkbox'
                    id='checkbox2'
                    checked={addOffers}
                    onChange={(e) => setAddOffers(e.target.checked)}
                  />
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={css.next}>
        <button className={css.blueButton} style={{minWidth: '130px'}} onClick={nextStep}>
          Продолжить
        </button>
      </div>
    </>
  );
};

export default observer(CopyForm);
