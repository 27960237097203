/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, forwardRef} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import DatePicker from 'react-datepicker';
import {useForm, Controller} from 'react-hook-form';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {useThemeMode} from '../../../../../_metronic/partials';
import {toast} from 'react-toastify';
import Warning from '../../../../modules/Warning';
import {Selector} from '../../../../shared/Selectors';
import MaskedTextInput from 'react-text-mask';

const AddAccounts = ({
  setWarning,
  warning,
  setSave,
  save,
  show,
  getData,
  isUpdate,
  data,
  bayers,
  agents,
}) => {
  const store = useStore();
  const theme = useThemeMode();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  useEffect(() => {
    if (isUpdate) {
      setValue('id', data.id);
      setValue('account_name', data.account_name);
      setValue('bayer_id', data.bayer_id);
      setValue('active', data.active);
      data.facebook_agent_id && setValue('facebook_agent_id', data.facebook_agent_id);
    }

    if (store.roles.includes(6) || store.roles.includes(7)) {
      setValue('bayer_id', store.user.id);
    }
  }, []);

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      getData();
      toast.success(`${message}`, {});
    }
  };

  const onSubmit = async (data) => {
    let result;

    if (isUpdate) {
      result = await store.trafficsFacebookAccountUpdateAccount(data);
    } else {
      result = await store.trafficsFacebookAccountAddAccount(data);
    }

    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

    setSave(true);
    show(false);
  };

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      {!isUpdate && (
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>ID</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            {...register('account_id', {required: true})}
          />

          {errors?.account_id?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      )}
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Название</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('account_name', {required: true})}
        />

        {errors?.account_name?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='fw-semibold fs-6 mb-2'>Агент</label>

        <Controller
          name='facebook_agent_id'
          control={control}
          rules={{required: false}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={agents} value={value} onChange={onChange} />
          )}
        />
      </div>
      {store.roles.includes(6) || store.roles.includes(7) || isUpdate ? (
        <></>
      ) : (
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Байер</label>

          <Controller
            name='bayer_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector options={bayers} value={value} onChange={onChange} />
            )}
          />
          {errors?.bayer_id?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      )}
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='fw-semibold fs-6 mb-2'>Статус</label>

        <div className='form-check form-switch form-check-custom form-check-solid me-10'>
          <input
            className='form-check-input h-30px w-50px cursor-pointer'
            {...register('active', {required: false})}
            type='checkbox'
            id='flexSwitch30x50'
          />
        </div>
      </div>

      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={'Сохранить'}
        />
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

export default observer(AddAccounts);
