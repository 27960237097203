/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import css from './styles.module.scss';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../_metronic/partials';
import InputSearch from '../InputSearch';
import {TrekerButton} from '../../pages/traffic/influence/bloggers/source/Treker/TrekerButton';

const FilterWithSelectedOptions = ({
  placeholder = 'Поиск...',
  item,
  index = 0,
  options,
  selectedOptions,
  onChange,
  apply,
  del,
  isSearch = false,
  onChangeValue = (value) => {},
}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');
  const containerRef = useRef(null);

  const [checkedSelector, setCheckedSelector] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);

  const selected = (item) => {
    const newSelectedOptions = [...selectedOptions] || [];
    newSelectedOptions.push(item);
    onChange(newSelectedOptions);
  };

  useEffect(() => {
    setSearchOptions(options);
  }, [options]);

  const handleBlur = (event) => {
    // Перевіряємо, чи залишається фокус всередині контейнера
    if (containerRef.current && !containerRef.current.contains(event.relatedTarget)) {
      setCheckedSelector(false);
    }
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    if (store.focusedTableFilter === item.value) {
      containerRef.current.focus();
      setCheckedSelector(true);
      store.setFocusFilter('');
    }
  }, [store.focusedTableFilter, item]);

  return (
    <>
      <div
        className={css.container}
        style={{
          '--bg': mode === 'dark' ? 'rgba(27, 29, 35, 1)' : '#FFFFFF',
          '--borderColor': mode === 'dark' ? 'hsl(0, 0%, 20%)' : 'hsl(0, 0%, 80%)',
          '--itemHover': mode === 'dark' ? '#4d5863' : '#f4f6f9',
          '--buttonHover': mode === 'dark' ? 'rgba(258, 258, 258, 0.05)' : 'rgba(0, 0, 0, 0.05)',
        }}
        ref={containerRef}
        tabIndex={index}
        onBlur={handleBlur}
      >
        <label className={css.buttonOpen} htmlFor={`checkbox-${index}`}>
          <span className={css.buttonOpenTitle}>{item.label}</span>
          <input
            checked={checkedSelector}
            type='checkbox'
            name=''
            id={`checkbox-${index}`}
            onChange={(e) => setCheckedSelector(e.target.checked)}
          />
          <svg
            className={css.arrow}
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='7'
            fill='none'
            viewBox='0 0 12 7'
          >
            <path
              fill={mode === 'dark' ? 'hsl(0, 0.00%, 100.00%)' : 'hsl(0, 0.00%, 0.00%)'}
              d='M5.59 6.077a1.66 1.66 0 0 1-1.18-.487L0 1.178 1.178 0 5.59 4.41 10 0l1.178 1.178-4.41 4.411a1.66 1.66 0 0 1-1.179.488Z'
            />
          </svg>
          <span className={css.hoverEffect}></span>
        </label>
        <div className={css.dropdown}>
          <section className={css.listWrapper}>
            <div className={css.searchWrapper}>
              <InputSearch
                style={
                  mode === 'dark'
                    ? {border: '1px solid hsl(0, 0%, 20%)', height: '36px', fontSize: '13px'}
                    : {border: '1px solid hsl(0, 0%, 80%)', height: '36px', fontSize: '13dgsdpx'}
                }
                onChangeValue={onChangeValue}
                title={placeholder}
                array={options}
                newArray={setSearchOptions}
                searchParameters={['label']}
              />
            </div>
            <ul className={css.list}>
              {searchOptions
                ?.filter(
                  (opt) => !selectedOptions?.some((selected) => selected.value === opt.value)
                )
                ?.map((opt, i) => (
                  <React.Fragment key={opt.value}>
                    <li className={css.item} onClick={() => selected(opt)}>
                      {isSearch && 'Добавить '}
                      {opt.flag && <img src={`${opt?.flag}`} alt='' />}
                      <span>{opt.label}</span>
                    </li>
                  </React.Fragment>
                ))}
            </ul>
          </section>
          {!!selectedOptions && selectedOptions?.length > 0 && (
            <section className={css.selectedListWrapper}>
              <div className={css.action}>
                <TrekerButton
                  style={{
                    height: '36px',
                    width: '100%',
                    paddingTop: '0',
                    paddingBottom: '0',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  svg={
                    <svg
                      enableBackground='new 0 0 32 32'
                      id='Editable-line'
                      version='1.1'
                      viewBox='0 0 32 32'
                      xmlSpace='preserve'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='18'
                      height='18'
                    >
                      <path
                        d='  M3.241,7.646L13,19v9l6-4v-5l9.759-11.354C29.315,6.996,28.848,6,27.986,6H4.014C3.152,6,2.685,6.996,3.241,7.646z'
                        fill='none'
                        id='XMLID_6_'
                        stroke='#000000'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeMiterlimit='10'
                        strokeWidth='2'
                      />
                    </svg>
                  }
                  onClick={() => {
                    apply();
                    setCheckedSelector(false);
                  }}
                >
                  Применить фильтр
                </TrekerButton>
              </div>
              <ul className={css.selectedList}>
                {selectedOptions.map((item, i) => {
                  return (
                    <li className={css.item} key={item.value}>
                      {item.flag && <img src={`${item?.flag}`} alt='' />}
                      <span>{item.label}</span>
                      <span
                        className={css.close}
                        onClick={() => {
                          del(index, i);
                        }}
                      ></span>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(FilterWithSelectedOptions);
