/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import css from '../../../styles/CustomPicker.module.scss';
import classNames from 'classnames';
import {KTIcon} from '../../../_metronic/helpers';
import {useThemeMode} from '../../../_metronic/partials';
import {CreateAppModal} from '../../../_metronic/partials';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {Button} from '../../shared/Buttons';
import {observer} from 'mobx-react';
import {useStore} from '../../../store/store';
import {toast} from 'react-toastify';

const momentTZ = require('moment-timezone');

const Calendar = ({
  showCreateAppModal,
  setShowCreateAppModal,
  handleCustom,
  timeConfig,
  minDate,
  maxDate,
  limitDays = null,
}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [startTimeForRequest, setStartTimeForRequest] = useState('');
  const [endTimeForRequest, setEndTimeForRequest] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const getKyivOffset = () => {
    return (moment.tz('Europe/Kiev').utcOffset() / 60) * 60 * 60 * 1000;
  };

  const getTimezoneOffset = (date) => {
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    const hoursOffset = (Math.abs(timezoneOffsetInMinutes) / 60) * 60 * 60 * 1000;

    return {timezoneOffsetInMinutes, hoursOffset};
  };

  useEffect(() => {
    if (!timeConfig.time_start) return initTime(new Date());

    setStartDate(new Date(timeConfig.time_start).getTime());
    setEndDate(new Date(timeConfig.time_end).getTime());
    setStartTimeForRequest(timeConfig.sendStart);
    setEndTimeForRequest(timeConfig.sendEnd);
  }, [timeConfig.time_start, timeConfig.time_end]);

  const initTime = () => {
    const kievOffset = getKyivOffset();
    const {timezoneOffsetInMinutes, hoursOffset} = getTimezoneOffset(new Date());

    if (timezoneOffsetInMinutes > 0) {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setStartDate(currentTime);
      setStartTimeForRequest(currentTime - hoursOffset);
    } else if (timezoneOffsetInMinutes < 0) {
      if (kievOffset > hoursOffset) {
        const diff = kievOffset - hoursOffset;
        const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
        setStartDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else if (kievOffset < hoursOffset) {
        const diff = hoursOffset - kievOffset;
        const currentTime = new Date(new Date().getTime() - diff).setHours(0, 0, 0);
        setStartDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else {
        const currentTime = new Date().setHours(0, 0, 0);
        setStartDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      }
    } else {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setStartDate(currentTime);
      setStartTimeForRequest(currentTime);
    }
  };

  const onChange = (dates, e) => {
    let [start, end] = dates;

    if (!!limitDays && !!end) {
      const newStart = new Date(start).getTime();
      const newEnd = new Date(end).getTime();

      if (newStart + (limitDays - 1) * 24 * 60 * 60 * 1000 < newEnd) {
        return toast.error('Диапазон не должен превышать 7 дней', {theme: 'colored'});
      }
    }

    function getMillisecondsForMidnightInKievFromGMT(h, m, s, date) {
      const kievTime = momentTZ(date).tz('Europe/Kiev');
      const utcTime = kievTime.clone().utc();
      utcTime.hours(h);
      utcTime.minutes(m);
      utcTime.seconds(s);
      const milliseconds = utcTime.valueOf();
      return milliseconds;
    }

    if (!end) {
      setStartDate(start);
      setEndDate(null);
      const yearStart = start.getFullYear();
      const monthStart = start.getMonth();
      const dayStart = Number(e.target.innerText);
      const hoursStart = 0;
      const minutesStart = 0;
      const secondsStart = 0;
      const selectDateStart = new Date(
        Date.UTC(yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart)
      );

      const midnightInMillisStart = getMillisecondsForMidnightInKievFromGMT(
        0,
        0,
        0,
        selectDateStart
      );
      setEndTimeForRequest('');
      setStartTimeForRequest(midnightInMillisStart);
    }
    if (!!end) {
      const yearEnd = end.getFullYear();
      const monthEnd = end.getMonth();
      const dayEnd = Number(e.target.innerText);
      const hoursEnd = 0;
      const minutesEnd = 0;
      const secondsEnd = 0;

      const selectDateEnd = new Date(
        Date.UTC(yearEnd, monthEnd, dayEnd, hoursEnd, minutesEnd, secondsEnd)
      );

      const midnightInMillisEnd = getMillisecondsForMidnightInKievFromGMT(
        23,
        59,
        59,
        selectDateEnd
      );

      setEndDate(end);
      setEndTimeForRequest(midnightInMillisEnd);
    }
  };

  return (
    <CreateAppModal
      customWidth='mw-850px'
      show={showCreateAppModal}
      handleClose={() => setShowCreateAppModal(false)}
      title={'Календарь'}
    >
      <div className={classNames(css.container, css[`_${mode}`])}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          monthsShown={2}
          inline
          {...(minDate && {minDate})}
          {...(maxDate && {maxDate})}
        />
        <div className={css.otherContent}>
          <Button
            action={() => {
              const diff = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000;
              const endDay = startTimeForRequest + diff;
              handleCustom(
                {
                  time_config: 'custom',
                  time_start: startTimeForRequest || '',
                  time_end: !endTimeForRequest ? endDay : endTimeForRequest,
                },
                {startDate, endDate}
              );
              setShowCreateAppModal(false);
            }}
          >
            <>
              <KTIcon iconName='save-2' className='fs-1' />
              Выбрать
            </>
          </Button>
        </div>
      </div>
    </CreateAppModal>
  );
};

export default observer(Calendar);
