/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {formatNumberToCash} from '../../../../functions/formatNumber';
import {formatNumber} from '../../../../functions/formatNumber';
import Card from '../../../../shared/Card';
import Warning from '../../../../modules/Warning';
import Preloader from '../../../../modules/Preloader';
import PaginationBe from '../../../../shared/PaginationBe';

const Sources = ({links, romiList, timeConfig, id, title, subTitle}) => {
  const store = useStore();
  const [data, setData] = useState([]);

  const [prel, setPrel] = useState(false);
  const [input, setInput] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState('');

  useEffect(() => {
    setInput('');
  }, [timeConfig]);

  useEffect(() => {
    setData(links.rows);
    setPerPage(links.perPage);
    setTotal(links.total);
  }, [links]);

  const getInfluence = async (timeConfig, currentPage) => {
    setPrel(true);
    const getLinks = await store.trafficsFacebookGetLinksWithFilter({
      timeConfig,
      id,
      page: currentPage,
      perPage,
      sort,
      q: input,
    });

    // const newLinks = getLinks.rows.map((el) => {
    //   const romi = Math.round(+el.romi);
    //   const indicator = romiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);

    //   return {...el, indicator};
    // });

    setData(getLinks.rows);
    setPrel(false);
  };

  const filterSort = async (filter) => {
    setPrel(true);
    setPage(1);
    setSort(filter);
    const getLinks = await store.trafficsFacebookGetLinksWithFilter({
      timeConfig,
      id,
      page: 1,
      perPage,
      sort: filter,
      q: input,
    });

    // const newLinks = getLinks.rows.map((el) => {
    //   const romi = Math.round(+el.romi);
    //   const indicator = romiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);

    //   return {...el, indicator};
    // });

    setData(getLinks.rows);
    setPrel(false);
  };

  const filterSearch = async (text) => {
    setPrel(true);
    setPage(1);
    const getLinks = await store.trafficsFacebookGetLinksWithFilter({
      timeConfig,
      id,
      page: 1,
      perPage,
      q: text,
    });

    // const newLinks = getLinks.rows.map((el) => {
    //   const romi = Math.round(+el.romi);
    //   const indicator = romiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);

    //   return {...el, indicator};
    // });

    setPerPage(getLinks.perPage);
    setTotal(getLinks.total);
    setData(getLinks.rows);
    setPrel(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setInput(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setPrel(true);
    const newTimeoutId = setTimeout(() => {
      filterSearch(value);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='w-100 card-title justify-content-between'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <div className='d-flex align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                className='form-control form-control-solid w-100 mw-250px ps-13'
                placeholder='Поиск...'
                value={input}
                onChange={handleSearch}
              />
            </div>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive' style={{position: 'relative', minHeight: '100px'}}>
            {!!data?.length && (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>
                      <span
                        className='d-flex justify-content-start align-items-center cursor-pointer'
                        onClick={() => {
                          if (sort === 'id.asc') {
                            filterSort('id.desc');
                          } else {
                            filterSort('id.asc');
                          }
                        }}
                      >
                        ID
                        {sort !== 'id.asc' ? (
                          <i
                            className={`ki-duotone ki-down fs-1 ${
                              sort === 'id.desc' && 'text-primary'
                            }`}
                          />
                        ) : (
                          <i
                            className={`ki-duotone ki-up fs-1 ${
                              sort === 'id.asc' && 'text-warning'
                            }`}
                          />
                        )}
                      </span>
                    </th>
                    <th className='min-w-300px'>Название</th>
                    {!store.roles.includes(19) && (
                      <th className='min-w-120px'>
                        <span
                          className='d-flex justify-content-start align-items-center cursor-pointer'
                          onClick={() => {
                            if (sort === 'revenue.asc') {
                              filterSort('revenue.desc');
                            } else {
                              filterSort('revenue.asc');
                            }
                          }}
                        >
                          Доходы
                          {sort !== 'revenue.asc' ? (
                            <i
                              className={`ki-duotone ki-down fs-1 ${
                                sort === 'revenue.desc' && 'text-primary'
                              }`}
                            />
                          ) : (
                            <i
                              className={`ki-duotone ki-up fs-1 ${
                                sort === 'revenue.asc' && 'text-warning'
                              }`}
                            />
                          )}
                        </span>
                      </th>
                    )}
                    <th className='min-w-80px text-center'>
                      <span
                        className='d-flex justify-content-start align-items-center cursor-pointer'
                        onClick={() => {
                          if (sort === 'count_leads.asc') {
                            filterSort('count_leads.desc');
                          } else {
                            filterSort('count_leads.asc');
                          }
                        }}
                      >
                        Лиды
                        {sort !== 'count_leads.asc' ? (
                          <i
                            className={`ki-duotone ki-down fs-1 ${
                              sort === 'count_leads.desc' && 'text-primary'
                            }`}
                          />
                        ) : (
                          <i
                            className={`ki-duotone ki-up fs-1 ${
                              sort === 'count_leads.asc' && 'text-warning'
                            }`}
                          />
                        )}
                      </span>
                    </th>
                    <th className='min-w-80px text-center'>
                      <span
                        className='d-flex justify-content-start align-items-center cursor-pointer'
                        onClick={() => {
                          if (sort === 'count_appeals.asc') {
                            filterSort('count_appeals.desc');
                          } else {
                            filterSort('count_appeals.asc');
                          }
                        }}
                      >
                        Обращения
                        {sort !== 'count_appeals.asc' ? (
                          <i
                            className={`ki-duotone ki-down fs-1 ${
                              sort === 'count_appeals.desc' && 'text-primary'
                            }`}
                          />
                        ) : (
                          <i
                            className={`ki-duotone ki-up fs-1 ${
                              sort === 'count_appeals.asc' && 'text-warning'
                            }`}
                          />
                        )}
                      </span>
                    </th>
                    <th className='min-w-80px text-center'>
                      <span
                        className='d-flex justify-content-start align-items-center cursor-pointer'
                        onClick={() => {
                          if (sort === 'count_registrations.asc') {
                            filterSort('count_registrations.desc');
                          } else {
                            filterSort('count_registrations.asc');
                          }
                        }}
                      >
                        Регистрации
                        {sort !== 'count_registrations.asc' ? (
                          <i
                            className={`ki-duotone ki-down fs-1 ${
                              sort === 'count_registrations.desc' && 'text-primary'
                            }`}
                          />
                        ) : (
                          <i
                            className={`ki-duotone ki-up fs-1 ${
                              sort === 'count_registrations.asc' && 'text-warning'
                            }`}
                          />
                        )}
                      </span>
                    </th>
                    <th className='min-w-80px text-center'>
                      <span
                        className='d-flex justify-content-start align-items-center cursor-pointer'
                        onClick={() => {
                          if (sort === 'count_qualifications.asc') {
                            filterSort('count_qualifications.desc');
                          } else {
                            filterSort('count_qualifications.asc');
                          }
                        }}
                      >
                        Квалификации
                        {sort !== 'count_qualifications.asc' ? (
                          <i
                            className={`ki-duotone ki-down fs-1 ${
                              sort === 'count_qualifications.desc' && 'text-primary'
                            }`}
                          />
                        ) : (
                          <i
                            className={`ki-duotone ki-up fs-1 ${
                              sort === 'count_qualifications.asc' && 'text-warning'
                            }`}
                          />
                        )}
                      </span>
                    </th>
                    {!!data.length && !!data[0].hasOwnProperty('status') && (
                      <th className='min-w-120px'>Статус</th>
                    )}
                    <th className='w-80px text-end'></th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-semibold'>
                  {data.map((el, index) => {
                    return (
                      <tr key={el?.id}>
                        <td>
                          <span>#{el?.id}</span>
                        </td>
                        <td>
                          <Link to={`/tracker/facebook/source/${el?.id}`}>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6 text-nowrap'>
                              {el?.name}
                            </span>
                          </Link>
                        </td>

                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.revenue)}
                            </span>
                          </td>
                        )}
                        <td className='text-center'>
                          <span>{formatNumber(el?.count_leads)}</span>
                        </td>
                        <td className='text-center'>
                          <span>{formatNumber(el?.count_appeals)}</span>
                        </td>
                        <td className='text-center'>
                          <span>{formatNumber(el?.count_registrations)}</span>
                        </td>
                        <td className='text-center'>
                          <span>{formatNumber(el?.count_qualifications)}</span>
                        </td>

                        {!!el.hasOwnProperty('status') && (
                          <td>
                            {!!el?.status && (
                              <span className={`badge badge-light-${el?.status?.indicator}`}>
                                {el?.status?.name}
                              </span>
                            )}
                            {!el?.status && <span className='badge badge-light'>Неизвестно</span>}
                          </td>
                        )}
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {el?.count_leads === 0 &&
                              (!!store.roles.includes(4) || !!store.roles.includes(11)) && (
                                <ButtonDelete
                                  data={data}
                                  setData={setData}
                                  index={index}
                                  link={el}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {prel && <Preloader style={{border: '0'}} />}
            {!data?.length && <span className='fs-6 fw-semibold text-gray-400'>{subTitle}</span>}
          </div>
          <PaginationBe
            arrLength={total}
            limit={perPage}
            page={page}
            setPage={setPage}
            action={(currentPage) => getInfluence(timeConfig, currentPage)}
          />
        </div>
      </Card>
    </>
  );
};

const ButtonDelete = observer(({data, setData, index, link}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const deleteLink = async () => {
    const result = store.trafficsInfluenceLinksDeleteLink(link.id);

    if (!result) {
      return toast.error(`Произошла ошибка в удалении!`, {
        theme: 'colored',
      });
    }

    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);

    toast.success(`Успешно удалено!`);
  };

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => setShowCreateAppModal(true)}
      >
        <i className='ki-duotone ki-trash fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>
      </span>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <span>
              Вы уверены что хотите удалить рекламную кампанию{' '}
              <span className='text-primary'>{link.name}</span>
            </span>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                deleteLink();
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default observer(Sources);
