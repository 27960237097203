/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState, useRef} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../_metronic/partials';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import PageWrapper from '../../modules/PageWrapper';
import Stepper from './Stepper';
import Tabs from './Tabs';
import Previews from './Previews';
import Form from './templates/googleplay-app/Form';
import FormSite from './templates/googleplay_site/Form';
import FormLucky from './templates/1win-lucky/Form';
import FormBloggers from './templates/1win-bloggers/Form';
import NameForm from './NameForm';
import DomainForm from './DomainForm';
import CopyForm from './CopyForm';
import Preloader from '../../modules/Preloader';
import LandingForm from './LandingForm';

import ServicesForm from './services/ServicesForm';

import {googleplay_app, googleplay_site, lucky, bloggers} from './templates/objectsTemplates';
import {set} from 'mobx';

async function getWithToken(url, token) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
async function putWithToken(url, token, data) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}
async function postWithToken(url, token, data) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}
async function deleteWithToken(url, token, data = {}) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}

const Component = observer(({setPreloader, isVerify}) => {
  const {pwaId} = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const store = useStore();
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  const [prel, setPrel] = useState(false);

  const [token, setToken] = useState('');
  const [id, setId] = useState(null);

  const [step, setStep] = useState(0);
  const [currentLang, setCurrentLang] = useState(null);

  const [data, setData] = useState({});
  // const [dataNameForm, setDataNameForm] = useState({offers: [], template: {label: '1win Blogger Landing'}});
  const [dataNameForm, setDataNameForm] = useState({offers: []});
  const [dataDomainForm, setDataDomainForm] = useState({activeForm: false});

  const [dataOffersFormFt, setDataOffersFormFt] = useState({});
  const [dataOffersFormBc, setDataOffersFormBc] = useState({});

  const [templatesNames, setTemplatesNames] = useState([]);

  const [domains, setDomains] = useState([]);
  const [subDomains, setSubDomains] = useState([]);
  const [validSubDomain, setValidSubDomain] = useState(true);

  const [formNameError, setFormNameError] = useState([]);
  const [formOffersError, setForOffersError] = useState(false);
  const [formOffersGeoError, setForOffersGeoError] = useState([]);
  const [formDomainError, setFormDomainError] = useState([]);

  const [offers, setOffers] = useState([]);
  const [offersOptions, setOffersOptions] = useState([]);
  const [advertisersOptions, setAdvertisersOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesObj, setCountriesObj] = useState([]);
  const [landings, setLandings] = useState([]);

  const [checkedService, setCheckedService] = useState(0);
  const [checkedType, setCheckedType] = useState({label: 'PWA', value: 'pwa'});
  const [updateDataForServices, setUpdateDataForServices] = useState(null);
  const [pwaToCopy, setPwaToCopy] = useState(null);

  const isUpdate = useRef(false);

  const getType = (type) => {
    const find = [
      {label: 'PWA', value: 'pwa'},
      {label: 'Android', value: 'android'},
      {label: 'iOS', value: 'ios'},
    ].find((item) => type === item.value);

    return find;
  };

  const getUpdateData = async (newOffers, tempList, subDomains, userToken) => {
    setId(pwaId);
    isUpdate.current = true;
    const getPwaData = await store.resourcePWAgetAppConstructor(pwaId);

    setCheckedType(getType(getPwaData.type_config));

    if (!!getPwaData.service_id) {
      setCheckedService(getPwaData.service_id);

      setStep(100);
      setUpdateDataForServices({
        data: JSON.parse(JSON.stringify({...getPwaData, domain: getPwaData?.link})),
        offers: {
          country_offers: getPwaData?.country_offers,
          offer_landing_id: getPwaData?.offer_landing_id,
          split_enabled: getPwaData?.split_enabled,
          apps_split: getPwaData?.apps_split,
        },
      });
      return;
    }

    const template = tempList.find((item) => item.id === getPwaData?.template_id);

    setDataOffersFormBc({
      country_offers: getPwaData?.country_offers,
      offer_landing_id: getPwaData?.offer_landing_id,
      split_enabled: getPwaData?.split_enabled,
      apps_split: getPwaData?.apps_split,
    });
    setDataOffersFormFt({
      country_offers: getPwaData?.country_offers,
      offer_landing_id: getPwaData?.offer_landing_id,
      split_enabled: getPwaData?.split_enabled,
      apps_split: getPwaData?.apps_split,
    });

    setDataNameForm({
      name: getPwaData?.name,
      template: {value: template?.id, label: template?.label},
    });

    setDataDomainForm({
      domain_id: getPwaData?.domain_id,
      subdomain_id: getPwaData?.subdomain_id,
      subdomain: subDomains
        .find((item) => item.id === getPwaData?.subdomain_id)
        ?.name?.split('.')[0],
    });

    setData(JSON.parse(JSON.stringify(getPwaData?.data)) || {});
  };

  const addLang = (lang) => {
    setCurrentLang(lang);

    if (dataNameForm?.template?.label === 'Google-Play-Store') {
      setData({...data, [lang]: {...googleplay_app, base: false}});
    }

    if (dataNameForm?.template?.label === 'Google-Play-Site') {
      setData({...data, [lang]: {...googleplay_site, base: false}});
    }

    if (dataNameForm?.template?.label === 'LuckyJet Web-to-Web') {
      setData({...data, [lang]: {...lucky, base: false}});
    }
    if (dataNameForm?.template?.label === '1win Blogger Landing') {
      setData({...data, [lang]: {...bloggers, base: false}});
    }
  };

  const setTemplate = (label) => {
    setCurrentLang('en');

    if (label === 'Google-Play-Store') {
      setData({...data, en: {...googleplay_app, base: false}});
    }

    if (label === 'Google-Play-Site') {
      setData({...data, en: {...googleplay_site, base: false}});
    }

    if (label === 'LuckyJet Web-to-Web') {
      setData({...data, en: {...lucky, base: false}});
    }
    if (label === '1win Blogger Landing') {
      setData({...data, en: {...bloggers, base: false}});
    }
  };

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  useEffect(() => {
    setCurrentLang('en');
    !!isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    setPrel(true);

    const token = store.user.main_token;
    setToken(token);

    const [
      getTemplatesList,
      getAdvertisers,
      getOffers,
      getCountries,
      getLandingsList,
      getDomains,
      getSubDomains,
    ] = await Promise.all([
      getWithToken('templates/list', token),
      store.getAdvertisersList(),
      store.offersGetOffers(),
      store.getCountries(),
      store.offersGetLandingsList(),
      getWithToken('domains/list', token),
      getWithToken('domains/sub/list', token),
    ]);

    setLandings(getLandingsList);

    setDomains(
      getDomains.data.map((item) => {
        return {value: item.id, label: item.name};
      })
    );

    setSubDomains(getSubDomains.data);

    setOffers(getOffers);
    setAdvertisersOptions(
      getAdvertisers.map((item) => {
        return {value: item.id, label: `#${item?.id} ${item.name}`};
      })
    );

    setCountries(
      getCountries.map((item) => {
        return {label: item.name, value: item.id};
      })
    );
    setCountriesObj(getCountries);

    setTemplatesNames(
      getTemplatesList.data.map((item) => {
        return {value: item.id, label: item.label};
      })
    );

    if (pwaId !== undefined) {
      setStep(1);
      getUpdateData(getOffers, getTemplatesList.data, getSubDomains.data, token);
    }

    setPrel(false);
    setPreloader(false);
  };

  const validationForm = (validation, data) => {
    const noValid = [];
    validation.forEach((valid) => {
      if (
        data[valid] === undefined ||
        data[valid] === null ||
        data[valid] === '' ||
        data[valid].length === 0
      ) {
        noValid.push(valid);
      }
    });

    return noValid;
  };

  const pwaCopy = async () => {
    const obj = {
      ...dataOffersFormBc,
      name: dataNameForm?.name,
      domain_id: dataDomainForm?.domain_id,
      subdomain_id: null,
      copy_notifications: pwaToCopy.addPushes,
      copy_split: pwaToCopy.addOffers,
    };

    const postSubDomain = await postWithToken(`domains/${dataDomainForm?.domain_id}/sub`, token, {
      name: dataDomainForm?.subdomain,
    });

    obj.subdomain_id = postSubDomain.data.id;
    const createPwa = await postWithToken(`pwa/${pwaToCopy.id}/copy`, token, obj);

    const putDomain = await putWithToken(`pwa/${createPwa?.data?.id}/updatePwaDomain`, token, {
      subdomain_id: postSubDomain.data.id,
      domain_id: dataDomainForm?.domain_id,
    });

    navigate(`/app/constructor/${createPwa?.data?.id}?step=4`);
  };

  useEffect(() => {
    if (!pwaId) return;
    if (!!pwaToCopy) {
      (async () => {
        const step = searchParams.get('step');
        setId(pwaId);
        isUpdate.current = true;
        const getPwaData = await store.resourcePWAgetAppConstructor(pwaId);

        if (!!getPwaData.service_id) {
          return;
        }

        const newData = JSON.parse(JSON.stringify(getPwaData.data));

        setData(newData);
        setStep(+step);
      })();
    }
  }, [pwaId]);

  const saveName = async () => {
    const newData = {
      ...dataNameForm,
      template_id: dataNameForm?.template?.value,
    };
    delete newData.template;
    const valArr = ['name', 'template_id'];

    const validation = validationForm(valArr, newData);

    if (validation.length > 0) {
      return setFormNameError(validation);
    }
    setFormNameError([]);
    setStep(2);
  };

  const saveOffers = async (obj) => {
    if (!obj.offer_landing_id) {
      return setForOffersError(true);
    } else if (obj?.country_offers?.length > 0) {
      const indexes = [];
      obj?.country_offers.forEach((item, index) => {
        if (!item.offer_landing_id) {
          indexes.push(index);
        }
      });

      if (!indexes.length) {
        setDataOffersFormBc(obj);
        setDataOffersFormFt(obj);
        setForOffersError(false);
        setForOffersGeoError([]);
        setStep(3);
      } else {
        setForOffersGeoError(indexes);
      }
    } else {
      setDataOffersFormBc(obj);
      setDataOffersFormFt(obj);
      setForOffersError(false);
      setForOffersGeoError([]);
      setStep(3);
    }
  };

  const saveDomain = async () => {
    const validation = validationForm(['domain_id', 'subdomain'], dataDomainForm);

    if (validation.length > 0) {
      return setFormDomainError(validation);
    }
    setFormDomainError([]);

    if (!validSubDomain) return;

    if (!!pwaToCopy && !id) {
      pwaCopy();
    } else {
      setStep(4);
    }
  };

  const createPwa = async () => {
    const newDataNameForm = {
      ...dataNameForm,
      ...dataOffersFormBc,
      template_id: dataNameForm?.template?.value,
    };

    const createName = await postWithToken('pwa', token, newDataNameForm);
    if (!createName.success) {
      setPrel(false);
      setFormNameError(['name']);
      return toast.error('Название уже сущесвует', {theme: 'colored'});
    }
    setId(createName.data.id);
    return createName.data.id;
  };

  const save = async () => {
    setPrel(true);
    let idResult = null;
    const newDataNameForm = {
      ...dataNameForm,
      ...dataOffersFormBc,
      template_id: dataNameForm?.template?.value,
    };

    // settings;
    if (!!id) {
      const updateName = await putWithToken(`pwa/${id}`, token, newDataNameForm);
      idResult = id;
    } else {
      const createName = await postWithToken('pwa', token, newDataNameForm);
      if (!createName.success) {
        setPrel(false);
        setFormNameError(['name']);
        return toast.error('Название уже сущесвует', {theme: 'colored'});
      }
      setId(createName.data.id);
      idResult = createName.data.id;
    }
    //domain
    if (!isUpdate.current) {
      const postSubDomain = await postWithToken(`domains/${dataDomainForm?.domain_id}/sub`, token, {
        name: dataDomainForm?.subdomain,
      });

      const putDomain = await putWithToken(`pwa/${idResult}/updatePwaDomain`, token, {
        subdomain_id: postSubDomain.data.id,
        domain_id: dataDomainForm?.domain_id,
      });

      isUpdate.current = true;
    }

    //constructor

    // Запит на оновлення
    const create = await putWithToken(`pwa/${idResult}/updatePwaData`, token, {data: data});
    const build = await postWithToken(`pwa/${idResult}/build`, token);
    setPrel(false);

    if (!!id) {
      navigate(`/app/${id}`);
    } else {
      navigate(`/app/${checkedType.value}`);
    }
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      {step !== 100 && (
        <div
          className={css.container}
          style={{
            '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
            '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
            '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
            '--activeColor':
              themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
            '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
            '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
            '--buttonGrayBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
            '--slideSelectorBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
            '--slideSelectorItemBg':
              themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
            '--buttonCommentBg':
              themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
          }}
        >
          <div className={css.constructorContainer}>
            {step !== 0 && <Stepper themeMode={themeMode} step={step} />}
            <div className={css.table}>
              <Tabs
                themeMode={themeMode}
                setData={setData}
                data={data}
                setCurrentLang={setCurrentLang}
                currentLang={currentLang}
                step={step}
                id={id}
                deleteWithToken={deleteWithToken}
                token={token}
                addLang={addLang}
              />
              <section className={css.form} style={{position: 'relative'}}>
                {prel && <Preloader style={{border: 'none'}} />}
                {currentLang === null && (
                  <span className={css.warning}>
                    Добавте вкладку для дальнейшего создания Приложения...
                  </span>
                )}
                {!!currentLang && (
                  <>
                    {step === 0 && (
                      <>
                        <CopyForm
                          setStep={setStep}
                          templatesNames={templatesNames}
                          setDataNameForm={setDataNameForm}
                          setPwaToCopy={setPwaToCopy}
                          setDataOffersFormBc={setDataOffersFormBc}
                          setDataOffersFormFt={setDataOffersFormFt}
                          checkedService={checkedService}
                          setCheckedService={setCheckedService}
                          checkedType={checkedType}
                          setCheckedType={setCheckedType}
                          setUpdateDataForServices={setUpdateDataForServices}
                        />
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <NameForm
                          data={dataNameForm}
                          setData={setDataNameForm}
                          templatesNames={templatesNames}
                          postWithToken={postWithToken}
                          deleteWithToken={deleteWithToken}
                          putWithToken={putWithToken}
                          token={token}
                          advertisersOptions={advertisersOptions}
                          offersOptions={offersOptions}
                          offers={offers}
                          countries={countries}
                          setPreloader={setPrel}
                          errors={formNameError}
                          id={id}
                          setTemplate={setTemplate}
                          pwaToCopy={pwaToCopy}
                        />
                        <div className={css.next}>
                          <button className={css.blueButton} onClick={saveName}>
                            Продолжить
                          </button>
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <LandingForm
                          advertisersOptions={advertisersOptions}
                          offers={offers}
                          countries={countriesObj}
                          landings={landings}
                          dataFt={dataOffersFormFt}
                          setDataFt={setDataOffersFormFt}
                          dataBc={dataOffersFormBc}
                          setDataBc={setDataOffersFormBc}
                          setStep={setStep}
                          saveOffers={saveOffers}
                          errors={formOffersError}
                          errorsGeo={formOffersGeoError}
                        />
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <DomainForm
                          data={dataDomainForm}
                          setData={setDataDomainForm}
                          domains={domains}
                          saveDomain={saveDomain}
                          errors={formDomainError}
                          subDomains={subDomains}
                          validSubDomain={validSubDomain}
                          setValidSubDomain={setValidSubDomain}
                          isUpdate={isUpdate.current}
                        />
                        <div className={css.next}>
                          <button
                            className={css.grayButton}
                            onClick={() => setStep(2)}
                            style={{minWidth: '130px'}}
                          >
                            Назад
                          </button>

                          <button
                            className={css.blueButton}
                            style={{minWidth: '130px'}}
                            onClick={saveDomain}
                          >
                            Продолжить
                          </button>
                        </div>
                      </>
                    )}
                    {step === 4 && (
                      <>
                        {dataNameForm?.template?.label === 'Google-Play-Store' && (
                          <>
                            <Form
                              themeMode={themeMode}
                              config={data}
                              setConfig={setData}
                              currentLang={currentLang}
                              setStep={setStep}
                              post={postWithToken}
                              id={id}
                              token={token}
                              createPwa={createPwa}
                              setId={setId}
                            />
                            {console.log(data)}
                            <div className={css.next}>
                              <button
                                className={css.grayButton}
                                onClick={() => setStep(3)}
                                style={{minWidth: '130px'}}
                              >
                                Назад
                              </button>
                              <button
                                className={css.blueButton}
                                onClick={save}
                                style={{minWidth: '130px'}}
                              >
                                Сохранить
                              </button>
                            </div>
                          </>
                        )}
                        {dataNameForm?.template?.label === 'Google-Play-Site' && (
                          <>
                            <FormSite
                              themeMode={themeMode}
                              config={data}
                              setConfig={setData}
                              currentLang={currentLang}
                              setStep={setStep}
                              post={postWithToken}
                              id={id}
                              token={token}
                              createPwa={createPwa}
                              setId={setId}
                            />
                            <div className={css.next}>
                              <button
                                className={css.grayButton}
                                onClick={() => setStep(3)}
                                style={{minWidth: '130px'}}
                              >
                                Назад
                              </button>
                              <button
                                className={css.blueButton}
                                onClick={save}
                                style={{minWidth: '130px'}}
                              >
                                Сохранить
                              </button>
                            </div>
                          </>
                        )}
                        {dataNameForm?.template?.label === 'LuckyJet Web-to-Web' && (
                          <>
                            <FormLucky
                              themeMode={themeMode}
                              config={data}
                              setConfig={setData}
                              currentLang={currentLang}
                              setStep={setStep}
                              post={postWithToken}
                              id={id}
                              token={token}
                              createPwa={createPwa}
                              setId={setId}
                            />
                            <div className={css.next}>
                              <button
                                className={css.grayButton}
                                onClick={() => setStep(3)}
                                style={{minWidth: '130px'}}
                              >
                                Назад
                              </button>
                              <button
                                className={css.blueButton}
                                onClick={save}
                                style={{minWidth: '130px'}}
                              >
                                Сохранить
                              </button>
                            </div>
                          </>
                        )}
                        {dataNameForm?.template?.label === '1win Blogger Landing' && (
                          <>
                            <FormBloggers
                              themeMode={themeMode}
                              config={data}
                              setConfig={setData}
                              currentLang={currentLang}
                              setStep={setStep}
                              post={postWithToken}
                              id={id}
                              token={token}
                              createPwa={createPwa}
                              setId={setId}
                            />
                            <div className={css.next}>
                              <button
                                className={css.grayButton}
                                onClick={() => setStep(3)}
                                style={{minWidth: '130px'}}
                              >
                                Назад
                              </button>
                              <button
                                className={css.blueButton}
                                onClick={save}
                                style={{minWidth: '130px'}}
                              >
                                Сохранить
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </section>
            </div>
          </div>
          {step === 4 && (
            <div
              className={css.preview}
              style={
                dataNameForm?.template?.label === '1win Blogger Landing' ||
                dataNameForm?.template?.label === 'LuckyJet Web-to-Web'
                  ? {overflowY: 'hidden'}
                  : {}
              }
            >
              <Previews
                data={data}
                currentLang={currentLang}
                typeApp={dataNameForm?.template?.label}
                id={id || pwaId}
              />
            </div>
          )}
        </div>
      )}
      {step === 100 && (
        <ServicesForm
          setPreloader={setPreloader}
          offers={offers}
          advertisersOptions={advertisersOptions}
          countriesObj={countriesObj}
          landings={landings}
          checkedService={checkedService}
          checkedType={checkedType}
          validationForm={validationForm}
          postWithToken={postWithToken}
          putWithToken={putWithToken}
          token={token}
          updateDataForServices={updateDataForServices}
          pwaToCopy={pwaToCopy}
          id={id}
        />
      )}
    </>
  );
});

const PwasConstructor = observer(() => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ``},
        {isSeparator: true},
        {title: 'Конструктор Приложения', path: `/app/constructor`},
      ]}
      title={`Конструктор Приложения`}
    >
      <Component />
    </PageWrapper>
  );
});

export default PwasConstructor;
