/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import PageWrapper from '../../../../modules/PageWrapper';
import {formatNumberToCash, formatNumber2} from '../../../../functions/formatNumber';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {toast} from 'react-toastify';
import moment from 'moment';
import PaginationBe from '../../../../shared/PaginationBe';
import {SelectorWithSearch} from '../../../../shared/Selectors';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {Button} from '../../../../shared/Buttons';
import AddAgent from './AddAgent';

const AgentsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Facebook Агенты', path: 'tracker/facebook/agents', isActive: true},
      ]}
      title={'Facebook Агенты'}
      firstTimeConfig='today'
      limitDays={7}
    >
      <Agents />
    </PageWrapper>
  );
};

const is_active = [
  // {value: '', label: 'Не выбрано'},
  {value: 1, label: 'Активный'},
  {value: 0, label: 'Не активный'},
];

const Agents = observer(({isVerify, setPreloader, timeConfig}) => {
  const store = useStore();

  const [accounts, setAccounts] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);

  const [bayers, setBayers] = useState([]);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    isVerify && timeConfig.time_config && getData(offset) && getFilters();
  }, [isVerify, timeConfig]);

  useEffect(() => {
    const containersXL = document.querySelectorAll('.container-xxl');
    containersXL.forEach((el) => (el.style.maxWidth = '100%'));
    return () => {
      containersXL.forEach((el) => (el.style.maxWidth = ''));
    };
  }, []);

  const getFilters = async () => {
    const [getBayers] = await Promise.all([store.trafficsFacebookGetBayersList()]);
    setBayers([
      {value: '', label: 'Не выбрано'},
      ...getBayers.map((item) => ({
        label: `#${item.id} ${item?.first_name} ${item?.last_name}`,
        value: item.id,
      })),
    ]);
  };

  const getData = async (page = 1, filters = null) => {
    let filtersQue = '';
    if (filters) {
      Object.entries(filters).forEach((item) => {
        filtersQue += `&${item[0]}=${item[1]}`;
      });
    }

    const [getAccounts] = await Promise.all([
      store.trafficsFacebookAgentsGetAgents(
        timeConfig,
        `&page=${page}&per_page=${limit}${filtersQue}`
      ),
    ]);
    setAccounts(getAccounts.rows);
    setTotal(getAccounts.total);
    setPreloader(false);
  };

  const update = async (id) => {
    await store.trafficsFacebookAgentsUpdateAgentsExpanses({...timeConfig, agent_id: id});
  };

  return (
    <div className='card'>
      <div className='card-header py-7'>
        <div
          className='card-toolbar'
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div>
            {store.roles.includes(6) || store.roles.includes(7) ? (
              <></>
            ) : (
              <ButtonAdd getData={getData} />
            )}
          </div>
          <div className='d-flex flex-wrap' style={{gap: '20px'}}>
            {store.roles.includes(6) || store.roles.includes(7) ? (
              <></>
            ) : (
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                  }),
                }}
                placeholder='Байера...'
                options={bayers}
                actions={(e) => {
                  if (!e) {
                    const newFilters = {...filters};
                    delete newFilters.bayer_id;
                    getData(offset, newFilters);
                    setFilters(newFilters);
                    return;
                  }
                  getData(offset, {...filters, bayer_id: e.value});
                  setFilters((prev) => ({...prev, bayer_id: e.value}));
                }}
                value={bayers.find((item) => item.value === filters.bayer_id)}
                clearable={true}
              />
            )}
            <SelectorWithSearch
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '42px',
                }),
              }}
              placeholder='Статус...'
              options={is_active}
              actions={(e) => {
                if (!e) {
                  const newFilters = {...filters};
                  delete newFilters.is_active;
                  getData(offset, newFilters);
                  setFilters(newFilters);
                  return;
                }
                getData(offset, {...filters, is_active: e.value});
                setFilters((prev) => ({...prev, is_active: e.value}));
              }}
              value={is_active.find((item) => item.value === filters.is_active)}
              clearable={true}
            />
          </div>
        </div>
      </div>
      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px text-start' style={{whiteSpace: 'nowrap'}}>
                    Агент
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Процент
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Расходы
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Статус
                  </th>
                  {/* <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Дата создания
                  </th> */}

                  <th className='min-w-120px text-start'></th>
                </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
                {accounts?.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span
                            className='me-2'
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              maxWidth: '350px',
                            }}
                          >
                            {item?.name}
                          </span>
                          <span className='me-2 text-gray-400'>#{item?.id}</span>
                        </div>
                      </td>
                      <td className='text-center'>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span className='me-2' style={{whiteSpace: 'nowrap'}}>
                            {formatNumber2(item?.percent)}%
                          </span>
                        </div>
                      </td>
                      <td className='text-center'>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span className='me-2' style={{whiteSpace: 'nowrap'}}>
                            {formatNumberToCash(item?.expenses)}
                          </span>
                        </div>
                      </td>
                      <td className='text-center'>
                        <div
                          className={`badge ${
                            !item.is_active ? 'badge-light-danger' : 'badge-light-success'
                          }`}
                        >
                          {!item.is_active ? 'Не активный' : 'Активный'}
                        </div>
                      </td>
                      {/* <td className='text-center'>
                        {!!item?.created ? (
                          <div className={`badge badge-danger`}>
                            {moment.tz(item?.created * 1000, 'Europe/Kyiv').format('DD/MM/YYYY')}
                          </div>
                        ) : (
                          '-'
                        )}
                      </td> */}

                      <td className='text-end'>
                        <div
                          style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-end'}}
                        >
                          {store.roles.includes(38) ? (
                            <></>
                          ) : (
                            <OverlayTrigger
                              delay={{hide: 450, show: 300}}
                              placement='top'
                              overlay={(props) => (
                                <Tooltip className='fs-8' {...props}>
                                  Обновление
                                </Tooltip>
                              )}
                            >
                              <span
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => update(item?.id)}
                              >
                                <i className='ki-duotone ki-abstract-33 fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </OverlayTrigger>
                          )}
                          {store.roles.includes(6) || store.roles.includes(7) ? (
                            <></>
                          ) : (
                            <ButtonUpdate getData={getData} data={item} />
                          )}
                          {Number(item.accounts_count) > 0 && (
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={async () => {
                                const result = await store.trafficsFacebookAgentsDeleteAgent({
                                  id: item.id,
                                });

                                if (!result) {
                                  return toast.error(`Произошла ошибка в удалении!`, {
                                    theme: 'colored',
                                  });
                                }

                                getData();
                                toast.success(`Успешно удалено!`);
                              }}
                            >
                              <i className='ki-duotone ki-trash fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                                <span className='path5'></span>
                              </i>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {total > limit && (
          <PaginationBe
            arrLength={total}
            limit={limit}
            page={offset}
            setPage={setOffset}
            action={(currentPage) => {
              getData(currentPage, filters);
            }}
          />
        )}
      </div>
    </div>
  );
});

export const ButtonAdd = ({getData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление агента'}
      >
        <AddAgent
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getData={getData}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({getData, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование агента'}
      >
        <AddAgent
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getData={getData}
          isUpdate={true}
          data={data}
        />
      </CreateAppModal>
    </>
  );
};

export default AgentsWrapper;
