/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import css from '../../../../styles/PagePwa.module.scss';
import classNames from 'classnames';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import PageWrapper from '../../../modules/PageWrapper';
import {useThemeMode} from '../../../../_metronic/partials';
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import moment from 'moment';
import {formatNumberToCash, formatNumber, formatNumber2} from '../../../functions/formatNumber';
import Navigation from '../../../modules/NavigationBloggers';
import {Button} from '../../../shared/Buttons';
import {Input} from '../../../shared/Inputs';
import PaginationBe from '../../../shared/PaginationBe';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';
import Preloader from '../../../modules/Preloader';
import Activity from './Activity';
import Facebook from './Facebook';
import Settings from './Settings';
import ButtonExel from '../../../modules/ButtonExel';
import {IMaskInput, IMask} from 'react-imask';
import {toast} from 'react-toastify';
import PwaConfig from './PwaConfig';

const formatName = {
  pwa: 'PWA',
  ios: 'iOS',
  android: 'Android',
};

const Pwa = () => {
  const [searchParams] = useSearchParams();
  const {id} = useParams();
  const [appName, setAppName] = useState('');
  const [prevTimeConfig, setPrevTimeConfig] = useState(null);

  useEffect(() => {
    const timeConfig = searchParams.get('time_config');
    const timeStart = searchParams.get('time_start');
    const timeEnd = searchParams.get('time_end');

    if (!timeConfig) return;

    setPrevTimeConfig({
      time_config: timeConfig,
      time_start: !!timeStart ? +timeStart * 1000 : '',
      time_end: !!timeEnd ? +timeEnd * 1000 : '',
    });
  }, []);

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      prevTimeConfig={prevTimeConfig}
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Приложения', path: `/app/${appName}`},
        {isSeparator: true},
        {title: `${appName && formatName[appName]} #${id}`, path: `/app/${id}`, isActive: true},
      ]}
      title={
        <span>
          {appName === 'android' && <i className='bi bi-android2 fs-2 me-2 text-dark'></i>}
          {appName === 'ios' && <i className='bi bi-apple fs-2 me-2 text-dark'></i>}
          {appName === 'pwa' && <i className='bi bi-phone fs-3 me-1 text-dark'></i>}
          {appName && formatName[appName]} #{id}
        </span>
      }
    >
      <Component id={id} setAppName={setAppName} />
    </PageWrapper>
  );
};

const Component = ({isVerify, timeConfig, setPreloader, id, setAppName}) => {
  const store = useStore();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [page, setPage] = useState('config');
  const [isExternal, setIsExternal] = useState(false);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [timeConfig, isVerify]);

  useEffect(() => {
    const containersXL = document.querySelectorAll('.container-xxl');
    containersXL.forEach((el) => (el.style.maxWidth = '100%'));

    return () => containersXL.forEach((el) => (el.style.maxWidth = ''));
  }, []);

  const getData = async () => {
    const [getApp, getStatistics, getAppNew] = await Promise.all([
      store.resourcePWAgetApp(timeConfig, id),
      store.resourcePWAmainStats(timeConfig, id),
      store.resourcePWAgetAppNew(timeConfig, id),
    ]);

    setAppName(getApp?.type_config);
    setIsExternal(typeof getApp?.service_id === 'number');

    setData({
      ...getApp,
      getStatistics,
      ...getAppNew,
      // getPush,
    });
    setPreloader(false);
  };

  const updateData = (newData) => {
    if (data) {
      setData({
        ...data,
        name: newData.name,
        domain_name: newData.domain_name,
        avatar: newData.avatar,
      });
    }
  };

  const onCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <>
      <div className='card mb-8'>
        <div className='card-body pt-4 pb-0 px-8'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-4'>
            <div className='me-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={data?.avatar ? data.avatar : imagePlug}
                  style={{width: '82px', height: '82px'}}
                  onError={(e) => (e.target.src = imagePlug)}
                  alt=''
                />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div
                className='d-md-flex justify-content-between align-items-start flex-wrap mb-0'
                style={{gap: '15px', display: 'flex'}}
              >
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-0'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {data?.name}
                    </span>
                    {data?.is_active_facebook_traffic && (
                      <span className={`badge badge-light-primary ms-2`}>Facebook</span>
                    )}
                    {data?.is_active_influence_traffic && (
                      <span className={`badge badge-light-danger ms-2`}>Influence</span>
                    )}
                  </div>
                  <div style={{display: 'flex', flexWrap: 'wrap', gap: '15px'}}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        className='d-flex flex-wrap fw-semibold mb-0 fs-5 text-gray-400 gap-1'
                        style={{paddingTop: '5px'}}
                      >
                        {data?.service_id ? `#${data?.service_id}` : ''}
                        <span className='text-uppercase'>
                          {data?.service_name ? data?.service_name : 'PWAHUNT'}
                        </span>
                      </div>
                      <div className='d-flex flex-wrap fw-semibold mb-0 fs-5 text-gray-400 gap-1'>
                        <span
                          className={`badge-lg badge-light px-2 fs-7`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            color: '#99A1B7',
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='11'
                            height='11'
                            fill='none'
                            viewBox='0 0 11 11'
                          >
                            <g id='Group'>
                              <path
                                id='Vector'
                                fill='#99A1B7'
                                d='M4.58333 10.3125C4.58333 10.6925 4.27579 11 3.89583 11H2.52083C1.13071 11 0 9.86929 0 8.47917V3.4375C0 2.04738 1.13071 0.916667 2.52083 0.916667H2.75V0.6875C2.75 0.307542 3.05754 0 3.4375 0C3.81746 0 4.125 0.307542 4.125 0.6875V0.916667H6.875V0.6875C6.875 0.307542 7.183 0 7.5625 0C7.942 0 8.25 0.307542 8.25 0.6875V0.916667H8.47917C9.86883 0.916667 11 2.04738 11 3.4375C11 3.81746 10.692 4.125 10.3125 4.125H1.375V8.47917C1.375 9.11075 1.88925 9.625 2.52083 9.625H3.89583C4.27579 9.625 4.58333 9.93254 4.58333 10.3125ZM11 8.02083C11 9.66625 9.66625 11 8.02083 11C6.37542 11 5.04167 9.66625 5.04167 8.02083C5.04167 6.37542 6.37542 5.04167 8.02083 5.04167C9.66625 5.04167 11 6.37542 11 8.02083ZM9.09517 8.12304L8.70833 7.73621V7.10417C8.70833 6.72467 8.40033 6.41667 8.02083 6.41667C7.64133 6.41667 7.33333 6.72467 7.33333 7.10417V7.92596C7.33333 8.16887 7.43004 8.40217 7.60192 8.57404L8.12304 9.09517C8.39162 9.36375 8.82658 9.36375 9.09517 9.09517C9.36375 8.82658 9.36375 8.39162 9.09517 8.12304Z'
                              />
                            </g>
                          </svg>
                          {moment(new Date(data?.created * 1000)).format('DD/MM/yyyy')}
                        </span>
                        <span
                          className={`badge-lg badge-light-${
                            !!data.active ? 'success' : 'danger'
                          } px-2 fs-7`}
                        >
                          {!!data?.active ? 'Активно' : 'Не активно'}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-wrap justify-content-start'>
                      <div className='d-flex flex-wrap' style={{gap: '8px'}}>
                        <div className='border border-gray-300 border-dashed rounded min-w-90px py-2 px-3'>
                          <div className='d-flex align-items-center'>
                            <i
                              className={`ki-duotone ki-arrow-${
                                data?.click2lead > 10 ? 'up' : 'down'
                              } fs-3 text-${data?.click2lead > 10 ? 'success' : 'danger'} me-2`}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            <div className='fs-6 fw-bold'>{formatNumber2(data?.click2lead)}%</div>
                          </div>
                          <div className='fw-semibold fs-7 text-gray-400'>Click2Lead</div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-90px py-2 px-3'>
                          <div className='d-flex align-items-center'>
                            <i
                              className={`ki-duotone ki-arrow-${
                                data?.lead2reg > 30 ? 'up' : 'down'
                              } fs-3 text-${data?.lead2reg > 30 ? 'success' : 'danger'} me-2`}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            <div className='fs-6 fw-bold'>{formatNumber2(data?.lead2reg)}%</div>
                          </div>
                          <div className='fw-semibold fs-7 text-gray-400'>Lead2Reg</div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-90px py-2 px-3'>
                          <div className='d-flex align-items-center'>
                            <i
                              className={`ki-duotone ki-arrow-${
                                data?.reg2cpa > 15 ? 'up' : 'down'
                              } fs-3 text-${data?.reg2cpa > 15 ? 'success' : 'danger'} me-2`}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            <div className='fs-6 fw-bold'>{formatNumber2(data?.reg2cpa)}%</div>
                          </div>
                          <div className='fw-semibold fs-7 text-gray-400'>Reg2Cpa</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='d-flex mb-4 flex-wrap' style={{rowGap: '0.75rem'}}>
                    <div
                      role='button'
                      onClick={() => {
                        window.open(
                          `https://${isExternal ? data?.link : data?.domain_name}`,
                          '_blank'
                        );
                      }}
                      className={classNames(
                        css.copyContainer,
                        'btn btn-light btn-active-color-primary cursor-pointer p-0 me-3'
                      )}
                    >
                      {/* <i className='ki-duotone ki-copy text-gray-500 p-0' style={{ fontSize: 22 }}></i> */}
                      <i className='bi bi-link-45deg text-gray-500 p-0' style={{fontSize: 22}}></i>
                    </div>
                    {store.roles.includes(38) || store.roles.includes(6) ? (
                      <></>
                    ) : (
                      <Button
                        className='me-3'
                        color='btn-primary'
                        action={() => navigate(`/app/constructor/${id}`)}
                      >
                        Редактировать
                      </Button>
                    )}
                    {isExternal &&
                      (store.roles.includes(4) ||
                        store.roles.includes(10) ||
                        store.roles.includes(39)) && (
                        <div
                          role='button'
                          onClick={() => {
                            let params = [];
                            if (data.click_param) {
                              params.push(`click_id={${data.click_param}}`);
                            }
                            if (data.external_param) {
                              params.push(`external_id={${data.external_param}}`);
                            }

                            const queryString = params.length ? `?${params.join('&')}` : '';
                            onCopy(`https://click.thl.ac/app/${data.id}${queryString}`);
                          }}
                          className={classNames(
                            css.copyContainer,
                            'btn btn-light btn-active-color-primary cursor-pointer p-0'
                          )}
                        >
                          <i
                            className='ki-duotone ki-copy text-gray-500'
                            style={{fontSize: 22}}
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='separator'></div>
          <Navigation
            page={page}
            setPage={setPage}
            links={
              isExternal
                ? [
                    {link: 'config', title: 'Конфигурация'},
                    // {link: 'statistics', title: 'Cтатистика'},
                    // {link: 'facebook', title: 'Facebook'},
                  ]
                : [
                    {link: 'config', title: 'Конфигурация'},
                    // {link: 'statistics', title: 'Cтатистика'},
                    {link: 'push', title: 'Push-Уведомления'},
                    // {link: 'facebook', title: 'Facebook'},
                  ]
            }
          />
        </div>
      </div>

      {page === 'config' && <PwaConfig data={data} isExternal={isExternal} roles={store.roles} />}
      {/* {page === 'statistics' && <Statistics data={data?.getStatistics} isExternal={isExternal} />} */}
      {page === 'push' && <Push data={data?.getPush} id={id} timeConfig={timeConfig} />}
      {page === 'activity' && <Activity id={id} timeConfig={timeConfig} />}
      {page === 'facebook' && <Facebook id={id} timeConfig={timeConfig} />}
      {page === 'settings' && <Settings data={data} updateData={updateData} />}
    </>
  );
};

const Statistics = ({data, isExternal}) => {
  const theme = useThemeMode();
  const [mode, setMode] = useState('');

  const [moreInformation, setMoreInformation] = useState(false);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <div className={classNames(css.statisticsContainer, mode === 'dark' && css._dark)}>
      <section className={css.header}>
        <span className={css.title}>Общая статистика</span>
      </section>
      <section className={classNames(css.statistics, isExternal && css.statistics__isExternal)}>
        <div className={classNames(css.section_1, isExternal && css.section_1__isExternal)}>
          <Stat
            title={'Квалификации'}
            num={formatNumber(data?.qualifications_count)}
            mode={mode}
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='28'
                height='20'
                fill='none'
                viewBox='0 0 28 20'
              >
                <path
                  fill='#9A71F7'
                  d='M3.462 4.922c0 2.806 2.083 4.922 4.846 4.922 2.762 0 4.846-2.116 4.846-4.922C13.154 2.117 11.07 0 8.308 0 5.545 0 3.462 2.117 3.462 4.922Zm20.076.224a1 1 0 0 0-1-1h-.769a1 1 0 0 0-1 1v3.219h-3.154a1 1 0 0 0-1 1v.813a1 1 0 0 0 1 1h3.154v3.218a1 1 0 0 0 1 1h.77a1 1 0 0 0 1-1v-3.219h3.153a1 1 0 0 0 1-1v-.812a1 1 0 0 0-1-1h-3.154v-3.22ZM2.77 19.616h12.846a1 1 0 0 0 1-1v-.407c0-3.877-3.105-7.031-6.923-7.031h-2.77C3.106 11.178 0 14.332 0 18.209v.406a1 1 0 0 0 1 1h1.77Z'
                />
              </svg>
            }
          />
          <Stat
            title={'Установки'}
            num={formatNumber(data?.install_count)}
            mode={mode}
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='28'
                height='20'
                fill='none'
                viewBox='0 0 28 20'
              >
                <path
                  fill='#3CF9DB'
                  d='M3.462 4.922c0 2.806 2.083 4.922 4.846 4.922 2.762 0 4.846-2.116 4.846-4.922C13.154 2.117 11.07 0 8.308 0 5.545 0 3.462 2.117 3.462 4.922Zm20.076.224a1 1 0 0 0-1-1h-.769a1 1 0 0 0-1 1v3.219h-3.154a1 1 0 0 0-1 1v.813a1 1 0 0 0 1 1h3.154v3.218a1 1 0 0 0 1 1h.77a1 1 0 0 0 1-1v-3.219h3.153a1 1 0 0 0 1-1v-.812a1 1 0 0 0-1-1h-3.154v-3.22ZM2.77 19.616h12.846a1 1 0 0 0 1-1v-.407c0-3.877-3.105-7.031-6.923-7.031h-2.77C3.106 11.178 0 14.332 0 18.209v.406a1 1 0 0 0 1 1h1.77Z'
                />
              </svg>
            }
          />
          {!isExternal && (
            <Stat
              title={'VTR'}
              num={`${formatNumber2(data?.vtr_push)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <mask
                    id='a'
                    width='24'
                    height='24'
                    x='0'
                    y='0'
                    maskUnits='userSpaceOnUse'
                    style={{maskType: 'luminance'}}
                  >
                    <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='4'>
                      <path
                        fill='#fff'
                        stroke='#fff'
                        d='M22 11.833c0 5.431-4.477 9.834-10 9.834H2v-9.834C2 6.403 6.477 2 12 2s10 4.402 10 9.833Z'
                      />
                      <path stroke='#000' d='m7 12.817 3 2.95 6.5-6.392' />
                    </g>
                  </mask>
                  <g mask='url(#a)'>
                    <path fill='#58A0F5' d='M0 .033h24v23.6H0V.033Z' />
                  </g>
                </svg>
              }
            />
          )}
          <Stat
            title={'Регистрации'}
            num={formatNumber(data?.registration_count)}
            mode={mode}
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='26'
                height='20'
                fill='none'
                viewBox='0 0 26 20'
              >
                <path
                  fill='#63D48A'
                  d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                />
              </svg>
            }
          />
          <Stat
            title={'Выручка'}
            num={formatNumberToCash(data?.revenue_amount)}
            mode={mode}
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='20'
                fill='none'
                viewBox='0 0 25 20'
              >
                <path
                  fill='#F5C51D'
                  d='M22.5 0h-20A2.503 2.503 0 0 0 0 2.5v15C0 18.879 1.121 20 2.5 20h20c1.379 0 2.5-1.121 2.5-2.5v-15C25 1.121 23.879 0 22.5 0ZM8.394 5c1.438 0 2.5 1.061 2.5 2.5S9.832 10 8.394 10c-1.439 0-2.5-1.061-2.5-2.5S6.954 5 8.394 5Zm4.643 10H3.75v-.581c0-1.716 2.095-3.482 4.644-3.482s4.643 1.766 4.643 3.482V15Zm8.213-1.25h-5v-2.5h5v2.5Zm0-5H15v-2.5h6.25v2.5Z'
                />
              </svg>
            }
          />
          {!isExternal && (
            <Stat
              title={'CTR'}
              num={`${formatNumber2(data?.ctr_push)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='28'
                  fill='none'
                  viewBox='0 0 28 28'
                >
                  <g fill='#FF6D04'>
                    <path d='M21 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z' />
                    <path d='M15.167 7c0-.83.178-1.618.492-2.333H7A2.336 2.336 0 0 0 4.667 7v14A2.336 2.336 0 0 0 7 23.333h14A2.336 2.336 0 0 0 23.333 21v-8.659c-.735.323-1.53.49-2.333.492A5.833 5.833 0 0 1 15.167 7Z' />
                  </g>
                </svg>
              }
            />
          )}
        </div>
        <div className={css.section_2}>
          <Stat2
            num={`${formatNumber2(data?.click2fd)}%`}
            title={'Click2Fd'}
            mode={mode}
            color={'#F5C51D'}
          />
          <Stat2
            num={`${formatNumber2(data?.registration2fd)}%`}
            title={'Reg2Fd'}
            mode={mode}
            color={'#FF6D04'}
          />
          <Stat2
            num={`${formatNumber2(data?.['\u0441pa2rd'] || data)}%`}
            title={'Cpa2Rd'}
            mode={mode}
            color={'#9A71F7'}
          />
          <Stat2
            num={`${formatNumber2(data?.rd2td)}%`}
            title={'Rd2Td'}
            mode={mode}
            color={'#58A0F5'}
          />
        </div>
      </section>
      <section className={css.moreInfo}>
        {!!moreInformation && (
          <div className={css.info}>
            <section className={css.section_1}>
              <div className={css.top}>
                <div className={classNames(css.column, mode === 'dark' && css._dark)}>
                  <div className={css.columnTitle}>FD</div>
                  <Stat
                    title={'Количество'}
                    num={formatNumber(data?.fd_count)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        fill='none'
                        viewBox='0 0 26 26'
                      >
                        <g fill='#9A71F7'>
                          <path d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                          <path d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                        </g>
                      </svg>
                    }
                  />
                  <Stat
                    title={'Сумма'}
                    num={formatNumberToCash(data?.fd_amount)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='20'
                        fill='none'
                        viewBox='0 0 26 20'
                      >
                        <path
                          fill='#63D48A'
                          d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                        />
                      </svg>
                    }
                  />
                </div>
                <div className={classNames(css.column, mode === 'dark' && css._dark)}>
                  <div className={css.columnTitle}>RD</div>
                  <Stat
                    title={'Количество'}
                    num={formatNumber(data?.rd_count)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        fill='none'
                        viewBox='0 0 26 26'
                      >
                        <g fill='#FF6D04'>
                          <path d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                          <path d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                        </g>
                      </svg>
                    }
                  />
                  <Stat
                    title={'Сумма'}
                    num={formatNumberToCash(data?.rd_amount)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='20'
                        fill='none'
                        viewBox='0 0 26 20'
                      >
                        <path
                          fill='#63D48A'
                          d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                        />
                      </svg>
                    }
                  />
                </div>
                <div className={classNames(css.column, mode === 'dark' && css._dark)}>
                  <div className={css.columnTitle}>TD</div>
                  <Stat
                    title={'Количество'}
                    num={formatNumber(data?.td_count)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        fill='none'
                        viewBox='0 0 26 26'
                      >
                        <g fill='#3C66F9'>
                          <path d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                          <path d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                        </g>
                      </svg>
                    }
                  />
                  <Stat
                    title={'Сумма'}
                    num={formatNumberToCash(data.td_amount)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='20'
                        fill='none'
                        viewBox='0 0 26 20'
                      >
                        <path
                          fill='#63D48A'
                          d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
              <div className={css.bottom}>
                {!isExternal && (
                  <Stat
                    title={'Включенные уведомления'}
                    num={formatNumber(data?.notification_count)}
                    mode={mode}
                    svg={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='21'
                        height='23'
                        fill='none'
                        viewBox='0 0 21 23'
                      >
                        <path
                          fill='#2F80ED'
                          d='M10.428 0A8.167 8.167 0 0 0 2.26 8.167v4.116c0 .18-.042.36-.122.521L.136 16.81a1.283 1.283 0 0 0 1.148 1.858h18.288a1.284 1.284 0 0 0 1.148-1.858l-2.002-4.005a1.168 1.168 0 0 1-.123-.521V8.167A8.167 8.167 0 0 0 10.428 0Zm0 22.167a3.5 3.5 0 0 1-3.302-2.334h6.603a3.5 3.5 0 0 1-3.301 2.334Z'
                        />
                      </svg>
                    }
                  />
                )}
                <Stat
                  title={'Кол-во кликов'}
                  num={formatNumber(data?.count_click)}
                  mode={mode}
                  svg={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='28'
                      height='28'
                      fill='none'
                      viewBox='0 0 28 28'
                    >
                      <path
                        fill='#9A71F7'
                        d='M13.132 19.258c.252.85-.421 1.694-1.268 1.432-1.108-.342-2.088-.958-2.939-1.848C7.642 17.5 7 15.886 7 14c0-1.944.68-3.597 2.042-4.958C10.402 7.68 12.056 7 14 7c1.886 0 3.5.642 4.842 1.925.89.851 1.506 1.831 1.848 2.94.262.846-.582 1.52-1.432 1.267-.438-.13-.76-.49-.932-.915a4.573 4.573 0 0 0-1.41-1.877c-.835-.67-1.808-1.006-2.916-1.007-1.283 0-2.382.457-3.296 1.371-.914.914-1.37 2.013-1.37 3.296 0 1.108.335 2.08 1.006 2.917a4.58 4.58 0 0 0 1.877 1.409c.425.171.784.494.915.932Zm1.762 5.845c.084.272-.084.564-.369.564H14c-1.614 0-3.13-.307-4.55-.92a11.8 11.8 0 0 1-3.704-2.493 11.757 11.757 0 0 1-2.493-3.704 11.386 11.386 0 0 1-.92-4.55c0-1.613.306-3.13.92-4.55a11.808 11.808 0 0 1 2.493-3.704A11.764 11.764 0 0 1 9.45 3.253a11.365 11.365 0 0 1 4.55-.92c1.612 0 3.129.307 4.55.92a11.765 11.765 0 0 1 3.704 2.493 11.825 11.825 0 0 1 2.494 3.704 11.29 11.29 0 0 1 .919 4.55v.525c0 .285-.292.453-.565.37l-1.434-.442a.474.474 0 0 1-.335-.453c0-2.606-.904-4.813-2.712-6.62C18.813 5.57 16.606 4.666 14 4.666c-2.606 0-4.813.904-6.62 2.712C5.57 9.188 4.666 11.394 4.666 14s.904 4.813 2.712 6.62c1.809 1.81 4.015 2.713 6.621 2.713.208 0 .392.136.453.335l.441 1.434Zm10.204-.005a1.63 1.63 0 0 1-2.304 0l-3.836-3.835-1.458 4.404L14 14l11.667 3.5-4.405 1.458 3.836 3.836a1.63 1.63 0 0 1 0 2.304Z'
                      />
                    </svg>
                  }
                />
              </div>
            </section>
            {!isExternal && (
              <section className={css.section_2}>
                <Stat2
                  num={`${formatNumber2(data?.dr_push)}%`}
                  title={'DR'}
                  mode={mode}
                  color={'#3CF9DB'}
                />
                <Stat2
                  num={`${formatNumber2(data?.install2notification)}%`}
                  title={'Instal2Push'}
                  mode={mode}
                  color={'#63D48A'}
                />
              </section>
            )}
          </div>
        )}
        <div className={css.moreInfoButton}>
          {!moreInformation && (
            <span
              className='text-primary text-bold border-bottom border-primary cursor-pointer fw-bold'
              onClick={() => setMoreInformation(true)}
            >
              Вся статистика
            </span>
          )}
          {moreInformation && (
            <span
              className='text-gray-500 text-bold border-bottom border-gray-500 cursor-pointer fw-bold'
              onClick={() => setMoreInformation(false)}
            >
              Свернуть статистику
            </span>
          )}
        </div>
      </section>
    </div>
  );
};

const Stat = ({title, svg, num, mode}) => {
  return (
    <div className={classNames(css.stat, mode === 'dark' && css._dark)}>
      <span className={classNames(css.title, 'text-gray-500')}>{title}</span>
      <div className={css.paramContainer}>
        <span className={css.icon}>{svg}</span>
        <span className={css.param}>{num}</span>
      </div>
    </div>
  );
};

const Stat2 = ({num, title, mode, color}) => {
  return (
    <div className={classNames(css.stat, css.stat_2, mode === 'dark' && css._dark)}>
      <span className={css.num} style={{color}}>
        {num}
      </span>
      <span className={classNames(css.title, 'text-gray-500')}>{title}</span>
    </div>
  );
};

const Push = observer(({data = {}, id, timeConfig}) => {
  const store = useStore();
  const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  const days2 = [
    {day: 'Понедельник', id: 1},
    {day: 'Вторник', id: 2},
    {day: 'Среда', id: 3},
    {day: 'Четверг', id: 4},
    {day: 'Пятница', id: 5},
    {day: 'Суббота', id: 6},
    {day: 'Воскресенье', id: 0},
  ];

  const theme = useThemeMode();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [mode, setMode] = useState('');
  const [pushes, setPushes] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filtersConfig, setFiltersConfig] = useState({
    is_install: '',
    is_reg: '',
    is_first_dep: '',
    is_rep_dep: '',
    week_day: [],
    is_delete: 0,
    sort: '',
    is_delivered: 0,
    min_vtr: '',
    max_vtr: '',
    min_ctr: '',
    max_ctr: '',
  });
  const [input, setInput] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const [preloader, setPreloader] = useState(false);
  const [pushesForExel, setPushesForExel] = useState([]);
  const [timeForExel, setTimePushesForExel] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    sort(filtersConfig);
  }, [timeConfig]);

  useEffect(() => {
    if (pushes.length === 0) return;
    getForExel();
  }, [pushes]);

  const getStatus = (el) => {
    if (!el?.is_reg && !el?.is_fd && !el?.is_rd) return 'Установка';
    if (!!el?.is_reg && !el?.is_fd && !el?.is_rd) return 'Регистрация';
    if (!!el?.is_reg && !!el?.is_fd && !el?.is_rd) return 'Первый депозит';
    if (!!el?.is_reg && !!el?.is_fd && !!el?.is_rd) return 'Повторный депозит';
  };

  const getForExel = async () => {
    const res = await store.resourcePWAgetAppPushForExel({
      timeConfig,
      id,
      per_page: total,
      page: 1,
      ...filtersConfig,
    });

    setTimePushesForExel(res.time);
    setPushesForExel(
      res?.data?.rows?.map((item) => {
        return {
          id: item.id,
          Активна: !item.is_delete ? 'Да' : 'Нет',
          Конфигурация: getStatus(item),
          'Время (Киев)': item.time,
          Заголовок: item.title,
          'Заголовок РУ': item.title_ru,
          Тело: item.text,
          'Тело РУ': item.text_ru,
          'День недели': days[item.week_day],
          Доставлено: item.delivered,
          'Dilivery Rate': item.dr_push,
          CTR: item.ctr_push,
          VTR: item.vtr_push,
          'Дата создания': moment
            .tz(item.created_at * 1000, 'Europe/Kyiv')
            .format('HH:mm DD.MM.YYYY'),
          'Дата Удаления': !!item.delete_created
            ? moment.tz(item.delete_created * 1000, 'Europe/Kyiv').format('HH:mm DD.MM.YYYY')
            : '',
        };
      })
    );
  };

  const sort = async (filters) => {
    setPushes([]);
    setPreloader(true);
    const res = await store.resourcePWAgetAppPush({
      timeConfig,
      id,
      per_page: perPage,
      page: 1,
      ...filters,
    });
    setPushes(res.rows);
    setTotal(res.total);
    setPage(1);
    setPreloader(false);
  };

  const filterSearch = async (text) => {
    setPushes([]);
    setPreloader(true);
    const res = await store.resourcePWAgetAppPush({
      timeConfig,
      id,
      per_page: perPage,
      page: 1,
      q: text,
      ...filtersConfig,
    });
    setPushes(res.rows);
    setTotal(res.total);
    setPage(1);
    setPreloader(false);
  };

  const changePage = async (page) => {
    setPushes([]);
    setPreloader(true);
    const res = await store.resourcePWAgetAppPush({
      timeConfig,
      id,
      per_page: perPage,
      page,
      ...filtersConfig,
    });
    setPushes(res.rows);
    setTotal(res.total);
    setPreloader(false);
  };

  const changePerPage = async (e) => {
    setPushes([]);
    setPreloader(true);
    const res = await store.resourcePWAgetAppPush({
      timeConfig,
      id,
      per_page: e,
      page: 1,
      ...filtersConfig,
    });
    setPushes(res.rows);
    setTotal(res.total);
    setPreloader(false);
  };

  const handleSearch = (event) => {
    setPushes([]);
    const value = event.target.value;
    setInput(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setPreloader(true);
    const newTimeoutId = setTimeout(() => {
      filterSearch(value);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const saveFile = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('app_id', id);

    const result = await store.PWAimportAppPush(formData);

    !!result && toast.success(`Успешно сохранено!`, {});
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  return (
    <div
      className={classNames(css.pushes, mode === 'dark' && css._dark)}
      style={{position: 'relative'}}
    >
      <section className={css.header}>
        <div className={css.titleContainer}>
          <span className={css.title}>Push уведомления</span>
        </div>
        <div className={css.filters}>
          {(filtersConfig.is_install !== '' ||
            filtersConfig.is_reg !== '' ||
            filtersConfig.is_first_dep !== '' ||
            filtersConfig.is_rep_dep !== '' ||
            filtersConfig.week_day.length !== 0 ||
            filtersConfig.is_delete !== '' ||
            filtersConfig.is_delivered !== 0 ||
            filtersConfig.min_ctr !== '' ||
            filtersConfig.max_ctr !== '' ||
            filtersConfig.min_vtr !== '' ||
            filtersConfig.max_vtr !== '' ||
            filtersConfig.sort !== '') && (
            <div
              className={classNames(css.refresh, mode === 'dark' && css._dark)}
              onClick={() => {
                sort({page: 1});
                setPage(1);
                setFiltersConfig({
                  is_install: '',
                  is_reg: '',
                  is_first_dep: '',
                  is_rep_dep: '',
                  week_day: [],
                  is_delete: '',
                  sort: '',
                  is_delivered: 0,
                  min_vtr: '',
                  max_vtr: '',
                  min_ctr: '',
                  max_ctr: '',
                });
              }}
            >
              Сбросить фильтры
            </div>
          )}
          <div className={css.filter} onClick={() => setShowCreateAppModal(true)}>
            Фильтр
          </div>
          <div className='d-flex align-items-center position-relative'>
            {/* <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i> */}
            <input
              style={{height: '43px'}}
              type='text'
              className='form-control form-control-solid w-100 mw-250px '
              placeholder='Поиск...'
              value={input}
              onChange={handleSearch}
            />
          </div>
          <label
            className={`btn btn-sm shadow-xs d-flex align-items-center btn-active-light-success`}
            htmlFor='inputExel'
          >
            <input
              style={{width: '0', opacity: '0', visibility: 'hidden'}}
              type='file'
              accept='.xls,.xlsx,.csv'
              id='inputExel'
              onChange={saveFile}
            />
            <i className='ki-duotone ki-add-files fs-1 pe-0'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
          </label>
          <ButtonExel
            data={pushesForExel}
            fieldsToKeep={[
              'id',
              'Активна',
              'Конфигурация',
              'Время (Киев)',
              'Заголовок',
              'Заголовок РУ',
              'Тело:',
              'Тело РУ',
              'День недели',
              'Доставлено',
              'Dilivery Rate',
              'CTR',
              'VTR',
              'Дата создания',
              'Дата Удаления',
            ]}
            columnFormats={{
              CTR: '#,##0.00%',
              VTR: '#,##0.00%',
              'Dilivery Rate': '#,##0.00%',
              'Время (Киев)': 'HH:mm',
              'Дата создания': 'HH:mm DD.MM.YYYY',
              'Дата Удаления': 'HH:mm DD.MM.YYYY',
            }}
            fileName={`Приложение #${id}_pushes`}
            time={timeForExel}
          />
          {store.roles.includes(38) || store.roles.includes(6) ? (
            <></>
          ) : (
            <AddPush id={id} sort={sort} filtersConfigPushes={filtersConfig} />
          )}
        </div>
      </section>
      <section className={css.table} style={{position: 'relative'}}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-50px'>ID</th>
                <th className='min-w-340px'>Заголовок</th>
                <td className='text-end min-w-100px'>
                  <span
                    className='d-flex justify-content-center align-items-center cursor-pointer'
                    onClick={() => {
                      if (filtersConfig.sort === 'delivered.asc') {
                        sort({...filtersConfig, sort: 'delivered.desc'});
                        setFiltersConfig({...filtersConfig, sort: 'delivered.desc'});
                      } else {
                        sort({...filtersConfig, sort: 'delivered.asc'});
                        setFiltersConfig({...filtersConfig, sort: 'delivered.asc'});
                      }
                    }}
                  >
                    Отправлено
                    {filtersConfig.sort !== 'delivered.asc' ? (
                      <i
                        className={`ki-duotone ki-down fs-1 ${
                          filtersConfig.sort === 'delivered.desc' && 'text-primary'
                        }`}
                      />
                    ) : (
                      <i
                        className={`ki-duotone ki-up fs-1 ${
                          filtersConfig.sort === 'delivered.asc' && 'text-warning'
                        }`}
                      />
                    )}
                  </span>
                </td>
                <td className='text-end min-w-90px'>
                  <span
                    className='d-flex justify-content-center align-items-center cursor-pointer'
                    onClick={() => {
                      if (filtersConfig.sort === 'dr.asc') {
                        sort({...filtersConfig, sort: 'dr.desc'});
                        setFiltersConfig({...filtersConfig, sort: 'dr.desc'});
                      } else {
                        sort({...filtersConfig, sort: 'dr.asc'});
                        setFiltersConfig({...filtersConfig, sort: 'dr.asc'});
                      }
                    }}
                  >
                    DR
                    {filtersConfig.sort !== 'dr.asc' ? (
                      <i
                        className={`ki-duotone ki-down fs-1 ${
                          filtersConfig.sort === 'dr.desc' && 'text-primary'
                        }`}
                      />
                    ) : (
                      <i
                        className={`ki-duotone ki-up fs-1 ${
                          filtersConfig.sort === 'dr.asc' && 'text-warning'
                        }`}
                      />
                    )}
                  </span>
                </td>
                <td className='text-end min-w-90px'>
                  <span
                    className='d-flex justify-content-center align-items-center cursor-pointer'
                    onClick={() => {
                      if (filtersConfig.sort === 'vtr.asc') {
                        sort({...filtersConfig, sort: 'vtr.desc'});
                        setFiltersConfig({...filtersConfig, sort: 'vtr.desc'});
                      } else {
                        sort({...filtersConfig, sort: 'vtr.asc'});
                        setFiltersConfig({...filtersConfig, sort: 'vtr.asc'});
                      }
                    }}
                  >
                    VTR
                    {filtersConfig.sort !== 'vtr.asc' ? (
                      <i
                        className={`ki-duotone ki-down fs-1 ${
                          filtersConfig.sort === 'vtr.desc' && 'text-primary'
                        }`}
                      />
                    ) : (
                      <i
                        className={`ki-duotone ki-up fs-1 ${
                          filtersConfig.sort === 'vtr.asc' && 'text-warning'
                        }`}
                      />
                    )}
                  </span>
                </td>
                <td className='text-end min-w-90px'>
                  <span
                    className='d-flex justify-content-center align-items-center cursor-pointer'
                    onClick={() => {
                      if (filtersConfig.sort === 'ctr.asc') {
                        sort({...filtersConfig, sort: 'ctr.desc'});
                        setFiltersConfig({...filtersConfig, sort: 'ctr.desc'});
                      } else {
                        sort({...filtersConfig, sort: 'ctr.asc'});
                        setFiltersConfig({...filtersConfig, sort: 'ctr.asc'});
                      }
                    }}
                  >
                    CTR
                    {filtersConfig.sort !== 'ctr.asc' ? (
                      <i
                        className={`ki-duotone ki-down fs-1 ${
                          filtersConfig.sort === 'ctr.desc' && 'text-primary'
                        }`}
                      />
                    ) : (
                      <i
                        className={`ki-duotone ki-up fs-1 ${
                          filtersConfig.sort === 'ctr.asc' && 'text-warning'
                        }`}
                      />
                    )}
                  </span>
                </td>
                <th className='min-w-120px text-start'>Этап</th>
                <th className='min-w-120px'>Отправка</th>
                <th className='min-w-120px'>Cтатус</th>
                {store.roles.includes(38) || store.roles.includes(6) ? (
                  <></>
                ) : (
                  <th className='min-w-30px'></th>
                )}
                <th className='min-w-30px'></th>
                {store.roles.includes(38) || store.roles.includes(6) ? (
                  <></>
                ) : (
                  <th className='min-w-30px'></th>
                )}
                {/* <th className='min-w-30px'></th> */}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {pushes.map((el, index) => {
                return (
                  <tr key={el?.id}>
                    <td>
                      <span>#{el?.id}</span>
                    </td>
                    <td>
                      <span className='text-gray-900 fw-bold d-block mb-1 fs-6'>{el?.title}</span>
                    </td>
                    <td className='text-gray-900 fw-bold text-center'>
                      <span>{formatNumber(el?.delivered)}</span>
                    </td>
                    <td className='text-gray-900 fw-bold text-center'>
                      <span>{formatNumber2(el?.dr_push)}%</span>
                    </td>
                    <td className='text-gray-900 fw-bold text-center'>
                      <span>{formatNumber2(el?.vtr_push)}%</span>
                    </td>
                    <td className='text-gray-900 fw-bold text-center'>
                      <span>{formatNumber2(el?.ctr_push)}%</span>
                    </td>
                    <td className='text-start'>
                      {!el?.is_reg && !el?.is_fd && !el?.is_rd && (
                        <span className='badge badge-light-primary'>Установка</span>
                      )}
                      {!!el?.is_reg && !el?.is_fd && !el?.is_rd && (
                        <span className='badge badge-light-success'>Регистрация</span>
                      )}
                      {!!el?.is_reg && !!el?.is_fd && !el?.is_rd && (
                        <span className='badge badge-light-info'>Первый депозит</span>
                      )}
                      {!!el?.is_reg && !!el?.is_fd && !!el?.is_rd && (
                        <span className='badge badge-light-danger'>Повторный депозит</span>
                      )}
                    </td>
                    <td>
                      <span className='badge badge-light-warning'>
                        {days[el.week_day]} ({el.time})
                      </span>
                    </td>
                    <td className='text-start'>
                      {!!el?.is_delete && (
                        <span className='badge badge-light-secondary'>Архив</span>
                      )}
                      {!el?.is_delete && <span className='badge badge-light-success'>Активно</span>}
                    </td>
                    {store.roles.includes(38) || store.roles.includes(6) ? (
                      <></>
                    ) : (
                      <td className=''>
                        <div className='d-flex justify-content-center'>
                          <PushUpdate
                            data={el}
                            mode={mode}
                            id={id}
                            sort={sort}
                            filtersConfigPushes={filtersConfig}
                          />
                        </div>
                      </td>
                    )}
                    <td className=''>
                      <div className='d-flex justify-content-center'>
                        <PushModal data={el} mode={mode} days={days} pwaId={id} />
                      </div>
                    </td>
                    {store.roles.includes(38) || store.roles.includes(6) ? (
                      <></>
                    ) : (
                      <>
                        {!el.is_delete && (
                          <td className=''>
                            <ToArchive
                              mode={mode}
                              id={el?.id}
                              sort={sort}
                              filtersConfig={filtersConfig}
                            />
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {preloader && (
          <Preloader style={{border: 'none', width: '101%', height: '101%', boxShadow: 'none'}} />
        )}
        <PaginationBe
          arrLength={total}
          limit={perPage}
          perPage={perPage}
          setPage={setPage}
          page={page}
          action={(currentPage) => {
            changePage(currentPage);
          }}
          changePerPage={(e) => {
            setPerPage(e);
            changePerPage(e);
          }}
        />
      </section>
      <CreateAppModal
        customWidth='mw-850px'
        padding='scroll-y'
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        title={'Фильтр'}
      >
        <div className={css.popupFilters}>
          <div className={css.block}>
            <label
              className={classNames(
                'form-check form-switch form-check-custom form-check-solid',
                css.moreZero
              )}
            >
              <span>Задействованные</span>
              <input
                className='form-check-input'
                type='checkbox'
                name='is_active'
                checked={Boolean(filtersConfig.is_delivered)}
                onChange={() =>
                  setFiltersConfig({
                    ...filtersConfig,
                    is_delivered: Number(!filtersConfig.is_delivered),
                  })
                }
              />
            </label>
          </div>
          <div className={css.block}>
            <div className={css.rangeContainer}>
              <div className={css.inputsContainer}>
                <span className={css.title}>VTR</span>
                <div className={css.inputs}>
                  <Input
                    style={{
                      minHeight: '30px',
                      height: '41px',
                    }}
                    classText='inputRange-1min'
                    text={'От'}
                    value={filtersConfig.min_vtr || ''}
                    handleChange={(e) => {
                      setFiltersConfig({
                        ...filtersConfig,
                        min_vtr: e.target.value
                          .replace(',', '.')
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1'),
                      });
                    }}
                  />
                  <Input
                    style={{
                      minHeight: '30px',
                      height: '41px',
                    }}
                    classText='inputRange-1min'
                    text={'До'}
                    value={filtersConfig.max_vtr || ''}
                    handleChange={(e) => {
                      setFiltersConfig({
                        ...filtersConfig,
                        max_vtr: e.target.value
                          .replace(',', '.')
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1'),
                      });
                    }}
                  />
                </div>
              </div>
              <div className={css.inputsContainer}>
                <span className={css.title}>CTR</span>
                <div className={css.inputs}>
                  <Input
                    style={{
                      minHeight: '30px',
                      height: '41px',
                    }}
                    classText='inputRange-1min'
                    text={'От'}
                    value={filtersConfig.min_ctr || ''}
                    handleChange={(e) => {
                      setFiltersConfig({
                        ...filtersConfig,
                        min_ctr: e.target.value
                          .replace(',', '.')
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1'),
                      });
                    }}
                  />
                  <Input
                    style={{
                      minHeight: '30px',
                      height: '41px',
                    }}
                    classText='inputRange-1min'
                    text={'До'}
                    value={filtersConfig.max_ctr || ''}
                    handleChange={(e) => {
                      setFiltersConfig({
                        ...filtersConfig,
                        max_ctr: e.target.value
                          .replace(',', '.')
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1'),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.block}>
            <span className={css.title}>День</span>
            <div className={css.buttons}>
              <div
                className={classNames(
                  css.button,
                  filtersConfig.week_day.length === 0 && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.week_day = [];
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Все
              </div>
              {days2.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      css.button,
                      filtersConfig.week_day.includes(item.id) && css._active,
                      mode === 'dark' && css._dark
                    )}
                    key={index}
                    onClick={() => {
                      const newFiltersConfig = {...filtersConfig};
                      const findIndex = newFiltersConfig.week_day.findIndex((el) => el === item.id);
                      if (findIndex === -1) {
                        newFiltersConfig.week_day.push(item.id);
                      } else {
                        newFiltersConfig.week_day.splice(findIndex, 1);
                      }
                      setFiltersConfig(newFiltersConfig);
                      // sort(newFiltersConfig);
                    }}
                  >
                    {item.day}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={css.block}>
            <span className={css.title}>Этап</span>
            <div className={css.buttons}>
              <div
                className={classNames(
                  css.button,
                  filtersConfig?.is_reg === '' &&
                    filtersConfig?.is_first_dep === '' &&
                    filtersConfig?.is_rep_dep === '' &&
                    filtersConfig?.is_install === '' &&
                    css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_reg = '';
                  newFiltersConfig.is_fd = '';
                  newFiltersConfig.is_rd = '';
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Все
              </div>
              <div
                className={classNames(
                  css.button,
                  !!filtersConfig?.is_install && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_install = Number(!newFiltersConfig?.is_install);
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Установка
              </div>
              <div
                className={classNames(
                  css.button,
                  !!filtersConfig?.is_reg && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_reg = Number(!newFiltersConfig?.is_reg);
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Регистрация
              </div>
              <div
                className={classNames(
                  css.button,
                  !!filtersConfig?.is_first_dep && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_first_dep = Number(!newFiltersConfig?.is_first_dep);
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Первый депозит
              </div>
              <div
                className={classNames(
                  css.button,
                  !!filtersConfig?.is_rep_dep && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_rep_dep = Number(!newFiltersConfig?.is_rep_dep);
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Повторный депозит
              </div>
            </div>
          </div>
          <div className={css.block}>
            <span className={css.title}>Статус</span>
            <div className={css.buttons}>
              <div
                className={classNames(
                  css.button,
                  filtersConfig?.is_delete === '' && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_delete = '';
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Все
              </div>
              <div
                className={classNames(
                  css.button,
                  filtersConfig.is_delete === 0 && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_delete = 0;
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Активные
              </div>
              <div
                className={classNames(
                  css.button,
                  filtersConfig.is_delete === 1 && css._active,
                  mode === 'dark' && css._dark
                )}
                onClick={() => {
                  const newFiltersConfig = {...filtersConfig};
                  newFiltersConfig.is_delete = 1;
                  setFiltersConfig(newFiltersConfig);
                  // sort(newFiltersConfig);
                }}
              >
                Архивные
              </div>
            </div>
          </div>
          <div className={css.filters}>
            <div
              className={css.filter}
              onClick={() => {
                sort(filtersConfig);
                setShowCreateAppModal(false);
              }}
            >
              Применить фильтры
            </div>
            {(filtersConfig.is_install !== '' ||
              filtersConfig.is_reg !== '' ||
              filtersConfig.is_first_dep !== '' ||
              filtersConfig.is_rep_dep !== '' ||
              filtersConfig.week_day.length !== 0 ||
              filtersConfig.is_delete !== '' ||
              filtersConfig.is_delivered !== 0 ||
              filtersConfig.min_ctr !== '' ||
              filtersConfig.max_ctr !== '' ||
              filtersConfig.min_vtr !== '' ||
              filtersConfig.max_vtr !== '' ||
              filtersConfig.sort !== '') && (
              <div
                className={classNames(css.refresh, mode === 'dark' && css._dark)}
                onClick={() => {
                  sort({page: 1});
                  setPage(1);
                  setFiltersConfig({
                    is_install: '',
                    is_reg: '',
                    is_first_dep: '',
                    is_rep_dep: '',
                    week_day: [],
                    is_delete: '',
                    sort: '',
                    is_delivered: 0,
                    min_vtr: '',
                    max_vtr: '',
                    min_ctr: '',
                    max_ctr: '',
                  });
                }}
              >
                Сбросить фильтры
              </div>
            )}
          </div>
        </div>
      </CreateAppModal>
    </div>
  );
});

const PushModal = ({data, mode, days, pwaId}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <div
        className={classNames(css.buttonPush, mode === 'dark' && css._dark)}
        onClick={() => setShowCreateAppModal(true)}
      />
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y'
        show={showCreateAppModal}
        // showHeader={false}
        title={
          <>
            <div className={css.popupPush}>
              <div
                className={css.section_1}
                style={{
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0px',
                }}
              >
                <div className={css.header}>
                  <div className={css.id}>
                    <span style={{fontSize: '16px'}}>#{data?.id}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
      >
        <div className={css.popupPush}>
          <section className={classNames(css.section_1, mode === 'dark' && css._dark)}>
            <div className={css.header} style={{justifyContent: 'flex-end'}}>
              {/* <div className={css.id}>
                <span>#{data?.id}</span>
              </div> */}
              <div className={css.params}>
                <div className={css.create}>
                  <span className={classNames('badge badge-light-success', css.badge)}>
                    {moment.tz(data?.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY')}
                  </span>
                </div>
                <div className={css.status}>
                  {!data?.is_reg && !data?.is_fd && !data?.is_rd && (
                    <span className={classNames('badge badge-light-primary', css.badge)}>
                      Установка
                    </span>
                  )}
                  {!!data?.is_reg && !data?.is_fd && !data?.is_rd && (
                    <span className={classNames('badge badge-light-success', css.badge)}>
                      Регистрация
                    </span>
                  )}
                  {!!data?.is_reg && !!data?.is_fd && !data?.is_rd && (
                    <span className={classNames('badge badge-light-info', css.badge)}>
                      Первый депозит
                    </span>
                  )}
                  {!!data?.is_reg && !!data?.is_fd && !!data?.is_rd && (
                    <span className={classNames('badge badge-light-danger', css.badge)}>
                      Повторный депозит
                    </span>
                  )}
                </div>
                <div className={css.day}>
                  <span className={classNames('badge badge-light-warning', css.badge)}>
                    {days[data.week_day]} ({data.time})
                  </span>
                </div>
                {!!data?.is_delete && (
                  <div
                    className={css.create}
                    style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}
                  >
                    <span className={classNames('badge badge-light-secondary', css.badge)}>
                      {moment.tz(data?.delete_created * 1000, 'Europe/Kyiv').format('DD.MM.YYYY')}
                    </span>
                  </div>
                )}
                {/* <div className={css.isDel}>
                  {!!data?.is_delete && (
                    <span className={classNames('badge badge-light-secondary', css.badge)}>
                      Архив
                    </span>
                  )}
                  {!data?.is_delete && (
                    <span className={classNames('badge badge-light-success', css.badge)}>
                      Активно
                    </span>
                  )}
                </div> */}
              </div>
            </div>
            <div className={css.params}></div>
            <div className={classNames(css.titles, mode === 'dark' && css._dark)}>
              <div className={classNames(css.title, css._translate)}>{data?.title_ru}</div>
              <div className={classNames(css.title, 'text-gray-600')}>{data?.title}</div>
            </div>
            <div className={css.texts}>
              <div className={classNames(css.text, css._translate)}>{data?.text_ru}</div>
              <div className={classNames(css.text, 'text-gray-600')}>{data?.text}</div>
            </div>
          </section>
          <section className={css.section_2}>
            <Stat
              title={'DR'}
              num={`${formatNumber2(data?.dr_push)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='23'
                  fill='none'
                  viewBox='0 0 24 23'
                >
                  <path
                    fill='#9A71F7'
                    d='M21 4.667h-3.5V2.333A2.336 2.336 0 0 0 15.167 0h-7a2.336 2.336 0 0 0-2.334 2.333v2.334h-3.5A2.336 2.336 0 0 0 0 7v3.5h23.333V7A2.336 2.336 0 0 0 21 4.667ZM8.167 2.333h7v2.334h-7V2.333ZM14 14H9.333v-2.333H0v8.166a2.336 2.336 0 0 0 2.333 2.334H21a2.336 2.336 0 0 0 2.333-2.334v-8.166H14V14Z'
                  />
                </svg>
              }
            />
            <Stat
              title={'VTR'}
              num={`${formatNumber2(data?.vtr_push)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='18'
                  fill='none'
                  viewBox='0 0 25 18'
                >
                  <path
                    fill='#58A0F5'
                    d='M16.25 7.5H25V10h-8.75V7.5Zm1.25 5H25V15h-7.5v-2.5ZM15 2.5h10V5H15V2.5Zm-12.5 15H15v-1.25A6.257 6.257 0 0 0 8.75 10h-2.5A6.257 6.257 0 0 0 0 16.25v1.25h2.5Zm5-8.75c2.494 0 4.375-1.881 4.375-4.375C11.875 1.881 9.994 0 7.5 0 5.006 0 3.125 1.881 3.125 4.375c0 2.494 1.881 4.375 4.375 4.375Z'
                  />
                </svg>
              }
            />
            <Stat
              title={'CTR'}
              num={`${formatNumber2(data?.ctr_push)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='28'
                  fill='none'
                  viewBox='0 0 28 28'
                >
                  <g fill='#FF6D04'>
                    <path d='M21 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z' />
                    <path d='M15.167 7c0-.83.178-1.618.492-2.333H7A2.336 2.336 0 0 0 4.667 7v14A2.336 2.336 0 0 0 7 23.333h14A2.336 2.336 0 0 0 23.333 21v-8.659c-.735.323-1.53.49-2.333.492A5.833 5.833 0 0 1 15.167 7Z' />
                  </g>
                </svg>
              }
            />
          </section>
        </div>
        <section className={css.section_3}>
          <Link to={`/app/${pwaId}/push/${data.id}`} target='_blank' rel='noopener noreferrer'>
            <div className={css.button}>
              Полная информация <i className='ki-duotone ki-right' style={{color: 'white'}}></i>
            </div>
          </Link>
        </section>
      </CreateAppModal>
    </>
  );
};

const ToArchive = observer(({mode, id, sort, filtersConfig}) => {
  const store = useStore();
  const [warning, setWarning] = useState(false);

  const deletePush = async () => {
    const res = await store.resourcePWADeleteAppPush(id);
    sort(filtersConfig);
  };

  return (
    <>
      <div className='d-flex justify-content-end'>
        <div
          className={classNames(css.buttonPush, css.basket, mode === 'dark' && css._dark)}
          onClick={() => setWarning(true)}
        >
          <i className='ki-duotone ki-basket fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
          </i>
        </div>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text='Вы уверены что хотите архивировать Push уведомление?'
          buttons={[
            {
              action: () => {
                deletePush();
                setWarning(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

const PushUpdate = observer(({mode, data, id, sort, filtersConfigPushes}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const days2 = [
    {day: 'Понедельник', id: 1},
    {day: 'Вторник', id: 2},
    {day: 'Среда', id: 3},
    {day: 'Четверг', id: 4},
    {day: 'Пятница', id: 5},
    {day: 'Суббота', id: 6},
    {day: 'Воскресенье', id: 0},
  ];

  const [filtersConfig, setFiltersConfig] = useState({
    is_reg: 0,
    is_fd: 0,
    is_rd: 0,
    week_day: 0,
  });

  useEffect(() => {
    setFiltersConfig({
      id: data?.id,
      is_reg: data?.is_reg,
      is_fd: data?.is_fd,
      is_rd: data?.is_rd,
      week_day: data?.week_day,
      time: data?.time,
      text: data?.text,
      text_ru: data?.text_ru,
      title: data?.title,
      title_ru: data?.title_ru,
    });
  }, [data]);

  const createPush = async () => {
    const create = await store.PWAupdateAppPush({...filtersConfig});
    sort(filtersConfigPushes);
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div className='d-flex justify-content-end'>
        <div
          className={classNames(css.buttonPush, css.basket, mode === 'dark' && css._dark)}
          onClick={() => setShowCreateAppModal(true)}
        >
          <i className='ki-duotone ki-pencil fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        </div>
      </div>
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y'
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        title={'Редактирование Push'}
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column'}}>
            <span>Title</span>
            <input
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              value={filtersConfig.title || ''}
              onChange={(e) => setFiltersConfig({...filtersConfig, title: e.target.value})}
            />
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Title Ru</span>
            <input
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              value={filtersConfig.title_ru || ''}
              onChange={(e) => setFiltersConfig({...filtersConfig, title_ru: e.target.value})}
            ></input>
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Text</span>
            <textarea
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              value={filtersConfig.text || ''}
              onChange={(e) => setFiltersConfig({...filtersConfig, text: e.target.value})}
            ></textarea>
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Text Ru</span>
            <textarea
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              value={filtersConfig.text_ru || ''}
              onChange={(e) => setFiltersConfig({...filtersConfig, text_ru: e.target.value})}
            ></textarea>
          </label>
          <div className={css.popupFilters} style={{marginTop: '10px'}}>
            <div className={css.block}>
              <span className={css.title}>Этап</span>
              <div className={css.buttons}>
                <div
                  className={classNames(
                    css.button,
                    !filtersConfig?.is_reg &&
                      !filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 0;
                    newFiltersConfig.is_fd = 0;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Установка
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 0;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Регистрация
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !!filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 1;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Первый депозит
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !!filtersConfig?.is_fd &&
                      !!filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 1;
                    newFiltersConfig.is_rd = 1;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Повторный депозит
                </div>
              </div>
            </div>
            <div className={css.block}>
              <span className={css.title}>День</span>
              <div className={css.buttons}>
                {/* <div
                  className={classNames(
                    css.button,
                    filtersConfig.week_day.length === 0 && css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.week_day = days2.map((item) => item.id);
                    setFiltersConfig(newFiltersConfig);
                    // sort(newFiltersConfig);
                  }}
                >
                  Все
                </div> */}
                {days2.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        css.button,
                        filtersConfig.week_day === item.id && css._active,
                        mode === 'dark' && css._dark
                      )}
                      key={index}
                      onClick={() => {
                        setFiltersConfig({...filtersConfig, week_day: item.id});
                        // sort(newFiltersConfig);
                      }}
                    >
                      {item.day}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <span>Время</span>
            <IMaskInput
              className='form-control form-control-lg form-control-solid'
              mask='00:00'
              blocks={{
                HH: {
                  mask: IMask.MaskedRange,
                  from: 0,
                  to: 23,
                },
                MM: {
                  mask: IMask.MaskedRange,
                  from: 0,
                  to: 59,
                },
              }}
              placeholder='00:00'
              value={filtersConfig?.time || ''}
              onAccept={(value) => setFiltersConfig({...filtersConfig, time: value})}
            />
          </div>
          <div
            style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '20px'}}
          >
            <Button action={() => createPush()}>Сохранить</Button>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
});
const AddPush = observer(({id, sort, filtersConfigPushes}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const days2 = [
    {day: 'Понедельник', id: 1},
    {day: 'Вторник', id: 2},
    {day: 'Среда', id: 3},
    {day: 'Четверг', id: 4},
    {day: 'Пятница', id: 5},
    {day: 'Суббота', id: 6},
    {day: 'Воскресенье', id: 0},
  ];

  const [filtersConfig, setFiltersConfig] = useState({
    is_reg: 0,
    is_fd: 0,
    is_rd: 0,
    week_day: 0,
  });

  const createPush = async () => {
    const create = await store.PWAaddAppPush({...filtersConfig, app_id: Number(id)});
    sort(filtersConfigPushes);
    setShowCreateAppModal(false);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <Button action={() => setShowCreateAppModal(true)}>Добавить</Button>
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y'
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false);
        }}
        title={'Создание Push'}
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column'}}>
            <span>Title</span>
            <input
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              onChange={(e) => setFiltersConfig({...filtersConfig, title: e.target.value})}
            />
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Title Ru</span>
            <input
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              onChange={(e) => setFiltersConfig({...filtersConfig, title_ru: e.target.value})}
            ></input>
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Text</span>
            <textarea
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              onChange={(e) => setFiltersConfig({...filtersConfig, text: e.target.value})}
            ></textarea>
          </label>
          <label htmlFor='' style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <span>Text Ru</span>
            <textarea
              className='form-control form-control-solid w-100 '
              name=''
              id=''
              onChange={(e) => setFiltersConfig({...filtersConfig, text_ru: e.target.value})}
            ></textarea>
          </label>
          <div className={css.popupFilters} style={{marginTop: '10px'}}>
            <div className={css.block}>
              <span className={css.title}>Этап</span>
              <div className={css.buttons}>
                <div
                  className={classNames(
                    css.button,
                    !filtersConfig?.is_reg &&
                      !filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 0;
                    newFiltersConfig.is_fd = 0;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Установка
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 0;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Регистрация
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !!filtersConfig?.is_fd &&
                      !filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 1;
                    newFiltersConfig.is_rd = 0;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Первый депозит
                </div>
                <div
                  className={classNames(
                    css.button,
                    !!filtersConfig?.is_reg &&
                      !!filtersConfig?.is_fd &&
                      !!filtersConfig?.is_rd &&
                      css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.is_reg = 1;
                    newFiltersConfig.is_fd = 1;
                    newFiltersConfig.is_rd = 1;
                    setFiltersConfig(newFiltersConfig);
                  }}
                >
                  Повторный депозит
                </div>
              </div>
            </div>
            <div className={css.block}>
              <span className={css.title}>День</span>
              <div className={css.buttons}>
                {/* <div
                  className={classNames(
                    css.button,
                    filtersConfig.week_day.length === 0 && css._active,
                    mode === 'dark' && css._dark
                  )}
                  onClick={() => {
                    const newFiltersConfig = {...filtersConfig};
                    newFiltersConfig.week_day = days2.map((item) => item.id);
                    setFiltersConfig(newFiltersConfig);
                    // sort(newFiltersConfig);
                  }}
                >
                  Все
                </div> */}
                {days2.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        css.button,
                        filtersConfig.week_day === item.id && css._active,
                        mode === 'dark' && css._dark
                      )}
                      key={index}
                      onClick={() => {
                        setFiltersConfig({...filtersConfig, week_day: item.id});
                        // sort(newFiltersConfig);
                      }}
                    >
                      {item.day}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <span>Время</span>
            <IMaskInput
              className='form-control form-control-lg form-control-solid'
              mask='00:00'
              blocks={{
                HH: {
                  mask: IMask.MaskedRange,
                  from: 0,
                  to: 23,
                },
                MM: {
                  mask: IMask.MaskedRange,
                  from: 0,
                  to: 59,
                },
              }}
              placeholder='00:00'
              onAccept={(value) => setFiltersConfig({...filtersConfig, time: value})}
            />
          </div>
          <div
            style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '20px'}}
          >
            <Button action={() => createPush()}>Создать</Button>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
});

export default observer(Pwa);
