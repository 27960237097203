/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import css from '../../../../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useThemeMode} from '../../../../../../_metronic/partials';
import {toast} from 'react-toastify';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import Card from '../../../../../shared/Card';
import {Button} from '../../../../../shared/Buttons';
import {SelectorWithSearch} from '../../../../../shared/Selectors';

const Settings = ({
  settingsType,
  saveData,
  setSaveData,
  settings,
  dataForSelectors,
  validation,
  setSaveButtonState,
}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  const onCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <Card className='card'>
      <div className='card-header'>
        <div
          className='card-title fs-3 fw-bold'
          style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
        >
          <span>Настройки</span>{' '}
          <span
            className={`badge ${
              settingsType === 'telegram' ? 'badge-light-primary' : 'badge-light-info'
            }`}
          >
            {settingsType === 'telegram' ? 'телеграм' : 'приложение'}
          </span>
        </div>
      </div>
      <div
        className={classNames('card-body', css.form)}
        style={{
          '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
          '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
          '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
          '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
          '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
          '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
          '--buttonGrayBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
          '--slideSelectorBg':
            themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
          '--slideSelectorItemBg':
            themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
          '--buttonCommentBg':
            themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
        }}
      >
        <div className={css.blockLocalization}>
          <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
            <div style={{width: '100%'}}>
              <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                <span>Название</span>
                <input
                  type='text'
                  style={
                    validation.includes('name') ? {border: '2px solid red', outline: 'none'} : {}
                  }
                  value={saveData.name}
                  onChange={(e) => {
                    setSaveData((prev) => ({...prev, name: e.target.value}));
                    setSaveButtonState(1);
                  }}
                  disabled={
                    store.roles.includes(19) ||
                    store.roles.includes(20) ||
                    store.roles.includes(6) ||
                    store.roles.includes(7)
                      ? true
                      : false
                  }
                />
              </label>
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginBottom: '5px', display: 'inline-block'}}>Домен</span>
              <SelectorWithSearch
                value={dataForSelectors.domains.find((d) => d.value === saveData.domain_id)}
                options={dataForSelectors.domains}
                setValue={(e) => {
                  setSaveData((prev) => ({...prev, domain_id: e.value}));
                  setSaveButtonState(1);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    border: validation.includes('domain_id') && '2px solid red !important',
                    boxShadow: validation.includes('domain_id') && 'none',
                  }),
                }}
              />
            </div>
          </div>
          <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
            <div style={{width: '100%'}}>
              <span style={{marginBottom: '5px', display: 'inline-block'}}>Байер</span>
              <SelectorWithSearch
                value={dataForSelectors.bayers.find((b) => b.value === saveData.bayer_id)}
                options={dataForSelectors.bayers}
                setValue={(e) => {
                  setSaveData((prev) => ({...prev, bayer_id: e.value}));
                  setSaveButtonState(1);
                }}
                isDisabled={
                  store.roles.includes(19) ||
                  store.roles.includes(20) ||
                  store.roles.includes(6) ||
                  store.roles.includes(7)
                    ? true
                    : false
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    border: validation.includes('bayer_id') && '2px solid red !important',
                    boxShadow: validation.includes('bayer_id') && 'none',
                  }),
                }}
              />
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginBottom: '5px', display: 'inline-block'}}>Facebook Пиксель</span>
              <SelectorWithSearch
                value={dataForSelectors.pixels.find((p) => p.value === saveData.pixel_id)}
                options={dataForSelectors.pixels}
                setValue={(e) => {
                  setSaveData((prev) => ({...prev, pixel_id: e.value}));
                  setSaveButtonState(1);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    border: validation.includes('pixel_id') && '2px solid red !important',
                    boxShadow: validation.includes('pixel_id') && 'none',
                  }),
                }}
              />
            </div>
          </div>
          {settingsType !== 'pwa' && (
            <>
              <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Канал</span>
                    <input
                      className='text-gray-500'
                      type='text'
                      value={settings?.channel_name || ''}
                      disabled
                      onChange={() => {}}
                    />
                  </label>
                </div>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Кампания</span>
                    <input
                      className='text-gray-500'
                      type='text'
                      value={settings.traffic_name}
                      disabled
                      onChange={() => {}}
                    />
                  </label>
                </div>
              </div>
              <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Бот</span>
                    <input
                      className='text-gray-500'
                      type='text'
                      value={settings?.bot_name}
                      disabled
                      onChange={() => {}}
                    />
                  </label>
                </div>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Ссылка на канал</span>
                    <input
                      type='text'
                      value={settings?.channel_link || ''}
                      disabled
                      onChange={() => {}}
                      className='text-gray-500'
                    />
                    <div
                      onClick={() => onCopy(settings?.channel_link)}
                      role='button'
                      className={css.copy}
                    />
                  </label>
                </div>
              </div>
            </>
          )}
          {settingsType === 'pwa' && (
            <>
              <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Приложение</span>
                    <input
                      className='text-gray-500'
                      type='text'
                      value={settings.pwa_name || ''}
                      disabled
                      onChange={() => {}}
                    />
                  </label>
                </div>
                <div style={{width: '100%'}}>
                  <label htmlFor='' className={css.inputField} style={{marginBottom: '0'}}>
                    <span>Кампания</span>
                    <input
                      className='text-gray-500'
                      type='text'
                      value={settings.traffic_name || ''}
                      disabled
                      onChange={() => {}}
                    />
                  </label>
                </div>
              </div>
            </>
          )}
          <div className={css.doubleFields} style={{marginBottom: '16px', gap: '40px'}}>
            <div style={{width: '100%'}}>
              <span style={{marginBottom: '5px', display: 'inline-block'}}>Лендинг</span>
              <SelectorWithSearch
                value={dataForSelectors.landings.find((b) => b.value === saveData.landing_id)}
                options={dataForSelectors.landings}
                setValue={(e) => {
                  setSaveData((prev) => ({...prev, landing_id: e.value}));
                  setSaveButtonState(1);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    // border: validation.includes('bayer_id') && '2px solid red !important',
                    // boxShadow: validation.includes('bayer_id') && 'none',
                  }),
                }}
              />
            </div>
            <div style={{width: '100%'}}></div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default observer(Settings);
