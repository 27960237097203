export const columns = [
  'Информация',
  'Дата',
  'Рекламодатель',
  'Оффер',
  'Лендинг',
  'Конверсия',
  'Выплата',
  'Lead ID',
  'Байер',
  'Platform ID',
  'IP',
  'ID Конверсии',
];

export const postBackColumns = [
  'Результат',
  'Дата',
  'Рекламодатель',
  'Оффер',
  'Конверсия',
  'Lead ID',
  'Байер',
  'Platform ID',
  'IP',
  'ID Конверсии',
  'Адрес постбека',
  'Event ID',
];

export const defaultActiveColumns = [
  'Рекламодатель',
  'Лендинг',
  'Platform ID',
  'IP',
  'ID Конверсии',
];

export const defaultPostBackActiveColumns = ['Рекламодатель', 'Platform ID', 'IP', 'ID Конверсии'];

export const conversion = {
  click: 'Click',
  fd: 'Первый Депозит',
  rd: 'Повторный депозит',
  reg: 'Регистрация',
  cpa: 'Квалификация',
  l: 'Лид',
  cht: 'Чат',
};

export const postbackConversion = {
  reg: 'Регистрация',
  fd: 'Первый Депозит',
  rd: 'Повторный депозит',
  cpa: 'Квалификация',
};

export const sourceType = {
  app: <i className='bi bi-phone fs-3'></i>,
  telegram: <i className='bi bi-telegram fs-3'></i>,
  undefined: <i className='bi bi-exclamation-triangle-fill fs-3'></i>,
};

export const source = {
  influence: <i className='bi bi-camera-video fs-3'></i>,
  facebook: <i className='bi bi-facebook fs-3'></i>,
};

export const filtersGroup = [
  {
    label: 'Популярные',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'conversion_type', label: 'Конверсии'},
      {value: 'conversion_id', label: 'ID Конверсии', isSearch: true},
      {value: 'platform_id', label: 'Platform ID', isSearch: true},
      {value: 'fb_bayer_id', label: 'Байер'},
      {value: 'lead_id', label: 'Lead ID', isSearch: true},
    ],
  },
  {
    label: 'Рекламодатель',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'landing_id', label: 'Лендинг'},
      {value: 'brand_id', label: 'Бренд'},
    ],
  },
  {
    label: 'Фейсбук',
    options: [
      {value: 'fb_bayer_id', label: 'Байер'},
      {value: 'app_type', label: 'Типы приложений'},
      {value: 'traffic_type', label: 'Типы трафика'},
    ],
  },
  {
    label: 'Страна',
    options: [{value: 'country_id', label: 'Страна'}],
  },
];

export const filtersPostBackGroup = [
  {
    label: 'Популярные',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'conversion_type', label: 'Конверсии'},
      {value: 'conversion_id', label: 'ID Конверсии', isSearch: true},
      {value: 'platform_id', label: 'Platform ID', isSearch: true},
      {value: 'fb_bayer_id', label: 'Байер'},
      {value: 'lead_id', label: 'Lead ID', isSearch: true},
      {value: 'event_id', label: 'Event ID', isSearch: true},
    ],
  },
  {
    label: 'Рекламодатель',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'landing_id', label: 'Лендинг'},
      {value: 'brand_id', label: 'Бренд'},
    ],
  },
  {
    label: 'Фейсбук',
    options: [
      {value: 'fb_bayer_id', label: 'Байер'},
      {value: 'app_type', label: 'Типы приложений'},
      {value: 'traffic_type', label: 'Типы трафика'},
    ],
  },
  {
    label: 'Страна',
    options: [{value: 'country_id', label: 'Страна'}],
  },
];
