import React, {useEffect, useState} from 'react';
import PageWrapper from '../../../modules/PageWrapper';
import Card from '../../../shared/Card';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {CreateAppModal} from '../../../../_metronic/partials';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Warning from '../../../modules/Warning';
import {toast} from 'react-toastify';
import InputSearch from '../../../shared/InputSearch';
import userPlug from '../../../../_metronic/assets/media/user.png';

// const types = [
//   {value: 'ios', label: 'iOS'},
//   {value: 'android', label: 'Android'},
//   {value: 'pwa', label: 'PWA'},
//   {value: '', label: 'Не выбрано'},
// ];

const status = [
  {value: false, label: 'Не активный'},
  {value: true, label: 'Активный'},
];

const Services = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'Приложения'},
        {isSeparator: true},
        {title: 'Сервисы', path: '/app/services', isActive: true},
      ]}
      title='Сервисы'
    >
      <Component />
    </PageWrapper>
  );
};

const Component = ({setPreloader, isVerify, timeConfig}) => {
  const store = useStore();

  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [isActive, setIsActive] = useState(null);

  const getData = async () => {
    const getServices = await store.getServicesList(isActive === null ? undefined : +isActive);
    setServices(getServices);
    setFilteredServices(getServices);
    setPreloader(false);
  };

  useEffect(() => {
    if (!isVerify) return;
    getData();
  }, [isVerify]);

  useEffect(() => {
    const containersXL = document.querySelectorAll('.container-xxl');
    containersXL.forEach((el) => (el.style.maxWidth = '100%'));
    return () => containersXL.forEach((el) => (el.style.maxWidth = ''));
  }, []);

  return (
    <Card className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
          <div className='text-end ms-0 ms-sm-0 mb-5 mb-sm-5 mb-md-0 position-relative'>
            <InputSearch
              title={'Поиск...'}
              array={services}
              newArray={setFilteredServices}
              searchParameters={['name']}
              // onChangeValue={setSearchValue}
            />
          </div>
          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
            <button
              type='button'
              className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Статус
              <i className='ki-duotone ki-down fs-5 ms-1'></i>
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-4'
              data-kt-menu='true'
            >
              {status.map((t) => (
                <div className='menu-item px-3'>
                  <span className='menu-link px-3' onClick={() => setIsActive(t.value)}>
                    {t.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {store.roles.includes(38) || store.roles.includes(6) ? (
          <></>
        ) : (
          <div className='card-toolbar'>
            <Link to={'/app/services/addService'}>
              <Button>
                <>
                  <i className='ki-duotone ki-plus fs-2'></i>Добавить
                </>
              </Button>
            </Link>
          </div>
        )}
      </div>

      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px sorting'>Название</th>
                  <th className='min-w-125px sorting'>PWA</th>
                  <th className='min-w-125px sorting'>Android</th>
                  <th className='min-w-125px sorting'>iOS</th>
                  <th className='min-w-125px sorting'>Статус</th>
                  {store.roles.includes(38) || store.roles.includes(6) ? (
                    <></>
                  ) : (
                    <th className='text-end min-w-100px sorting_disabled'>Действие</th>
                  )}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {filteredServices
                  .filter((item) => {
                    if (isActive === null) {
                      return item;
                    } else if (item.is_active === isActive) {
                      return item;
                    }
                  })
                  .map((el) => {
                    return (
                      <tr className='odd' key={el.id}>
                        <td className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 text-hover-primary mb-1 d-flex'>
                              {el.name}
                            </span>
                            <span>#{el.id}</span>
                          </div>
                        </td>
                        <td>
                          {el.is_pwa ? (
                            <div className='badge badge-light-success fw-bold'>Да</div>
                          ) : (
                            <div className='badge badge-light-danger fw-bold'>Нет</div>
                          )}
                        </td>
                        <td>
                          {el.is_android ? (
                            <div className='badge badge-light-success fw-bold'>Да</div>
                          ) : (
                            <div className='badge badge-light-danger fw-bold'>Нет</div>
                          )}
                        </td>
                        <td>
                          {el.is_ios ? (
                            <div className='badge badge-light-success fw-bold'>Да</div>
                          ) : (
                            <div className='badge badge-light-danger fw-bold'>Нет</div>
                          )}
                        </td>
                        <td>
                          {el.is_active ? (
                            <div className='badge badge-light-success fw-bold'>Активный</div>
                          ) : (
                            <div className='badge badge-light-danger fw-bold'>Не активный</div>
                          )}
                        </td>
                        {store.roles.includes(38) || store.roles.includes(6) ? (
                          <></>
                        ) : (
                          <td className='text-end'>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <>
                                <Link to={`/app/services/updateService/${el.id}`}>
                                  <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                    <i className='ki-duotone ki-pencil fs-2'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  </span>
                                </Link>

                                {el?.app_count === 0 && (
                                  <Delete
                                    getData={getData}
                                    fullName={el.fullName}
                                    id={el.id}
                                    status={el.is_ban}
                                    searchUsers={services}
                                    setSearchUsers={setFilteredServices}
                                    setUsers={setServices}
                                  />
                                )}
                              </>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {/* {localPreloader && (
            <Preloader style={{border: '0', minHeight: '70vh', marginTop: '8%'}} />
          )} */}
        </div>
      </div>
    </Card>
  );
};

const Delete = observer(
  ({fullName, id, status, setSearchUsers, searchUsers, setUsers, getData}) => {
    const store = useStore();
    const [showCreateAppModal, setShowCreateAppModal] = useState(false);

    const banUser = async () => {
      const res = await store.deleteService(id);

      if (!res) {
        return toast.error('Произошла ошибка в процесе удаления сервиса!', {
          onClose: () => store.signOut(),
          theme: 'colored',
        });
      }

      const newSearchUsers = [...searchUsers].map((el) => {
        if (el.id === id) {
          el.is_ban = !status;
        }
        return el;
      });

      setSearchUsers(newSearchUsers);
      setUsers(newSearchUsers);
      getData();
      toast.success(`Сервис успешно удален`, {});
      setShowCreateAppModal(false);
    };

    return (
      <>
        <span
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => setShowCreateAppModal(true)}
        >
          {!status && (
            <i className='ki-duotone ki-trash fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
              <span className='path4'></span>
              <span className='path5'></span>
            </i>
          )}
          {!!status && (
            <i className='ki-duotone ki-basket-ok fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
              <span className='path4'></span>
            </i>
          )}
        </span>
        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            text={
              <>
                Вы уверены, что хотите <span className='fw-bolder text-gray-700'>удалить</span>,{' '}
                <span className='text-primary'>{fullName}</span> сервис?
              </>
            }
            icon={
              <span className='symbol-label'>
                <i className='bi bi-question-circle fs-5x text-warning'></i>
              </span>
            }
            buttons={[
              {
                action: () => {
                  setShowCreateAppModal(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: async () => {
                  banUser();
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </>
    );
  }
);

export default Services;
