/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import userPlug from '../../../../_metronic/assets/media/user.png';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';
import Card from '../../../shared/Card';
import PaginationBe from '../../../shared/PaginationBe';
import Preloader from '../../../modules/Preloader';

const List = ({isVerify, setPreloader}) => {
  const store = useStore();
  const [users, setUsers] = useState([]);
  const searchDebounceRef = useRef(null);
  const [queryText, setQueryText] = useState();
  const [params, setParams] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [access, setAccess] = useState([]);
  const [isBan, setIsBan] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [localPreloader, setLocalPreloader] = useState(false);

  const [departmentsFilter, setDepartmentsFilter] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [usersList, departmentsList, accessList] = await Promise.all([
      store.getEmployees(),
      store.departamentsGetList(),
      store.usersGetAllAccess(),
    ]);

    setAccess(accessList);
    const newUserList = [];
    for (let i = 0; i < usersList.rows.length; i++) {
      let departments = departmentsList.find(
        (department) => department.id === usersList.rows[i].departament_id
      );
      let accesses = accessList.roles.find((access) => access.id === usersList.rows[i].role_id);
      newUserList.push({
        ...usersList.rows[i],
        fullName: `${usersList.rows[i].first_name} ${usersList.rows[i].last_name}`,
        departments: departments || {},
        access: accesses || {},
      });
    }

    setDepartmentsFilter(departmentsList);
    setUsers(newUserList);
    setTotal(usersList.total);
    setPreloader(false);
  };

  const updateUsers = async (filters) => {
    setLocalPreloader(true);
    const result = await store.getEmployees(filters);
    const newUserList = [];
    for (let i = 0; i < result.rows.length; i++) {
      let departments = departmentsFilter.find(
        (department) => department.id === result.rows[i].departament_id
      );
      let accesses = access.roles.find((access) => access.id === result.rows[i].role_id);
      newUserList.push({
        ...result.rows[i],
        fullName: `${result.rows[i].first_name} ${result.rows[i].last_name}`,
        departments: departments || {},
        access: accesses || {},
      });
    }
    setUsers(newUserList);
    setTotal(result.total);
    setLocalPreloader(false);
  };

  const createParams = async (field, value) => {
    setPage(1);
    const newParams = {...params, page: 1, [field]: value};
    setParams(newParams);
    let queryStr = '';

    for (let [key, value] of Object.entries(newParams)) {
      if (value !== undefined) {
        queryStr += `${key}=${value}&`;
      }
    }
    queryStr = queryStr.slice(0, -1);

    updateUsers(queryStr);
  };

  const onSearch = (e) => {
    const query = e.target.value;

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    setQueryText(query);
    searchDebounceRef.current = setTimeout(() => {
      createParams('q', query);
    }, 1000);
  };

  const onPaginationChange = (page) => {
    const cleanedParams = Object.fromEntries(
      Object.entries({...params, page}).filter(([_, value]) => value !== undefined)
    );

    setParams(cleanedParams);

    const queryStr = new URLSearchParams(cleanedParams).toString();

    updateUsers(queryStr);
  };

  return (
    <Card className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title d-flex flex-sm-column flex-column flex-md-row'>
          <div className='text-end ms-0 ms-sm-0 mb-5 mb-sm-5 mb-md-0 position-relative'>
            <i
              className='ki-duotone ki-magnifier fs-3 position-absolute'
              style={{left: '15px', top: '13px'}}
            >
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-13'
              placeholder='Поиск'
              value={queryText}
              onChange={onSearch}
            />
          </div>
          <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
            <button
              type='button'
              className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {isBan ? isBan : 'Статус'} <i className='ki-duotone ki-down fs-5 ms-1'></i>
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  onClick={() => {
                    setIsBan('Активный');
                    createParams('is_ban', 0);
                  }}
                >
                  Активный
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    setIsBan('Не активный');
                    createParams('is_ban', 1);
                  }}
                >
                  Не активный
                </span>
              </div>
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => {
                    setIsBan(undefined);
                    createParams('is_ban');
                  }}
                >
                  Все статусы
                </span>
              </div>
            </div>
          </div>
          {!store.roles.includes(11) && (
            <div className='text-end ms-0 ms-sm-0 ms-md-5 mb-5 mb-sm-5 mb-md-0'>
              <button
                type='button'
                className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {selectedDepartment ? selectedDepartment : 'Департамент'}{' '}
                <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      setSelectedDepartment(undefined);
                      createParams('departament_id');
                    }}
                  >
                    Все департаменты
                  </span>
                </div>
                {departmentsFilter.map((el) => {
                  return (
                    <div className='menu-item px-3' key={el.name}>
                      <span
                        className='menu-link px-3'
                        onClick={() => {
                          setSelectedDepartment(el.name);
                          createParams('departament_id', el.id);
                        }}
                      >
                        {el.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className='card-toolbar'>
          {store.roles.includes(13) || store.roles.includes(32) || store.roles.includes(39) ? (
            <></>
          ) : (
            <Link to={'/users/list/addUser'}>
              <Button>
                <>
                  <i className='ki-duotone ki-plus fs-2'></i>Добавить
                </>
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px sorting' style={{width: '275.953px'}}>
                    Сотрудник
                  </th>
                  <th className='min-w-125px sorting' style={{width: '275.953px'}}>
                    Департамент
                  </th>
                  <th className='min-w-125px sorting' style={{width: '125px'}}>
                    Баланс
                  </th>
                  <th className='min-w-125px sorting' style={{width: '161.531px'}}>
                    Статус
                  </th>
                  <th
                    className='text-end min-w-100px sorting_disabled'
                    style={{width: '132.234px'}}
                  >
                    Действие
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {users.map((el) => {
                  return (
                    <tr className='odd' key={el.id}>
                      <td className='d-flex align-items-center'>
                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                          <span>
                            <div className='symbol-label'>
                              <img
                                src={
                                  !!el?.avatar
                                    ? `${process.env.REACT_APP_API_HOST}/${el.avatar}`
                                    : userPlug
                                }
                                alt='#'
                                className='w-100'
                              />
                            </div>
                          </span>
                        </div>

                        <div className='d-flex flex-column'>
                          <span className='text-gray-800 text-hover-primary mb-1 d-flex'>
                            {el.first_name} {el.last_name}
                            {!!el?.is_verified && (
                              <div
                                className='symbol symbol-35px symbol-circle'
                                data-bs-toggle='tooltip'
                                title={
                                  !!el?.verify_date
                                    ? moment
                                        .tz(el?.verify_date * 1000, 'Europe/Kyiv')
                                        .format('DD.MM.YYYY HH:mm')
                                    : 'Time not set'
                                }
                              >
                                <span>
                                  <i className='ki-duotone ki-verify fs-1 text-primary'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </span>
                              </div>
                            )}
                          </span>
                          <span>#{el.id}</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <span>
                              <div className='symbol-label'>
                                <img
                                  src={
                                    !!el?.departments?.avatar
                                      ? `${process.env.REACT_APP_API_HOST}/${el?.departments?.avatar}`
                                      : userPlug
                                  }
                                  alt='#'
                                  className='w-100'
                                />
                              </div>
                            </span>
                          </div>

                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 text-hover-primary mb-1'>
                              {el?.departments?.name}
                            </span>
                            <span>{el?.access?.name}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='badge badge-light fw-bold'>${el.balance}</div>
                      </td>
                      <td>
                        {!!el.is_ban && (
                          <div className='badge badge-light-danger fw-bold'>Не активный</div>
                        )}
                        {!el.is_ban && (
                          <div className='badge badge-light-success fw-bold'>Активный</div>
                        )}
                      </td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link to={`/users/list/user/${el.id}/info`}>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <i className='ki-duotone ki-switch fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </span>
                          </Link>
                          {store.roles.includes(13) || store.roles.includes(32) ? (
                            <></>
                          ) : (
                            <>
                              <Link to={`/users/list/user/${el.id}/settings`}>
                                <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                  <i className='ki-duotone ki-pencil fs-2'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </span>
                              </Link>

                              <Delete
                                fullName={el.fullName}
                                id={el.id}
                                status={el.is_ban}
                                searchUsers={users}
                                setSearchUsers={setUsers}
                                setUsers={setUsers}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {localPreloader && (
            <Preloader style={{border: '0', minHeight: '70vh', marginTop: '8%'}} />
          )}
          {users.length > 0 && (
            <PaginationBe
              arrLength={total}
              limit={10}
              page={page}
              setPage={setPage}
              action={(currentPage) => {
                onPaginationChange(currentPage);
              }}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const Delete = observer(({fullName, id, status, setSearchUsers, searchUsers, setUsers}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const banUser = async () => {
    const res = await store.usersBanUser(id, !status);

    if (!res) {
      return toast.error('Произошла ошибка в процесе деактивации пользователя!', {
        onClose: () => store.signOut(),
        theme: 'colored',
      });
    }

    const newSearchUsers = [...searchUsers].map((el) => {
      if (el.id === id) {
        el.is_ban = !status;
      }
      return el;
    });

    setSearchUsers(newSearchUsers);
    setUsers(newSearchUsers);

    toast.success(`Пользователь успешно ${!status ? 'деактивирован!' : 'активирован!'}`, {});
    setShowCreateAppModal(false);
  };

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        onClick={() => setShowCreateAppModal(true)}
      >
        {!status && (
          <i className='ki-duotone ki-trash fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        )}
        {!!status && (
          <i className='ki-duotone ki-basket-ok fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
          </i>
        )}
      </span>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              Вы уверены, что хотите{' '}
              <span className='fw-bolder text-gray-700'>
                {!status ? 'деактивировать!' : 'активировать!'}`
              </span>
              , <span className='text-primary'>{fullName}</span> учетную запись?
            </>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                banUser();
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default observer(List);
