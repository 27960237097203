/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import moment from 'moment';

const Chats = ({data}) => {
  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Чаты</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px'>ID</th>
                  <th className='min-w-320px' style={{minWidth: '150px'}}>
                    Ответственный
                  </th>
                  <th className='min-w-320px' style={{minWidth: '150px'}}>
                    Контакт
                  </th>
                  <th className='min-w-125px' style={{minWidth: '150px'}}>
                    Work Space
                  </th>
                  <th className='min-w-100px text-center' style={{minWidth: '150px'}}></th>
                  <th className='min-w-100px' style={{minWidth: '150px'}}></th>
                </tr>
              </thead>

              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr key={`${index}-${item?.id}`}>
                      <td className='ps-4'>
                        <span className='text-gray-700 fw-bold d-block mb-1 fs-6'>#{item?.id}</span>
                      </td>
                      <td className='text-gray-700 fw-bold mb-1 fs-6'>{`${item.user_fname} ${item.user_lname} #${item.user_id}`}</td>

                      <td className='text-gray-700 fw-bold mb-1 fs-6'>{`@${item.tg_account_username} #${item.tg_account_id}`}</td>
                      <td className='text-gray-700 fw-bold mb-1 fs-6'>{`${item.workspace_name} #${item.workspace_id}`}</td>
                      <td className='text-gray-700 fw-bold mb-1 fs-6 text-center'>
                        {item.is_arhive && (
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            placement='top'
                            overlay={(props) => (
                              <Tooltip className='fs-8' {...props}>
                                <>{moment.tz(item?.arhive_created * 1000, 'Europe/Kyiv').format('DD.MM.YYYY HH:mm')}</>
                              </Tooltip>
                            )}
                          >
                            <span className={`badge badge-light-success me-auto cursor-pointer`}>
                              Архив
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className='text-gray-700 fw-bold mb-1 fs-6 d-flex justify-content-end'>
                        <a href={`${item.href}`} target='_blank'>
                          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <i className='ki-duotone ki-switch fs-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          </span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chats;
