import {api} from './api';

const salesAPI = {
  async RedistributeLtc(token, id) {
    try {
      const response = await api.get(
        `Redistribute/${id}/redistribute?token=${token}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async RedistributeRd(token, id) {
    try {
      const response = await api.get(
        `Redistribute/${id}/redistributeRd?token=${token}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsLtcMainStats(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Teams/Ltc/mainStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsRdMainStats(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Teams/Rd/mainStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsLtcGetManagersStats(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Teams/Ltc/getManagersStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsRdGetManagersStats(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Teams/Rd/getManagersStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesGetKpiLtcList(token) {
    try {
      const response = await api.get(`Sales/getKpiLtcList?token=${token}`);

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },
  async salesTeamsLtcGetTeams(token, time_config, filter = '') {
    try {
      const response = await api.get(
        `Sales/Teams/Ltc/getTeams?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}${filter}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsLtcGetTop(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Teams/Ltc/getTop?token=${token}&time_config=${time_config?.time_config}&time_start=${time_config?.time_start}&time_end=${time_config?.time_end}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesTeamsRdGetTeams(token, time_config, filter = '') {
    try {
      const response = await api.get(
        `Sales/Teams/Rd/getTeams?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}${filter}`
      );

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesGetKpiRdList(token) {
    try {
      const response = await api.get(`Sales/getKpiRdList?token=${token}`);

      if (!!response?.data?.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Основной сервер не отвечае', error);
      return null;
    }
  },

  async salesGetLtcManagersList(token) {
    try {
      const response = await api.get(`Sales/getLtcManagersList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getRdManagersList(token) {
    try {
      const response = await api.get(`Sales/getRdManagersList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsGetList(token, type) {
    try {
      const response = await api.get(`Sales/Teams/getList?token=${token}&type=${type}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsAddTeam(data) {
    try {
      const response = await api.post(`Sales/Teams/addTeam`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsUpdateTeam(data) {
    try {
      const response = await api.post(`Sales/Teams/updateTeam`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesGetLtcStats(token, time_config, filter = '') {
    try {
      const response = await api.get(
        `Sales/getLtcStats?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}${filter}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesGetRdStats(token, time_config, filter = '') {
    try {
      const response = await api.get(
        `Sales/getRdStats?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}${filter}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesLtcGetManagersStats(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Ltc/getManagersStats?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdGetManagersStats(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Rd/getManagersStats?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerRdMainStats(token, id, time_config) {
    try {
      const response = await api.get(
        `Sales/Manager/Rd/mainStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async salesManagerLtcMainStats(token, id, time_config) {
    try {
      const response = await api.get(
        `Sales/Manager/Ltc/mainStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async salesManagerLtcGeoQualifications(token, id, time_config) {
    try {
      const response = await api.get(
        `Sales/Manager/Ltc/geoQualifications?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerLtcQualificationSchedule(token, id) {
    try {
      const response = await api.get(
        `Sales/Manager/Ltc/qualificationSchedule?token=${token}&id=${id}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerRdDepositsSchedule(token, id) {
    try {
      const response = await api.get(`Sales/Manager/Rd/depositsSchedule?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesLtcManagersGetTop(token, time_config, subtype) {
    let queryString =`?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
    if(subtype) {
      queryString = `?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}&subtype=${subtype}`
    }
    try {
      const response = await api.get(
        `Sales/Ltc/Managers/getTop${queryString}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdManagersGetTop(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Rd/Managers/getTop?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdAnalysisGetMainStats(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Sales/Rd/Analysis/getMainStats${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdAnalysisGetSoft(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Sales/Rd/Analysis/getSoft${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdAnalysisGetMainStatsSoft(token, data) {
    try {
      const response = await api.get(
        `Sales/Rd/Analysis/getMainStatsSoft?token=${token}&time_start_activations=${data.qualificationsStart}&time_end_activations=${data.qualificationsEnd}&time_start_deposits=${data.depositsStart}&time_end_deposits=${data.depositsEnd}&advertiser_id=${data.advertiser}&rd_team_id=${data.team}&rd_manager_id=${data.manager}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesLtcGetLeadActivity(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Ltc/getLeadActivity?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async salesTeamsRdGetLeadsMetadata(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Teams/Rd/getLeadsMetadata?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async salesManagerRdGetLeadsMetadata(token, time_config, id) {
    try {
      const response = await api.get(
        `Sales/Manager/Rd/getLeadsMetadata?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdLeadsGetLeadsMetadata(token, time_config) {
    try {
      const response = await api.get(
        `Sales/Rd/Leads/getLeadsMetadata?token=${token}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsRdGetLeads(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Sales/Teams/Rd/getLeads${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerRdGetLeads(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Manager/Rd/getLeads${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerLtcGetStages(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Manager/Ltc/getStages${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerRdGetStages(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Manager/Rd/getStages${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsRdGetStages(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Teams/Rd/getStages${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesTeamsLtcGetStages(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Teams/Ltc/getStages${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesRdLeadsGetLeads(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/Rd/Leads/getLeads${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesGetManagersTop(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/getManagersTop${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  
  async salesMetricStats(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/metricStats${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesGetAiStats(data) {
    let queryStr = '';
    for (let [key, value] of Object.entries(data)) {
      // if (key === 'token') {
      //   queryStr += `?${key}=${value}`;
      //   continue;
      // }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      } else {
        continue;
      }
      // queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Sales/getAiStats?token=${data?.token}${queryStr}${data?.filter}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerUpdateTraffic(data) {
    try {
      const response = await api.post(`Sales/Manager/updateTraffic`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async salesManagerUpdateDayoff(data) {
    try {
      const response = await api.post(`Sales/Manager/updateDayoff`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default salesAPI;
