/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useRef, useEffect, useState} from 'react';
import css from '../../styles/Inputs.module.scss';
import '../../styles/Selectors.module.scss';
import classNames from 'classnames';
import ReactSelect, {components, SingleValue, Option, MultiValueRemoveProps} from 'react-select';
import {useThemeMode} from '../../_metronic/partials';

const CustomOption = (props) => {
  const {data, innerRef, innerProps} = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className='hoverOptionSelector'
      style={{display: 'flex', alignItems: 'center', padding: '10px'}}
    >
      {data?.label && (
        <img
          src={data.flag}
          alt={data.label}
          style={{width: '20px', height: '15px', marginRight: '10px'}}
        />
      )}
      {data.label}
    </div>
  );
};

// Кастомний компонент для вибраного значення
const CustomSingleValue = ({data}) => (
  <div
    className='react-select__single-value css-1dimb5e-singleValue'
    style={{position: 'absolute', paddingLeft: '10px', display: "flex", alignItems: "center"}}
  >
    <img
      src={data.flag}
      alt={data.label}
      style={{width: '20px', height: '15px', marginRight: '10px'}}
    />

    {data.label}
  </div>
);

const CustomMultiValue = (props) => {
  const {data, removeProps} = props;
  return (
    <div
      className={classNames('react-select__multi-value', css.countryMultiValue)}
      style={{display: 'flex', alignItems: 'center'}}
    >
      <div style={{padding: '3px 3px 3px 6px'}}>
        <img src={data.flag} alt={data.label} style={{width: '15px', height: '10px'}} />
        <span className={classNames('react-select__multi-value__label', css.label)}>
          {data.label}
        </span>
      </div>
      <div className={classNames(css.remove)} onClick={removeProps.onClick}>
        <svg
          height='14'
          width='14'
          viewBox='0 0 20 20'
          aria-hidden='true'
          focusable='false'
          className='css-tj5bde-Svg'
        >
          <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
        </svg>
      </div>
    </div>
  );
};

const SelectorWithCounties = ({
  styles = {},
  placeholder = 'Поиск...',
  options,
  value,
  setValue,
  actions,
  clearable = false,
  isDisabled = false,
  isMulti = false,
}) => {
  const selectRef = useRef();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');

  useEffect(() => {
    if (!value || !Object.keys(value).length) {
      selectRef.current.inputRef.placeholder = placeholder;
    } else {
      selectRef.current.inputRef.placeholder = '';
    }
  }, [value]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <ReactSelect
      className='react-select__container'
      classNamePrefix='react-select'
      ref={selectRef}
      placeholder={placeholder}
      isClearable={clearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      styles={{
        ...styles,
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...styles,
            backgroundColor: isFocused && '#1B84FF',
            color: isFocused && 'white',
          };
        },
      }}
      components={{
        SingleValue: CustomSingleValue,
        MultiValue: CustomMultiValue,
        Option: CustomOption,
      }}
      isSearchable={true}
      options={options}
      value={value}
      onChange={(e) => {
        !!setValue && setValue(e);
        !!actions && actions(e);
      }}
    />
  );
};

const SelectorWithSubtitle = ({
  placeholder,
  handleChange,
  value = '',
  text = '',
  classText = '',
  name,
  style = {},
  clearable = false,
  styles = {},
  setValue,
  actions,
  options,
}) => {
  const [textSize, setTextSize] = useState({width: 0, height: 0});
  const selectRef = useRef();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');

  useEffect(() => {
    if (!value || !Object.keys(value).length) {
      selectRef.current.inputRef.placeholder = placeholder;
    } else {
      selectRef.current.inputRef.placeholder = '';
    }
  }, [value]);

  useEffect(() => {
    if (text?.length > 0) {
      const element = document.querySelector(`.${classText}`);

      setTextSize({width: element?.offsetWidth, height: element?.offsetHeight});
    }
  }, [text]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <label className={css.label} style={{}}>
        {text?.length > 0 && (
          <div
            className={css.signature}
            style={{width: `${textSize?.width}px`, height: `${textSize?.height}px`, zIndex: '1'}}
          >
            <span className={`${classText}`}>{text}</span>
          </div>
        )}
        <ReactSelect
          className='react-select__container'
          classNamePrefix='react-select'
          ref={selectRef}
          placeholder={placeholder}
          isClearable={clearable}
          styles={{
            ...styles,
            zIndex: '2',
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
              return {
                ...styles,
                backgroundColor: isFocused && '#1B84FF',
                color: isFocused && 'white',
                zIndex: '1',
              };
            },
            menu: (styles, {data, isDisabled, isFocused, isSelected}) => {
              return {
                ...styles,
                zIndex: 2,
                backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
              };
            },
            control: (styles, {data, isDisabled, isFocused, isSelected}) => {
              return {
                ...styles,
                height: '43px !important',
                borderRadius: '0.475rem !important',
                backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
                border:
                  mode === 'dark' ? '1px solid #99A1B7 !important' : '1px solid #99A1B7 !important',
              };
            },
          }}
          isSearchable={true}
          options={options}
          value={value}
          onChange={(e) => {
            !!setValue && setValue(e);
            !!actions && actions(e);
          }}
        />
      </label>
    </>
  );
};

const SelectorWithSearch = ({
  styles = {},
  placeholder = 'Поиск...',
  options,
  value,
  setValue,
  actions,
  clearable = false,
  isDisabled = false,
}) => {
  const selectRef = useRef();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');

  useEffect(() => {
    if (!value || !Object.keys(value).length) {
      selectRef.current.inputRef.placeholder = placeholder;
    } else {
      selectRef.current.inputRef.placeholder = '';
    }
  }, [value]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <ReactSelect
      className='react-select__container'
      classNamePrefix='react-select'
      ref={selectRef}
      placeholder={placeholder}
      isClearable={clearable}
      isDisabled={isDisabled}
      styles={{
        ...styles,
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...styles,
            backgroundColor: isFocused && '#1B84FF',
            color: isFocused && 'white',
          };
        },
        // menu: (styles, {data, isDisabled, isFocused, isSelected}) => {
        //   return {
        //     ...styles,
        //     backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
        //   };
        // },
        // control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        //   return {
        //     ...styles,
        //     backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
        //   };
        // },
      }}
      isSearchable={true}
      options={options}
      value={value}
      onChange={(e) => {
        !!setValue && setValue(e);
        !!actions && actions(e);
      }}
    />
  );
};

const Selector = ({
  styles = {},
  placeholder = 'Поиск...',
  options,
  value,
  defaultValue,
  onChange,
}) => {
  return (
    <ReactSelect
      className='react-select__container'
      classNamePrefix='react-select'
      placeholder={placeholder || 'Поиск...'}
      styles={{
        ...styles,
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...styles,
            backgroundColor: isFocused && '#1B84FF',
            color: isFocused && 'white',
          };
        },
      }}
      isSearchable={true}
      options={options}
      value={options.find((c) => {
        return c.value === value;
      })}
      defaultValue={defaultValue}
      onChange={(val) => onChange(val.value)}
    />
  );
};

const SelectorIsMulti = ({
  styles = {},
  placeholder = 'Поиск...',
  options,
  value,
  defaultValue,
  onChange,
}) => {
  return (
    <ReactSelect
      className='react-select__container'
      classNamePrefix='react-select'
      placeholder={placeholder || 'Поиск...'}
      styles={{
        ...styles,
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...styles,
            backgroundColor: isFocused && '#1B84FF',
            color: isFocused && 'white',
          };
        },
      }}
      isMulti
      isSearchable={true}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={(val, i) => onChange(val)}
    />
  );
};

export {SelectorWithSearch, Selector, SelectorIsMulti, SelectorWithSubtitle, SelectorWithCounties};
