/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState, useRef} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import BlockLine from './templates/BlockLine';
import BlockTitles from './templates/BlockTitles';
import {SelectorWithSearch, SelectorWithCounties} from '../../shared/Selectors';
import {CreateAppModal} from '../../../_metronic/partials';
import {useThemeMode} from '../../../_metronic/partials';
import Warning from '../../modules/Warning';
import Navigation from '../../modules/NavigationBloggers';

const LandingForm = ({
  advertisersOptions,
  offers,
  countries,
  landings,
  dataFt,
  setDataFt,
  dataBc,
  setDataBc,
  setStep,
  saveOffers,
  errors,
  errorsGeo,
}) => {
  const store = useStore();
  const [offersOptions, setOffersOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [landingsOptions, setLandingsOptions] = useState([]);

  // const [dataFt, setDataFt] = useState({});
  // const [dataBc, setDataBc] = useState({});

  // const selectRef = useRef(null);

  const [advertiser_id, setAdvertiser_id] = useState(null);
  const [offer_id, setOffer_id] = useState(null);
  const [offer_landing_id, setOffer_landing_id] = useState(null);

  // useEffect(() => {
  //   console.log(dataBc);
  // }, [dataBc]);

  const addMulti = () => {
    setDataFt({
      ...dataFt,
      country_offers: !!dataFt?.country_offers
        ? [
            ...dataFt?.country_offers,
            {
              country_id: null,
              offer_landing_id: null,
              split_enabled: false,
              apps_split: [],
            },
          ]
        : [
            {
              country_id: null,
              offer_landing_id: null,
              split_enabled: false,
              apps_split: [],
            },
          ],
    });
    setDataBc({
      ...dataBc,
      country_offers: !!dataBc?.country_offers
        ? [
            ...dataBc?.country_offers,
            {
              country_id: null,
              offer_landing_id: null,
              split_enabled: false,
              apps_split: [],
            },
          ]
        : [
            {
              country_id: null,
              offer_landing_id: null,
              split_enabled: false,
              apps_split: [],
            },
          ],
    });
  };

  useEffect(() => {
    if (!dataFt?.offer_landing_id) return;
    const findLanding = landings.find((item) => item.id === dataFt?.offer_landing_id);
    const findOffer = offers.find((item) => item.id === findLanding.offer_id);
    const findAdvertiser = advertisersOptions.find(
      (item) => item.value === findOffer.advertiser_id
    );

    const filterOffers = offers.filter((item) => item.advertiser_id === findOffer.advertiser_id);
    const filterLandings = landings.filter((item) => item.offer_id === findOffer.id);

    setLandingsOptions(
      filterLandings.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );
    setOffersOptions(
      filterOffers.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );

    // const newDataFt = JSON.parse(JSON.stringify(dataFt));
    // newDataFt.offer_id = findOffer?.id;
    // newDataFt.advertiser_id = findAdvertiser?.value;
    // setDataFt({...newDataFt});
    setAdvertiser_id(findAdvertiser?.value);
    setOffer_id(findOffer?.id);
    setOffer_landing_id(dataFt?.offer_landing_id);
  }, []);

  return (
    <>
      <span className={css.subTitle2}>Default</span>
      <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
        <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '45px',
              border: errors && '2px solid red !important',
              boxShadow: errors && 'none',
            }),
          }}
          placeholder=''
          options={advertisersOptions}
          value={advertisersOptions.find((item) => item.value === advertiser_id)}
          setValue={(e) => {
            setDataFt({
              ...dataFt,
              advertiser_id: e.value,
              offer_id: '',
              offer_landing_id: '',
            });
            setOffersOptions(
              offers
                .filter((item) => item.advertiser_id === e.value)
                .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
            );
            setAdvertiser_id(e.value);
            setOffer_id(null);
            setOffer_landing_id(null);
          }}
          actions={() => {}}
        />
      </label>

      {advertiser_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Оффер</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors && '2px solid red !important',
                boxShadow: errors && 'none',
              }),
            }}
            placeholder=''
            options={offersOptions}
            value={offersOptions.find((item) => item.value === offer_id)}
            setValue={(e) => {
              setDataFt({
                ...dataFt,
                offer_id: e.value,
                offer_landing_id: '',
              });

              setLandingsOptions(
                landings
                  .filter((item) => item.offer_id === e.value)
                  .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
              );
              setOffer_id(e.value);
              setOffer_landing_id(null);
            }}
            actions={() => {}}
          />
        </label>
      )}
      {offer_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Лендинг</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors && '2px solid red !important',
                boxShadow: errors && 'none',
              }),
            }}
            placeholder=''
            options={landingsOptions}
            value={landingsOptions.find((item) => item.value === offer_landing_id)}
            setValue={(e) => {
              setDataFt({
                ...dataFt,
                offer_landing_id: e.value,
              });
              setDataBc({
                ...dataBc,
                offer_landing_id: e.value,
              });
              setOffer_landing_id(e.value);
            }}
            actions={() => {}}
          />
        </label>
      )}
      <SettingsGlobal
        dataFt={dataFt}
        setDataFt={setDataFt}
        dataBc={dataBc}
        setDataBc={setDataBc}
        advertisersOptions={advertisersOptions}
        offers={offers}
        landings={landings}
      />
      {offer_landing_id && (
        <>
          <span className={css.subTitle2} style={{marginTop: '10px'}}>
            Мульти-ГЕО
          </span>
          {!!dataFt?.country_offers &&
            dataFt?.country_offers?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '8px',
                      }}
                    >
                      ГЕО
                      <div
                        className={css.delete}
                        onClick={async (e) => {
                          const newDataFt = structuredClone(dataFt);
                          newDataFt.country_offers.splice(index, 1);
                          setDataFt(newDataFt);

                          const newDataBc = structuredClone(dataBc);
                          newDataBc.country_offers.splice(index, 1);
                          setDataBc(newDataBc);
                        }}
                      />
                    </span>
                    <SelectorWithCounties
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '45px',
                          // border: errors?.includes('advertiser_id') && '2px solid red !important',
                          // boxShadow: errors?.includes('advertiser_id') && 'none',
                        }),
                      }}
                      placeholder=''
                      options={countries.map((item) => {
                        return {
                          value: item.id,
                          label: item.name,
                          flag: `${item?.flag}`,
                        };
                      })}
                      value={countries
                        .map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                            flag: `${item?.flag}`,
                          };
                        })
                        .find((country) => country.value === item?.country_id)}
                      setValue={(e) => {
                        const newDataFt = JSON.parse(JSON.stringify(dataFt));
                        newDataFt.country_offers[index].country_id = e.value;
                        setDataFt(newDataFt);
                        const newDataBc = JSON.parse(JSON.stringify(dataBc));
                        newDataBc.country_offers[index].country_id = e.value;
                        setDataBc(newDataBc);
                      }}
                      actions={() => {}}
                    />
                  </label>
                  {errorsGeo?.includes(index) && (
                    <span
                      style={{
                        display: 'inline-block',
                        marginBottom: '16px',
                        color: 'red',
                      }}
                    >
                      ГЕО пуст, нужно его заполнить
                    </span>
                  )}
                  {!!item?.country_id && (
                    <>
                      <SettingsMultiGeo
                        indexCountry={index}
                        id={item?.country_id}
                        dataFt={dataFt}
                        setDataFt={setDataFt}
                        dataBc={dataBc}
                        setDataBc={setDataBc}
                        advertisersOptions={advertisersOptions}
                        offers={offers}
                        landings={landings}
                        countries={countries}
                      />
                    </>
                  )}
                  <BlockLine />
                </React.Fragment>
              );
            })}
          <button
            className={css.addComment}
            style={{marginTop: '0', marginBottom: '15px'}}
            onClick={addMulti}
          >
            Добавить +
          </button>
        </>
      )}
      <div className={css.next}>
        <button className={css.grayButton} onClick={() => setStep(1)} style={{minWidth: '130px'}}>
          Назад
        </button>

        <button
          className={css.blueButton}
          style={{minWidth: '130px'}}
          onClick={() => saveOffers(dataBc)}
        >
          Продолжить
        </button>
      </div>
    </>
  );
};

const SettingsMultiGeo = ({
  indexCountry,
  id,
  dataFt,
  setDataFt,
  dataBc,
  setDataBc,
  advertisersOptions,
  offers,
  landings,
  countries,
}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const [page, setPage] = useState('main');

  const [offersOptions, setOffersOptions] = useState([]);
  const [landingsOptions, setLandingsOptions] = useState([]);

  const [errors, setErrors] = useState({traffic_percentage: false, offer_landing_id: []});

  const [advertiser_id, setAdvertiser_id] = useState(null);
  const [offer_id, setOffer_id] = useState(null);
  const [offer_landing_id, setOffer_landing_id] = useState(null);

  const [landingsSplit, setLandingsSplit] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    const newLandings = landings.filter(
      (obj1) =>
        !dataFt?.country_offers[indexCountry]?.apps_split?.some((obj2) => {
          return obj1.id === obj2.offer_landing_id;
        })
    );

    setLandingsSplit(newLandings);
  }, [dataFt]);

  useEffect(() => {
    if (!dataFt?.country_offers[indexCountry].offer_landing_id) return;

    const findLanding = landings.find(
      (item) => item.id === dataFt?.country_offers[indexCountry].offer_landing_id
    );
    const findOffer = offers.find((item) => item.id === findLanding.offer_id);
    const findAdvertiser = advertisersOptions.find(
      (item) => item.value === findOffer.advertiser_id
    );

    const filterOffers = offers.filter((item) => item.advertiser_id === findOffer.advertiser_id);
    const filterLandings = landings.filter((item) => item.offer_id === findOffer.id);

    setLandingsOptions(
      filterLandings.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );
    setOffersOptions(
      filterOffers.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );

    // const newDataFt = JSON.parse(JSON.stringify(dataFt));
    // newDataFt.country_offers[indexCountry].offer_id = findOffer?.id;
    // newDataFt.country_offers[indexCountry].advertiser_id = findAdvertiser?.value;
    // setDataFt({...newDataFt});
    setAdvertiser_id(findAdvertiser?.value);
    setOffer_id(findOffer?.id);
    setOffer_landing_id(dataFt?.country_offers[indexCountry].offer_landing_id);
  }, []);

  const addAppsSplit = () => {
    const newDataFt = JSON.parse(JSON.stringify(dataFt));
    newDataFt?.country_offers[indexCountry]?.apps_split.push({
      offer_landing_id: null,
      traffic_percentage: null,
    });
    setDataFt(newDataFt);

    const newDataBc = JSON.parse(JSON.stringify(dataBc));
    newDataBc?.country_offers[indexCountry]?.apps_split.push({
      offer_landing_id: null,
      traffic_percentage: null,
    });
    setDataBc(newDataBc);
  };

  useEffect(() => {
    if (
      !dataBc?.country_offers[indexCountry]?.apps_split ||
      dataBc?.country_offers[indexCountry]?.apps_split.length === 0
    ) {
      setErrors({traffic_percentage: false, offer_landing_id: []});
    }
  }, [dataBc]);

  const validation = () => {
    if (
      !dataBc?.country_offers[indexCountry]?.apps_split ||
      dataBc?.country_offers[indexCountry]?.apps_split.length === 0
    ) {
      setErrors({traffic_percentage: false, offer_landing_id: []});
      return setShowCreateAppModal(false);
    }
    const sum = dataBc?.country_offers[indexCountry]?.apps_split?.reduce(
      (acc, cur) => acc + cur.traffic_percentage,
      0
    );
    const fieldsErrors = [];
    dataBc?.country_offers[indexCountry]?.apps_split?.forEach((item, index) => {
      if (!item?.offer_landing_id) {
        fieldsErrors.push(index);
      }
    });

    const obj = {...errors};

    if (sum !== 100) {
      obj.traffic_percentage = true;
    } else {
      obj.traffic_percentage = false;
    }

    if (fieldsErrors.length > 0) {
      obj.offer_landing_id = fieldsErrors;
    } else {
      obj.offer_landing_id = [];
    }

    setErrors({...obj});

    if (fieldsErrors.length > 0 || sum !== 100) {
      return;
    }
    return setShowCreateAppModal(false);
  };

  return (
    <>
      <button
        className={classNames(css.addComment, 'bg-warning-subtle text-warning')}
        style={{marginTop: '0'}}
        onClick={() => setShowCreateAppModal(true)}
      >
        Настройки
      </button>
      <CreateAppModal
        customWidth='mw-516px'
        padding='px-lg-10 pb-lg-10 pt-0'
        show={showCreateAppModal}
        handleClose={() => {
          validation();
        }}
        title={`Настройка ГЕО ${countries.find((item) => item?.id === id).name}`}
      >
        <Navigation
          page={page}
          setPage={setPage}
          links={[
            {link: 'main', title: 'Основное'},
            {link: 'split', title: 'SPLIT'},
          ]}
        />
        <div
          className={css.form}
          style={{
            marginTop: '15px',
            padding: '0px',
            boxShadow: 'none',
            '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
            '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
            '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
            '--activeColor':
              themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
            '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
            '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
            '--buttonGrayBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
            '--slideSelectorBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
            '--slideSelectorItemBg':
              themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
            '--buttonCommentBg':
              themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
          }}
        >
          {page === 'main' && (
            <>
              <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
                <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
                <SelectorWithSearch
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                      // border: errors?.includes('advertiser_id') && '2px solid red !important',
                      // boxShadow: errors?.includes('advertiser_id') && 'none',
                    }),
                  }}
                  placeholder=''
                  options={advertisersOptions}
                  value={advertisersOptions.find((item) => item.value === advertiser_id)}
                  setValue={(e) => {
                    const newCountryOffers = [...dataFt?.country_offers];
                    newCountryOffers[indexCountry].advertiser_id = e.value;
                    newCountryOffers[indexCountry].offer_id = null;
                    newCountryOffers[indexCountry].offer_landing_id = null;
                    setDataFt({
                      ...dataFt,
                      country_offers: newCountryOffers,
                    });
                    setOffersOptions(
                      offers
                        .filter((item) => item.advertiser_id === e.value)
                        .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
                    );
                    setAdvertiser_id(e.value);
                    setOffer_id(null);
                    setOffer_landing_id(null);
                  }}
                  actions={() => {}}
                />
              </label>

              {advertiser_id && (
                <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
                  <span style={{display: 'inline-block', marginBottom: '8px'}}>Оффер</span>
                  <SelectorWithSearch
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '45px',
                        // border: errors?.includes('advertiser_id') && '2px solid red !important',
                        // boxShadow: errors?.includes('advertiser_id') && 'none',
                      }),
                    }}
                    placeholder=''
                    options={offersOptions}
                    value={offersOptions.find((item) => item.value === offer_id)}
                    setValue={(e) => {
                      const newCountryOffers = [...dataFt?.country_offers];
                      newCountryOffers[indexCountry].offer_id = e.value;
                      newCountryOffers[indexCountry].offer_landing_id = '';
                      setDataFt({
                        ...dataFt,
                        country_offers: newCountryOffers,
                      });

                      setLandingsOptions(
                        landings
                          .filter((item) => item.offer_id === e.value)
                          .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
                      );
                      setOffer_id(e.value);
                      setOffer_landing_id(null);
                    }}
                    actions={() => {}}
                  />
                </label>
              )}
              {!!offer_id && (
                <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
                  <span style={{display: 'inline-block', marginBottom: '8px'}}>Лендинг</span>
                  <SelectorWithSearch
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '45px',
                        // border: errors?.includes('advertiser_id') && '2px solid red !important',
                        // boxShadow: errors?.includes('advertiser_id') && 'none',
                      }),
                    }}
                    placeholder=''
                    options={landingsOptions}
                    value={landingsOptions.find((item) => item.value === offer_landing_id)}
                    setValue={(e) => {
                      const newCountryOffersFt = [...dataFt?.country_offers];
                      newCountryOffersFt[indexCountry].offer_landing_id = e.value;
                      setDataFt({
                        ...dataFt,
                        country_offers: newCountryOffersFt,
                      });
                      const newCountryOffersBc = [...dataBc?.country_offers];
                      newCountryOffersBc[indexCountry].offer_landing_id = e.value;
                      setDataBc({
                        ...dataBc,
                        country_offers: newCountryOffersBc,
                      });
                      setOffer_landing_id(e.value);
                    }}
                    actions={() => {}}
                  />
                </label>
              )}
            </>
          )}
          {page === 'split' && (
            <>
              <div
                className={css.checkerContainer}
                style={{
                  '--slideSelectorBg':
                    themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
                }}
              >
                <div
                  className={css.checkboxContainer}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span style={{display: 'inline-block', marginBottom: '0px'}}>
                    Включить SPLIT ({dataFt?.country_offers[indexCountry]?.apps_split?.length || 0})
                  </span>
                  <label htmlFor='checkbox1' className={css.switch}>
                    <input
                      type='checkbox'
                      id='checkbox1'
                      checked={dataFt?.country_offers[indexCountry].split_enabled}
                      onChange={(e) => {
                        const newCountyOffersFt = [...dataFt?.country_offers];
                        newCountyOffersFt[indexCountry].split_enabled = e.target.checked;
                        const newCountyOffersBc = [...dataBc?.country_offers];
                        newCountyOffersBc[indexCountry].split_enabled = e.target.checked;
                        if (!e.target.checked) {
                          newCountyOffersFt[indexCountry].apps_split = [];
                          newCountyOffersBc[indexCountry].apps_split = [];
                        }
                        setDataFt({...dataFt, country_offers: newCountyOffersFt});
                        setDataBc({...dataBc, country_offers: newCountyOffersBc});
                      }}
                    />
                  </label>
                </div>
              </div>
              {errors?.traffic_percentage && (
                <span
                  style={{
                    display: 'inline-block',
                    marginBottom: '16px',
                    marginTop: '16px',
                    color: 'red',
                  }}
                >
                  Сумарный процент трафика должен быть 100
                </span>
              )}
              <br />
              {errors?.offer_landing_id.length > 0 && (
                <span
                  style={{
                    display: 'inline-block',
                    marginBottom: '16px',
                    marginTop: '16px',
                    color: 'red',
                  }}
                >
                  Заполните поля
                </span>
              )}
              {!!dataFt?.country_offers[indexCountry]?.split_enabled && (
                <>
                  {!!dataFt?.country_offers[indexCountry]?.apps_split &&
                    dataFt?.country_offers[indexCountry]?.apps_split?.map((app, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CountryOffersMulti
                            dataBc={dataBc}
                            setDataBc={setDataBc}
                            setDataFt={setDataFt}
                            dataFt={dataFt}
                            app={app}
                            index={index}
                            indexCountry={indexCountry}
                            advertisersOptions={advertisersOptions}
                            offers={offers}
                            landings={landingsSplit}
                            landingsForStart={landings}
                            errors={errors}
                          />
                          <BlockLine />
                        </React.Fragment>
                      );
                    })}
                  {!!dataFt?.country_offers[indexCountry]?.apps_split &&
                    dataFt?.country_offers[indexCountry]?.apps_split?.length > 0 && (
                      <button
                        className={classNames(css.addComment, css._traffic)}
                        style={{marginTop: '15px', marginBottom: '15px'}}
                        onClick={() => {
                          const newDataFt = JSON.parse(JSON.stringify(dataFt));
                          const num =
                            100 / newDataFt.country_offers[indexCountry].apps_split.length;
                          newDataFt.country_offers[indexCountry].apps_split =
                            newDataFt.country_offers[indexCountry].apps_split.map((item, index) => {
                              return {
                                ...item,
                                traffic_percentage:
                                  num % 1 !== 0
                                    ? index ===
                                      newDataFt.country_offers[indexCountry].apps_split.length - 1
                                      ? Math.floor(num) + 1
                                      : Math.floor(num)
                                    : num,
                              };
                            });
                          setDataFt(newDataFt);

                          const newDataBc = JSON.parse(JSON.stringify(dataBc));
                          newDataBc.country_offers[indexCountry].apps_split =
                            newDataBc.country_offers[indexCountry].apps_split.map((item, index) => {
                              return {
                                ...item,
                                traffic_percentage:
                                  num % 1 !== 0
                                    ? index ===
                                      newDataBc.country_offers[indexCountry].apps_split.length - 1
                                      ? Math.floor(num) + 1
                                      : Math.floor(num)
                                    : num,
                              };
                            });
                          setDataBc(newDataBc);
                        }}
                      >
                        Уровнять трафик
                      </button>
                    )}
                  <button
                    className={css.addComment}
                    style={{marginTop: '15px', marginBottom: '15px'}}
                    onClick={addAppsSplit}
                  >
                    Добавить +
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </CreateAppModal>
    </>
  );
};

const CountryOffersMulti = ({
  dataBc,
  setDataBc,
  dataFt,
  setDataFt,
  app,
  index,
  indexCountry,
  advertisersOptions,
  offers,
  landings,
  landingsForStart,
  errors,
}) => {
  const [offersOptions, setOffersOptions] = useState([]);
  const [landingsOptions, setLandingsOptions] = useState([]);
  const [advertiser_id, setAdvertiser_id] = useState(null);
  const [offer_id, setOffer_id] = useState(null);
  const [offer_landing_id, setOffer_landing_id] = useState(null);
  const [traffic_percentage, setTraffic_percentage] = useState(0);

  useEffect(() => {
    setTraffic_percentage(app.traffic_percentage);
  }, [dataFt]);

  useEffect(() => {
    if (!app.offer_landing_id) return;

    let findLanding = landings.find((item) => item.id === app.offer_landing_id);

    if (!findLanding) {
      findLanding = landingsForStart.find((item) => item.id === app.offer_landing_id);
    }

    const findOffer = offers.find((item) => item.id === findLanding.offer_id);
    const findAdvertiser = advertisersOptions.find(
      (item) => item.value === findOffer.advertiser_id
    );

    const filterOffers = offers.filter((item) => item.advertiser_id === findOffer.advertiser_id);
    const filterLandings = landings.filter((item) => item.offer_id === findOffer.id);

    const findLandingInList = filterLandings.find((item) => item.id === app.offer_landing_id);
    if (!findLandingInList) {
      filterLandings.push(findLanding);
    }

    setLandingsOptions(
      filterLandings
        .sort((a, b) => a.id - b.id)
        .map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );
    setOffersOptions(
      filterOffers.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );

    setAdvertiser_id(findAdvertiser?.value);
    setOffer_id(findOffer?.id);
    setOffer_landing_id(app.offer_landing_id);
    setTraffic_percentage(app.traffic_percentage);
  }, [landings]);

  return (
    <>
      <label htmlFor='' style={{width: '100%', marginBottom: '16px', marginTop: '16px'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px 0',
          }}
        >
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
          <DeleteCountryOfferMulti
            dataBc={dataBc}
            setDataBc={setDataBc}
            setDataFt={setDataFt}
            dataFt={dataFt}
            index={index}
            indexCountry={indexCountry}
          />
        </div>
        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '45px',
              border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
              boxShadow: errors?.offer_landing_id.includes(index) && 'none',
            }),
          }}
          placeholder=''
          options={advertisersOptions}
          value={advertisersOptions.find((item) => item.value === advertiser_id)}
          setValue={(e) => {
            const newDataFt = JSON.parse(JSON.stringify(dataFt));
            newDataFt.country_offers[indexCountry].apps_split[index].advertiser_id = e.value;
            newDataFt.country_offers[indexCountry].apps_split[index].offer_id = null;
            newDataFt.country_offers[indexCountry].apps_split[index].offer_landing_id = null;
            setDataFt(newDataFt);
            setOffersOptions(
              offers
                .filter((item) => item.advertiser_id === e.value)
                .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
            );
            setAdvertiser_id(e.value);
            setOffer_id(null);
            setOffer_landing_id(null);
          }}
          actions={() => {}}
        />
      </label>

      {advertiser_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Оффер</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
                boxShadow: errors?.offer_landing_id.includes(index) && 'none',
              }),
            }}
            placeholder=''
            options={offersOptions}
            value={offersOptions.find((item) => item.value === offer_id)}
            setValue={(e) => {
              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.country_offers[indexCountry].apps_split[index].offer_id = e.value;
              newDataFt.country_offers[indexCountry].apps_split[index].offer_landing_id = null;
              setDataFt(newDataFt);
              setLandingsOptions(
                landings
                  .filter((item) => item.offer_id === e.value)
                  .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
              );
              setOffer_id(e.value);
              setOffer_landing_id(null);
            }}
            actions={() => {}}
          />
        </label>
      )}
      {offer_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Лендинг</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
                boxShadow: errors?.offer_landing_id.includes(index) && 'none',
              }),
            }}
            placeholder=''
            options={landingsOptions}
            value={landingsOptions.find((item) => item.value === offer_landing_id)}
            setValue={(e) => {
              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.country_offers[indexCountry].apps_split[index].offer_landing_id = e.value;
              setDataFt(newDataFt);

              const newDataBc = JSON.parse(JSON.stringify(dataBc));
              newDataBc.country_offers[indexCountry].apps_split[index].offer_landing_id = e.value;
              setDataBc(newDataBc);
              setOffer_landing_id(e.value);
            }}
            actions={() => {}}
          />
        </label>
      )}
      <label
        className={css.inputField}
        style={{width: '100%', marginBottom: '16px'}}
        htmlFor={`range${index}`}
      >
        <span style={{display: 'inline-block', marginBottom: '8px'}}>Процент трафика</span>
        <div className={css.rangeContainer}>
          <div
            className={css.value}
            style={{
              left: !traffic_percentage
                ? '-17px'
                : `calc(${traffic_percentage}% + (${-17 - traffic_percentage * 0.17}px))`,
            }}
          >
            {traffic_percentage || 0}
          </div>
          <input
            type='range'
            name=''
            id={`range${index}`}
            className={css.range}
            value={traffic_percentage || 0}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (!/^\d*$/.test(inputValue)) return;
              if (parseInt(inputValue, 10) > 100) return;

              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.country_offers[indexCountry].apps_split[index].traffic_percentage =
                +inputValue;
              setDataFt(newDataFt);

              const newDataBc = JSON.parse(JSON.stringify(dataBc));
              newDataBc.country_offers[indexCountry].apps_split[index].traffic_percentage =
                +inputValue;
              setDataBc(newDataBc);
              setTraffic_percentage(+inputValue);
            }}
          />
        </div>
      </label>
    </>
  );
};

const DeleteCountryOfferMulti = ({dataBc, setDataBc, dataFt, setDataFt, index, indexCountry}) => {
  const [warning, setWarning] = useState(false);

  const del = async () => {
    const newDataBc = JSON.parse(JSON.stringify(dataBc));
    newDataBc.country_offers[indexCountry].apps_split.splice(index, 1);
    setDataBc(newDataBc);

    const newDataFt = JSON.parse(JSON.stringify(dataFt));
    newDataFt.country_offers[indexCountry].apps_split.splice(index, 1);
    setDataFt(newDataFt);
  };

  return (
    <>
      <div
        className={css.delete}
        onClick={async (e) => {
          setWarning(true);
        }}
      />
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text='Вы уверены что хотите удалить оффер?'
          buttons={[
            {
              action: () => {
                del();
                setWarning(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
};

const SettingsGlobal = ({
  dataFt,
  setDataFt,
  dataBc,
  setDataBc,
  advertisersOptions,
  offers,
  landings,
}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const [errors, setErrors] = useState({traffic_percentage: false, offer_landing_id: []});

  const [landingsSplit, setLandingsSplit] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    const newLandings = landings.filter(
      (obj1) =>
        !dataFt?.apps_split?.some((obj2) => {
          return obj1.id === obj2.offer_landing_id;
        })
    );

    setLandingsSplit(newLandings);
  }, [dataFt]);

  const addAppsSplit = () => {
    setDataFt({
      ...dataFt,
      apps_split: !!dataFt?.apps_split
        ? [
            ...dataFt?.apps_split,
            {
              offer_landing_id: null,
              traffic_percentage: null,
            },
          ]
        : [
            {
              offer_landing_id: null,
              traffic_percentage: null,
            },
          ],
    });
    setDataBc({
      ...dataBc,
      apps_split: !!dataBc?.apps_split
        ? [
            ...dataBc?.apps_split,
            {
              offer_landing_id: null,
              traffic_percentage: null,
            },
          ]
        : [
            {
              offer_landing_id: null,
              traffic_percentage: null,
            },
          ],
    });
  };

  useEffect(() => {
    if (!dataBc?.apps_split?.length || dataBc?.apps_split?.length === 0) {
      setErrors({traffic_percentage: false, offer_landing_id: []});
    }
  }, [dataBc]);

  const validation = () => {
    if (!dataBc?.apps_split?.length || dataBc?.apps_split?.length === 0) {
      setErrors({traffic_percentage: false, offer_landing_id: []});
      return setShowCreateAppModal(false);
    }
    const sum = dataBc?.apps_split?.reduce((acc, cur) => acc + cur.traffic_percentage, 0);
    const fieldsErrors = [];
    dataBc?.apps_split?.forEach((item, index) => {
      if (!item?.offer_landing_id) {
        fieldsErrors.push(index);
      }
    });

    const obj = {...errors};

    if (sum !== 100) {
      obj.traffic_percentage = true;
    } else {
      obj.traffic_percentage = false;
    }

    if (fieldsErrors.length > 0) {
      obj.offer_landing_id = fieldsErrors;
    } else {
      obj.offer_landing_id = [];
    }

    setErrors({...obj});

    if (fieldsErrors.length > 0 || sum !== 100) {
      return;
    }
    return setShowCreateAppModal(false);
  };

  return (
    <>
      <button
        className={classNames(css.addComment, 'bg-warning-subtle text-warning')}
        style={{marginTop: '0'}}
        onClick={() => setShowCreateAppModal(true)}
      >
        Настройки
      </button>
      <CreateAppModal
        customWidth='mw-516px'
        show={showCreateAppModal}
        handleClose={() => {
          validation();
        }}
        title={'Настройка для Default'}
      >
        <div
          className={css.form}
          style={{
            padding: '0px',
            boxShadow: 'none',
            '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
            '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
            '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
            '--activeColor':
              themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
            '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
            '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
            '--buttonGrayBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
            '--slideSelectorBg':
              themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
            '--slideSelectorItemBg':
              themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
            '--buttonCommentBg':
              themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
          }}
        >
          <div
            className={css.checkerContainer}
            style={{
              '--slideSelectorBg':
                themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
            }}
          >
            <div
              className={css.checkboxContainer}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span style={{display: 'inline-block', marginBottom: '0px'}}>
                Включить SPLIT ({dataFt?.apps_split?.length || 0})
              </span>
              <label htmlFor='checkbox1' className={css.switch}>
                <input
                  type='checkbox'
                  id='checkbox1'
                  checked={dataFt?.split_enabled}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setDataFt({...dataFt, apps_split: [], split_enabled: e.target.checked});
                      setDataBc({...dataBc, apps_split: [], split_enabled: e.target.checked});
                    } else {
                      setDataFt({...dataFt, split_enabled: e.target.checked});
                      setDataBc({...dataBc, split_enabled: e.target.checked});
                    }
                  }}
                />
              </label>
            </div>
          </div>
          {errors?.traffic_percentage && (
            <span
              style={{
                display: 'inline-block',
                marginBottom: '16px',
                marginTop: '16px',
                color: 'red',
              }}
            >
              Сумарный процент трафика должен быть 100
            </span>
          )}
          <br />
          {errors?.offer_landing_id.length > 0 && (
            <span
              style={{
                display: 'inline-block',
                marginBottom: '16px',
                marginTop: '16px',
                color: 'red',
              }}
            >
              Заполните поля
            </span>
          )}
          {!!dataFt?.split_enabled && (
            <>
              {!!dataFt?.apps_split &&
                dataFt.apps_split?.map((app, index) => {
                  return (
                    <React.Fragment key={index}>
                      <SettingsGlobalMulti
                        app={app}
                        index={index}
                        dataFt={dataFt}
                        setDataFt={setDataFt}
                        dataBc={dataBc}
                        setDataBc={setDataBc}
                        advertisersOptions={advertisersOptions}
                        offers={offers}
                        landings={landingsSplit}
                        landingsForStart={landings}
                        errors={errors}
                      />
                      <BlockLine />
                    </React.Fragment>
                  );
                })}

              {!!dataFt?.apps_split && dataFt?.apps_split?.length > 0 && (
                <button
                  className={classNames(css.addComment, css._traffic)}
                  style={{marginTop: '15px', marginBottom: '15px'}}
                  onClick={() => {
                    const newDataFt = JSON.parse(JSON.stringify(dataFt));
                    const num = 100 / newDataFt.apps_split.length;
                    newDataFt.apps_split = newDataFt.apps_split.map((item, index) => {
                      return {
                        ...item,
                        traffic_percentage:
                          num % 1 !== 0
                            ? index === newDataFt.apps_split.length - 1
                              ? Math.floor(num) + 1
                              : Math.floor(num)
                            : num,
                      };
                    });
                    setDataFt(newDataFt);

                    const newDataBc = JSON.parse(JSON.stringify(dataBc));
                    newDataBc.apps_split = newDataBc.apps_split.map((item, index) => {
                      return {
                        ...item,
                        traffic_percentage:
                          num % 1 !== 0
                            ? index === newDataBc.apps_split.length - 1
                              ? Math.floor(num) + 1
                              : Math.floor(num)
                            : num,
                      };
                    });
                    setDataBc(newDataBc);
                  }}
                >
                  Уровнять трафик
                </button>
              )}
              <button
                className={css.addComment}
                style={{marginTop: '15px', marginBottom: '15px'}}
                onClick={addAppsSplit}
              >
                Добавить +
              </button>
            </>
          )}
        </div>
      </CreateAppModal>
    </>
  );
};

const SettingsGlobalMulti = ({
  app,
  index,
  dataFt,
  setDataFt,
  dataBc,
  setDataBc,
  advertisersOptions,
  offers,
  landings,
  landingsForStart,
  errors,
}) => {
  const [offersOptions, setOffersOptions] = useState([]);
  const [landingsOptions, setLandingsOptions] = useState([]);
  const [advertiser_id, setAdvertiser_id] = useState(null);
  const [offer_id, setOffer_id] = useState(null);
  const [offer_landing_id, setOffer_landing_id] = useState(null);
  const [traffic_percentage, setTraffic_percentage] = useState(0);

  useEffect(() => {
    setTraffic_percentage(app.traffic_percentage);
  }, [dataFt]);

  useEffect(() => {
    if (!app.offer_landing_id) return;
    let findLanding = landings.find((item) => item.id === app.offer_landing_id);

    if (!findLanding) {
      findLanding = landingsForStart.find((item) => item.id === app.offer_landing_id);
    }

    const findOffer = offers.find((item) => item.id === findLanding.offer_id);
    const findAdvertiser = advertisersOptions.find(
      (item) => item.value === findOffer.advertiser_id
    );

    const filterOffers = offers.filter((item) => item.advertiser_id === findOffer.advertiser_id);
    const filterLandings = landings.filter((item) => item.offer_id === findOffer.id);

    const findLandingInList = filterLandings.find((item) => item.id === app.offer_landing_id);
    if (!findLandingInList) {
      filterLandings.push(findLanding);
    }

    setLandingsOptions(
      filterLandings
        .sort((a, b) => a.id - b.id)
        .map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );
    setOffersOptions(
      filterOffers.map((item) => ({value: item.id, label: `#${item?.id} ${item.name}`}))
    );

    setAdvertiser_id(findAdvertiser?.value);
    setOffer_id(findOffer?.id);
    setOffer_landing_id(app.offer_landing_id);
    setTraffic_percentage(app.traffic_percentage);
  }, [landings]);

  return (
    <>
      <label htmlFor='' style={{width: '100%', marginBottom: '16px', marginTop: '16px'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px 0',
          }}
        >
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
          <DeleteOffer
            dataBc={dataBc}
            setDataBc={setDataBc}
            setDataFt={setDataFt}
            dataFt={dataFt}
            index={index}
          />
        </div>
        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '45px',
              border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
              boxShadow: errors?.offer_landing_id.includes(index) && 'none',
            }),
          }}
          placeholder=''
          options={advertisersOptions}
          value={advertisersOptions?.find((item) => item?.value === advertiser_id)}
          setValue={(e) => {
            const newDataFt = JSON.parse(JSON.stringify(dataFt));
            newDataFt.apps_split[index].advertiser_id = e.value;
            newDataFt.apps_split[index].offer_id = null;
            newDataFt.apps_split[index].offer_landing_id = null;
            setDataFt(newDataFt);
            setOffersOptions(
              offers
                .filter((item) => item.advertiser_id === e.value)
                .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
            );
            setAdvertiser_id(e.value);
            setOffer_id(null);
            setOffer_landing_id(null);
          }}
          actions={() => {}}
        />
      </label>

      {advertiser_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Оффер</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
                boxShadow: errors?.offer_landing_id.includes(index) && 'none',
              }),
            }}
            placeholder=''
            options={offersOptions}
            value={offersOptions?.find((item) => item?.value === offer_id)}
            setValue={(e) => {
              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.apps_split[index].offer_id = e.value;
              newDataFt.apps_split[index].offer_landing_id = null;
              setDataFt(newDataFt);
              setLandingsOptions(
                landings
                  .filter((item) => item.offer_id === e.value)
                  .map((item) => ({label: `#${item?.id} ${item.name}`, value: item.id}))
              );
              setOffer_id(e.value);
              setOffer_landing_id(null);
            }}
            actions={() => {}}
          />
        </label>
      )}
      {offer_id && (
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <span style={{display: 'inline-block', marginBottom: '8px'}}>Лендинг</span>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors?.offer_landing_id.includes(index) && '2px solid red !important',
                boxShadow: errors?.offer_landing_id.includes(index) && 'none',
              }),
            }}
            placeholder=''
            options={landingsOptions}
            value={landingsOptions.find((item) => item.value === offer_landing_id)}
            setValue={(e) => {
              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.apps_split[index].offer_landing_id = e.value;
              setDataFt(newDataFt);

              const newDataBc = JSON.parse(JSON.stringify(dataBc));
              newDataBc.apps_split[index].offer_landing_id = e.value;
              setDataBc(newDataBc);
              setOffer_landing_id(e.value);
            }}
            actions={() => {}}
          />
        </label>
      )}
      <label
        className={css.inputField}
        style={{width: '100%', marginBottom: '16px'}}
        htmlFor='range1'
      >
        <span style={{display: 'inline-block', marginBottom: '8px'}}>Процент трафика</span>
        <div className={css.rangeContainer}>
          <div
            className={css.value}
            style={{
              left: !app?.traffic_percentage
                ? '-17px'
                : `calc(${app?.traffic_percentage}% + (${-17 - app?.traffic_percentage * 0.17}px))`,
            }}
          >
            {traffic_percentage || 0}
          </div>
          <input
            type='range'
            name=''
            id='range1'
            className={css.range}
            value={traffic_percentage || 0}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (!/^\d*$/.test(inputValue)) return;
              if (parseInt(inputValue, 10) > 100) return;

              const newDataFt = JSON.parse(JSON.stringify(dataFt));
              newDataFt.apps_split[index].traffic_percentage = +inputValue;
              setDataFt(newDataFt);

              const newDataBc = JSON.parse(JSON.stringify(dataBc));
              newDataBc.apps_split[index].traffic_percentage = +inputValue;
              setDataBc(newDataBc);
              setTraffic_percentage(+inputValue);
            }}
          />
        </div>
      </label>
    </>
  );
};

const DeleteOffer = ({dataBc, setDataBc, dataFt, setDataFt, index}) => {
  const [warning, setWarning] = useState(false);

  const del = async () => {
    const newDataBc = JSON.parse(JSON.stringify(dataBc));
    newDataBc.apps_split.splice(index, 1);
    setDataBc(newDataBc);

    const newDataFt = JSON.parse(JSON.stringify(dataFt));
    newDataFt.apps_split.splice(index, 1);
    setDataFt(newDataFt);
  };

  return (
    <>
      <div
        className={css.delete}
        onClick={async (e) => {
          setWarning(true);
        }}
      />
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text='Вы уверены что хотите удалить оффер?'
          buttons={[
            {
              action: () => {
                del();
                setWarning(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
};

export default observer(LandingForm);
