import {api} from './api';

const offersAPI = {
  async offersGetOffers(token) {
    try {
      const response = await api.get(`Offers/getOffers?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getOffersListPage(token, perPage, page, query, advertiserId) {
    try {
      const response = await api.get(
        `Offers/getOffersListPage?token=${token}&page=${page}&per_page=${perPage}&q=${query}&advertiser_id=${advertiserId}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersGetOffer(token, id) {
    try {
      const response = await api.get(`Offers/getOffer?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async offersGetLandingsList(token) {
    try {
      const response = await api.get(`Offers/getLandingsList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async offersGetLandings(token, query) {
    try {
      const response = await api.get(`Offers/getLandings?token=${token}${query}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersAddOfferLanding(formData) {
    try {
      const response = await api.post(`Offers/addOfferLanding`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersUpdateOfferLanding(formData) {
    try {
      const response = await api.post(`Offers/updateOfferLanding`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersGetOfferStats(token, time_config, id) {
    try {
      const response = await api.get(
        `Offers/getOfferStats?token=${token}&id=${id}&time_config=${time_config.time_config}&time_start=${time_config.time_start}&time_end=${time_config.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersGetOffersList(token) {
    try {
      const response = await api.get(`Offers/getOffersList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getBrands(token) {
    try {
      const response = await api.get(`Brands/getBrands?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getBrandsList(token) {
    try {
      const response = await api.get(`Brands/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async offersAddOffer(formData) {
    try {
      const response = await api.post(`Offers/addOffer`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async offersUpdateOffer(formData) {
    try {
      const response = await api.post(`Offers/updateOffer`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default offersAPI;
