/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import InputSearch from '../../../shared/InputSearch';
import Pagination from '../../../shared/Pagination';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import AddAdvertiser from '../../advertisers/advertisers/AddAdvertiser';
import {CreateAppModal} from '../../../../_metronic/partials';
import {formatNumber} from '../../../functions/formatNumber';
// import AddAChannel from './AddChannel';

const TgAccountsWrapper = () => {
  return (
    <PageWrapper
      firstTimeConfig='today'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Телеграм аккаунты', path: '/resources/telegram/accounts', isActive: true},
      ]}
      title={'Телеграм аккаунты'}
    >
      <TgAccounts />
    </PageWrapper>
  );
};

const TgAccounts = observer(({isVerify, timeConfig, setPreloader}) => {
  const store = useStore();

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    isVerify && !!timeConfig.time_config && getAccounts();
  }, [isVerify, timeConfig]);

  const getAccounts = async () => {
    const getAccounts = await store.resourceTelegramGetAccounts(timeConfig);
    setAccounts(getAccounts);
    setPreloader(false);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title flex-column'>
            <h3 className='fw-bold mb-1'>Телеграм аккаунты</h3>
          </div>
        </div>
        <Table accounts={accounts} getAccounts={getAccounts} />
      </Card>
    </>
  );
});

const Table = ({accounts, getAccounts}) => {
  const [portionChannels, setPortionChannels] = useState([]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='max-w-90px text-start'>#</th>
                <th className='min-w-125px text-start'>USERNAME</th>
                <th className='min-w-125px text-start'>НОМЕР</th>
                <th className='min-w-125px text-center'>Чаты</th>
                <th className='min-w-125px text-center'>Квалификации</th>
                {/* <th className='min-w-125px text-center'>Входящие сообщения</th>
                <th className='min-w-125px text-center'>Исходящие сообщения</th> */}
                <th className='text-end min-w-100px'>WorkSpace</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {accounts.map((item, i) => {
                return (
                  <React.Fragment key={item.id}>
                    <Accounts data={item} getAccounts={getAccounts} />
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {channels.length > limit && (
        <Pagination
          arrLength={channels.length}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )} */}
    </div>
  );
};

const Accounts = ({data, getAccounts}) => {
  return (
    <tr>
      <td className='text-start'>
        <div>{data.id}</div>
      </td>
      <td className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <span>
            <div className='symbol-label'>
              <img
                src={
                  !!data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug
                }
                alt='#'
                className='w-100'
              />
            </div>
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex fs-6 text-gray-800'>
              <span className='me-2'>{data.username}</span>{' '}
              {/* <span className='fw-semibold text-gray-400'>#{data.id}</span> */}
            </div>
            {/* <div className='fw-semibold text-gray-800'>{data.phone_number}</div> */}
          </div>
        </div>
      </td>
      <td className='text-start'>
        <div className='fw-semibold text-gray-800'>{data.phone_number}</div>
      </td>
      <td className='text-center'>
        <div>{data.count_chats}</div>
      </td>

      {/* <td className='text-center'>
        <div className='badge badge-light-success fw-bold'>{data.config}20</div>
      </td>
      <td className='text-center'>
        <div className='badge badge-light-warning fw-bold'>{data.config}20</div>
      </td> */}
      <td className='text-center'>
        <div>{data.count_qualifications}</div>
      </td>
      <td className='d-flex align-items-center justify-content-end'>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex fs-6 text-gray-800'>
              <span className='me-2'>{data.workspace_name}</span>{' '}
              <span className='fw-semibold text-gray-400 text-end'>#{data.workspace_id}</span>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TgAccountsWrapper;
