/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {Logout, AuthPage, useAuth} from '../modules/auth';
import {App} from '../App';
import {observer} from 'mobx-react';
import {useStore} from '../../store/store';
import {toast} from 'react-toastify';

const {PUBLIC_URL} = process.env;

const AppRoutes = () => {
  const store = useStore();

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            {!!store.user?.main_token ? (
              <>
                {store.roles.includes(4) && (
                  <Route index element={<Navigate to='/dashboard/main' />} />
                )}
                {store.roles.includes(13) && (
                  <Route index element={<Navigate to='/dashboard/main' />} />
                )}
                {store.roles.includes(32) && (
                  <Route index element={<Navigate to='/dashboard/main' />} />
                )}
                {store.roles.includes(15) && (
                  <Route index element={<Navigate to='/dashboard/manager/rd' />} />
                )}
                {store.roles.includes(34) && <Route index element={<Navigate to='/leads' />} />}
                {store.roles.includes(9) && (
                  <Route index element={<Navigate to='/dashboard/manager/ltc' />} />
                )}
                {store.roles.includes(33) && <Route index element={<Navigate to='/leads' />} />}
                {store.roles.includes(16) && (
                  <Route index element={<Navigate to='/dashboard/teamleader/ltc' />} />
                )}
                {store.roles.includes(17) && (
                  <Route index element={<Navigate to='/dashboard/teamleader/rd' />} />
                )}
                {store.roles.includes(8) && (
                  <Route index element={<Navigate to='/dashboard/sales' />} />
                )}
                {store.roles.includes(11) && (
                  <Route index element={<Navigate to='/dashboard/influence' />} />
                )}
                {store.roles.includes(10) && (
                  <Route index element={<Navigate to='/dashboard/facebook' />} />
                )}
                {store.roles.includes(6) && (
                  <Route index element={<Navigate to='/dashboard/facebook/bayer' />} />
                )}
                {store.roles.includes(19) && (
                  <Route index element={<Navigate to='/dashboard/influence/bayer' />} />
                )}
                {store.roles.includes(26) && (
                  <Route index element={<Navigate to='/dashboard/hr' />} />
                )}
                {store.roles.includes(28) && <Route index element={<Navigate to='/leads' />} />}
                {store.roles.includes(29) && <Route index element={<Navigate to='/leads' />} />}
                {store.roles.includes(30) && (
                  <Route index element={<Navigate to='/dashboard/sales' />} />
                )}
                {store.roles.includes(31) && (
                  <Route index element={<Navigate to='/dashboard/sales' />} />
                )}
                {store.roles.includes(38) && (
                  <Route index element={<Navigate to='/dashboard/main' />} />
                )}
                {store.roles.includes(39) && (
                  <Route index element={<Navigate to='/advertisers/list' />} />
                )}
                <Route path='/*' element={<PrivateRoutes />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
            <Route path='error/*' element={<ErrorsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default observer(AppRoutes);
