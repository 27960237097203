/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {useParams, Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import numeral from 'numeral';
import moment from 'moment';
import {KTIcon} from '../../../../../_metronic/helpers';
import {Button} from '../../../../shared/Buttons';
import {formatNumber} from '../../../../functions/formatNumber';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import PageWrapper from '../../../../modules/PageWrapper';
import Card from '../../../../shared/Card';
import Stats from './Stats';
import Landings from './Landings';

const OfferWrapper = () => {
  const params = useParams();

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Офферы', path: '/advertisers/offers', isActive: true},
        {isSeparator: true},
        {title: `Оффер #${params.id}`, path: `/advertisers/offers/${params.id}`, isActive: true},
      ]}
      title={`Оффер #${params.id}`}
    >
      <Offer id={params.id} />
    </PageWrapper>
  );
};

const Offer = observer(({isVerify, timeConfig, setPreloader, id}) => {
  const store = useStore();
  const [data, setData] = useState({});

  const [stats, setStats] = useState({});
  const [offer, setOffer] = useState({});

  const [landings, setLandings] = useState({});

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const stats = await store.offersGetOfferStats(timeConfig, id);
    const offer = await store.offersGetOffer(id);
    const landings = await store.offersGetLandings(`&offer_id=${id}&perPage=10`, true);

    setStats(stats);
    setOffer(offer);
    setLandings(landings);
    setPreloader(false);
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div className='row g-5 g-xl-10 mt-0'>
      <div className='col-xl-4 mt-0 mb-xl-0 mb-10'>
        <Stats className='card-xl-stretch' chartColor='info' chartHeight='250px' data={stats} />
      </div>
      <div className='col-xl-8 mt-0 h-xl-100'>
        <Card className='card mb-10'>
          <div className='card-header py-7'>
            <div className='d-flex align-items-center'>
              <div className='me-5 position-relative'>
                <div className='symbol symbol-50px'>
                  <img
                    src={
                      offer?.advertiser?.avatar
                        ? `${process.env.REACT_APP_API_HOST}/${offer?.advertiser?.avatar}`
                        : imagePlug
                    }
                    alt=''
                  />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <span className='fs-2 fw-bold text-gray-800 text-hover-primary'>{offer?.name}</span>
                <div className='fw-semibold text-gray-500'>Оффер #{offer?.id}</div>
              </div>
            </div>
            <div className='card-toolbar'>
              {store.roles.includes(13) || store.roles.includes(32) ? (
                <></>
              ) : (
                <Link to={`/advertisers/offers/updateOffer/${id}`}>
                  <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                    <i className='ki-duotone ki-pencil fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </span>
                </Link>
              )}
            </div>
          </div>
          <div className='card-body py-9'>
            <div className='row g-5 g-xl-10'>
              <div className='col-xl-6 mt-0'>
                <div className='card-header py-7 border-bottom-0 ps-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800'>Основная информация</span>
                  </h3>
                </div>
                <div className='p-0'>
                  <div style={{height: '365px'}}>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Offer ID Рекламодателя</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>
                          {offer?.advertise_offer_id}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Ставка</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.bid}$</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Baseline</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.baseline}$</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>
                        Кол-во депозитов
                      </label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>
                          {formatNumber(offer?.count_deposits)}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7 d-flex align-items-center'>
                      <label className='col-4 fw-semibold text-muted'>Ссылка</label>

                      <div
                        className='col-7 d-flex align-items-center border border-end-0 border-2 rounded-1'
                        style={{padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)'}}
                      >
                        <span
                          className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            minHeight: '20px',
                          }}
                          id=''
                        >
                          {offer?.link}
                        </span>
                      </div>
                      <div className='col-1 d-flex flex-end p-0'>
                        <Button
                          className=''
                          style={{borderRadius: '0px 6px 6px 0px'}}
                          color='btn-dark'
                          action={() => copyText(offer?.link)}
                        >
                          <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 my-0'>
                <div className='card-header py-7 border-bottom-0 ps-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800 text-nowrap'>
                      Дополнительная информация
                    </span>
                  </h3>
                </div>
                <div className='p-0'>
                  <div style={{height: '365px'}}>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Параметр 1</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.param_1}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Параметр 2</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.param_2}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted'>Параметр 3</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.param_3}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>Параметр 4</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.param_4}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>
                        Регистрация
                      </label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>
                          {offer?.registration_path}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>Депозит</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>
                          {offer?.deposit_path}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>Играть</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>{offer?.play_path}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='col-4 fw-semibold text-muted text-nowrap'>Создано</label>

                      <div className='col-8 d-flex align-items-center'>
                        <span className='fw-bold fs-6 text-gray-800 me-2'>
                          {moment.tz(offer?.created_at * 1000, 'Europe/Kyiv').format('DD.MM.YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card className='card h-xl-100 mb-10'>
          <div className='card-header pt-7 border-bottom-0'>
            <span className='fs-2 card-label fw-bold text-gray-800'>Рекламодатель</span>
          </div>
          <div className='card-body pt-0 d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <div className='me-5 position-relative'>
                <div className='symbol symbol-50px '>
                  <img
                    src={
                      offer?.advertiser?.avatar
                        ? `${process.env.REACT_APP_API_HOST}/${offer?.advertiser?.avatar}`
                        : imagePlug
                    }
                    alt=''
                  />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <span className='fs-2 fw-bold text-gray-800 text-hover-primary'>
                  {offer?.advertiser?.name}
                </span>
                <div className='fw-semibold text-gray-500'>#{offer?.advertiser?.id}</div>
              </div>
            </div>
            <div className='card-toolbar'>
              <Link to={`/advertisers/list/${offer?.advertiser?.id}`}>
                <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                  <i className='ki-duotone ki-switch fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </span>
              </Link>
            </div>
          </div>
        </Card>
        <Landings offerId={id} offerLink={offer.link} landings={landings} />
      </div>
    </div>
  );
});

export default OfferWrapper;
